.cart-big {
    .highlight-container--content {
        padding-top: 0;

    }
}

.cart-big--title {
    .cart-big--col {
        &-date,
        &-location,
        &-price {
            @include media-breakpoint-down(xl) {
                display: none;
            }
        }
    }
    .cart-big--col {
        &-price {
            @include media-breakpoint-up(xl) {
                text-align: center;
            }
        }
    }
}

.cart-big--row {
    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: auto min(25%, 230px) min(15%, 140px) min(20%, 180px);
        align-items: center;
    }
}

.cart-big--col {
    &-title {}
    &-date {}
    &-location {}
    &-price {}
}


.cart-big--form {

    .cart-big--form--additional-fields {
        margin-top: to-rem(20px);
        margin-bottom: 0;
    }
}

.cart-big--sum {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-top: to-rem(20px);
    
    &--price {
        font-weight: $font-weight-bold;
        text-align: right;
    }
}

.cart-big--item {
    position: relative;

    margin-top: to-rem(20px);
    padding-bottom: to-rem(20px);
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: to-rem($grid-gutter-width / 2 * -1);
        right: to-rem($grid-gutter-width / 2 * -1);
        height: 1px;
        background-color: $color-gray-light-3;

        @include media-breakpoint-up(sm) {
            left: to-rem($effect-default-border-radius * -2);
            right: to-rem($effect-default-border-radius * -2);
        }
    }

    @include media-breakpoint-up(xl) {
        &--title,
        &--info,
        &--date,
        &--location,
        &--price {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &--title {
        margin-bottom: to-rem(10px);

        @include media-breakpoint-up(xl) {
            padding-right: to-rem(30px);
        }

        &--headline {
            @extend .h4;
            margin-bottom: 0;
            color: $text-color;
            text-transform: none;
        }
        &--info {
            @extend .small;
            margin-bottom: 0;
        }
    }

    &--date {
        margin-bottom: to-rem(10px);
    }

    &--location {
        margin-bottom: to-rem(10px);
    }

    &--price {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        .cart-big-no-actions & {
            display: block;
        }

        &--value {
            font-weight: $font-weight-bold;
            white-space: nowrap;

            .cart-big-no-actions & {
                text-align: right;
            }

            @include media-breakpoint-up(xl) {
                text-align: right;
            }
        }

        &--actions {
            .cart-big-no-actions & {
                display: none;
            }
            .btn {
                margin: 0;
                color: $color-gray-light-1;

                &:hover, &:focus {
                    color: $text-color;
                }
            }
        }
    }
}