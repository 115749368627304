.icon-kufer {
    display: inline-block;
    font-size: $font-size-base;
    width: to-em(35px);
    height: to-em(35px);
    border: none;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: to-em(2.5px) to-em(5px) to-em(2.5px) 0;

    &[aria-description]:not([aria-description=""]):not(a) {
        cursor: help;
    }

    @include tooltip-pseudo;

    &--medium {
        @include fluid-property('font-size', $viewport-width-min, $viewport-width-max, 17px, 25px);
    }
}