.area--jumbotron-hero--outer {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;

    @include media-breakpoint-up(md) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
}

.area--jumbotron-hero {
    background-color: $brand-primary;
    background-image: $effect-gradient-bfi;
    background-size: 100% 100%;
    background-position: top left;
    color: $white;
    border-radius: to-rem($effect-default-border-radius);
    overflow: hidden;


    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: currentColor;

        small {
            color: currentColor;
            font-size: .65em;
        }
    }
    li::marker {
        color: currentColor;
    }


    &--inner {
        margin-left: auto;
        margin-right: auto;
        //padding-left: $container-padding / 2;
        //padding-right: $container-padding / 2;
        max-width: $container-max-width + ($container-padding * 2);

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-areas: "content image";
            grid-template-columns: 1fr auto;
        }
    }

    &--content {
        grid-area: content;
        padding: to-rem(40px);
        font-size: to-rem(13px);

        @include media-breakpoint-up(md) {
            font-size: to-rem($font-size-base);
            align-self: center;
        }



        a {
            text-decoration: none;
            color: currentColor;

            &:hover, &:focus {
                color: currentColor;
                text-decoration: underline;
            }
        }
        p:not(.area--jumbotron-hero--address--line) {
            margin-top: 1em;

            a {
                text-decoration: underline;
            }
        }
    }

    &--image {
        grid-area: image;
        align-self: end;
        width: 100%;
        max-width: to-rem(750px);

        @include media-breakpoint-up(md) {
            width: 40vw;
            padding-top: to-rem(40px);
        }

        @include media-breakpoint-up(xl) {
            padding-top: to-rem(100px);
        }
    }


    &--address {
    }

    &--address--line {
        display: flex;
        margin: 0;
        margin-top: to-rem(20px);

        .icon-text {
            align-items: flex-start;
            line-height: $line-height-base;
        }


    }
}

@include editmode {
    /**
     * Without this, the input is 0px wide
     */
    .area--jumbotron-hero {
        .icon-text, .icon-text > div, .pimcore_editable_input {
            width: 100%;
        }
        >div {
            flex-grow: 1;
        }
    }
}