$display-values: none, inline, inline-block, block, grid, table, table-cell, table-row, flex, inline-flex;

@each $display-value in $display-values {
    .d-#{$display-value} {
        display: $display-value !important;
    }
    @each $bp, $value in $grid-breakpoints {
        @include media-breakpoint-up($bp) {
            .d-#{$bp}-#{$display-value} {
                display: $display-value !important;
            }
        }
    }
}


