$mobile-icon-nav--font-size: 10px;

.area--mobile-icon-nav {
    @include fluid-type(400px, $viewport-width-max, $mobile-icon-nav--font-size, $h4-min-font-size);


    @include media-breakpoint-up(xl) {
        display: none;
    }

    @include editmode {
        display: block !important;
    }

    &--list {
        &, & li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    &--list {
        li {
            display: block;
            margin-bottom: to-rem(10px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (min-width: 370px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: to-rem(10px);

            li {
                margin: 0;
                flex-basis: calc(50% - 5px);
                flex-grow: 0;
                width: calc(50% - 5px);
            }
        }

        @include media-breakpoint-up(lg) {
            li {
                flex-basis: auto;
                width: auto;
            }
        }

        /*display: grid;
        grid-template-columns: 1fr;
        grid-gap: to-rem(10px);

        @media (min-width: 350px) {
            grid-template-columns: 50% 50%;
        }*/
    }

    a {
        color: $color-bfi-brand-blue;
        font-weight: $font-weight-bold;
        text-decoration: none;
        text-transform: uppercase;

        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: "icon label";
        align-items: center;
        grid-gap: to-em(10px, $mobile-icon-nav--font-size);

        border: 1px solid $color-gray-light-3;
        border-radius: $btn-outline-text-border-radius;
        padding: to-em(15px, $mobile-icon-nav--font-size)
    }



    i {
        grid-area: icon;
        position: relative;
        background-color: currentColor;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        width: to-em(30px, $mobile-icon-nav--font-size);
        height: to-em(30px, $mobile-icon-nav--font-size);

        &::before {
            @extend .mask-icon;
            color: $white;
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60%;
            height: 60%;
            transform: translate(-50%, -50%);
        }

        &.mask-icon-before--bfi-info {
            background-color: transparent;

            &::before {
                color: currentColor;
                width: 100%;
                height: 100%;
            }
        }
    }
}