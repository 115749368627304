

$area--thumbnail--border-radius: to-rem($effect-default-border-radius);
$area--thumbnail--copyright__font-size: 10px;
.area--thumbnail {
    position: relative;

    picture, img, video {
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    video {
        object-fit: cover;

        .area--media--multiple > .cols > & {
            height: 100%;
        }
    }

    .pimcore_editable_video_progress {
        img, picture {
            width: 100% !important;
            height: auto !important;
        }
    }

    @include editmode {
        .pimcore_editable_video {
            position: static;
        }
        > .media-wrapper--edit {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            z-index: 3;
        }
    }

    &--copyright {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: auto;
        max-width: 80%;
        max-width: unquote('calc(100% - #{$area--thumbnail--border-radius})');
        background-color: $black;
        font-size: to-rem($area--thumbnail--copyright__font-size);
        color: $white;
        opacity: .4;
        line-height: 1.2;
        padding: to-rem(2px, $area--thumbnail--copyright__font-size) $area--thumbnail--border-radius;
        border-top-left-radius: $area--thumbnail--border-radius;
        border-bottom-right-radius: $area--thumbnail--border-radius;
        pointer-events: none;

        a {
            &, &:hover, &:focus {
                text-decoration: none;
                color: currentColor;
                pointer-events: all;
            }
        }
    }

    &--caption {
        @extend .h4;

        display: block;
        margin-left: auto;
        margin-right: auto;
        transform: translateY(to-em(-84px/2px, $h4-font-size));
        width: 80%;
        max-width: 80vw;
        padding: to-em(30px, $h4-font-size) to-em(40px, $h4-font-size);
        color: $text-color;
        background-color: $white;
        border-radius: to-em($effect-default-border-radius, $h4-font-size);
        box-shadow: $effect-dropshadow-big;


        @include media-breakpoint-up(md) {
            max-width: 60vw;
        }
        @include media-breakpoint-up(xl) {
            max-width: to-rem(600px);
        }
    }

    &--editmode-link {
        @include editmode {
            position: absolute;
            top: to-rem(35px);
            left: 0;
            padding: to-rem(15px);

            .pimcore_editable_link_text {
                //display: none;
            }
        }
    }
}


// border radius thumbnails
.area--thumbnail {
    picture, img, video, iframe {
        border-radius: $area--thumbnail--border-radius;

        @include media-breakpoint-down(sm) {
            .area--textmedia.full-width & {
                border-radius: 0;
            }
        }
    }

    .area--parallax.full-width &,
    .area--media.full-width > .cols.cols--1 & {
        picture, img, video, iframe {
            border-radius: 0;
        }
    }

    .area--textmedia.full-width > .thumbnail--left > & {
        picture, img, video, iframe {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .area--thumbnail--copyright {
            border-top-left-radius: 0;
        }
    }
    .area--textmedia.full-width > .thumbnail--right > & {
        picture, img, video, iframe {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .no-gutter > & {
        picture, img, video, iframe {
            border-radius: 0;
        }
    }

    :not(.full-width) > .no-gutter > & {
        &:first-child {
            picture, img, video, iframe {
                border-top-left-radius: $area--thumbnail--border-radius;
                border-bottom-left-radius: $area--thumbnail--border-radius;
            }
        }
        &:last-child {
            picture, img, video, iframe {
                border-top-right-radius: $area--thumbnail--border-radius;
                border-bottom-right-radius: $area--thumbnail--border-radius;
            }
        }
    }
}