:root {
    --courseHeroRatioTop: #{100% / (315/185)};

    @include media-breakpoint-up(lg) {
        --courseHeroRatioTop: #{100% / (457/255)};
    }
}

.page-header--courses-hero {

}

.courses-hero--inner {
    @extend .container;
}

.courses-hero {
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }


    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        //height: to-rem(21px);
        padding-top: 100% / (375/20);
        background-image: svg-load('layout/svg/cta-search-bg.svg');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% 100%;
        pointer-events: none;
        z-index: 0;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: inherit;
        margin: 0;
        margin-top: to-rem(15px);
        margin-bottom: to-rem(30px);
        text-transform: uppercase;

        small {
            color: inherit;
            text-transform: none;
            @include fluid-type($viewport-width-min, $viewport-width-max, $small-font-size, $h4-min-font-size);
        }
    }

    h1, .h1 {
        // edge unterstützt aktuell noch kein hyphen: auto, bei @supports wird es allerdings als unterstützt gemeldet
        html[data-useragent*="Edg/"] & {
            word-break: break-all;
        }
    }

    .tag {
        background-color: transparent;
        color: $white;
        border-color: rgba($white, .4);
    }
    
    &--inner {
        background-repeat: no-repeat;
        background-position: bottom;

        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: var(--courseHeroRatioTop);
        }

        @include media-breakpoint-up(lg) {
            display: grid;
            grid-template-columns: 1fr var(--courseDetail-sidebarWidth);
            grid-template-areas: "header-content header-sidebar";
            position: relative;

            .courses-hero--inner-content {
                padding-right: to-rem(60px);
            }


            background-image: svg-load('layout/svg/cta-search-bg.svg');
            background-position: bottom right;
            background-position: 100% calc(100% + 2px);
            background-size: calc(var(--courseDetail-sidebarWidth) + 120px) auto;
        }
    }
}