
// Basic Variables
$font-size-root: 15px;
$line-height-base: 1.3333;
$wrapper-max-width: (1140px / $font-size-root) * 1rem;
$site-host: 'bfi-sbg.at' !default;

// Project Colors
@import "brand-colors";

$color-bfi-brand-blue: #003770 !default;
$color-bfi-brand-red: #C61932 !default;

$color-blue: $color-bfi-brand-blue !default;
$color-blue-light: #3D5A86 !default;
$color-light-blue-1: #F0F5FB !default;
$color-light-blue-2-courses: #6ABBF3 !default;
$color-red: $color-bfi-brand-red !default;
$color-red-light: #FBF0F0 !default;
$color-gray-dark: #757575 !default;
$color-gray-light-1: #ccc !default;
$color-gray-light-2: #E0E0E0 !default;
$color-gray-light-3: #eee !default;
$color-green-light: #F0FBF3 !default;
$color-success: #3BA738 !default;
$color-danger: #B83D3D !default;
$color-yellow: #FBEA13 !default;
$color-mainNav-dropDown-bg: $color-light-blue-1 !default;

// Basic Colors
$white:    #fff !default;
$gray: #e0e0e0;
$black:    #000 !default;

$body-bg: $white;
$text-color: $black;
$text-color-muted: $color-gray-dark;
$brand-primary: $color-bfi-brand-blue;
$brand-secondary: $color-bfi-brand-red;

// Effects
$effect-gradient-bfi: linear-gradient(275.58deg, $color-bfi-brand-blue 0%, $color-bfi-brand-red 100%) !default;
$effect-dropshadow-default: 0 0 .667rem rgba(0, 0, 0, 0.15) !default;
$effect-dropshadow-default-hidden: 0 0 .667rem rgba(0, 0, 0, 0) !default;

$effect-dropshadow-big: 0 .267rem 4rem rgba(0, 0, 0, 0.1) !default;
$effect-dropshadow-big-hidden: 0 .267rem 4rem rgba(0, 0, 0, 0) !default;
$effect-dropshadow-big-light: 0 .267rem 4rem rgba(0, 0, 0, 0.05) !default;
$effect-dropshadow-big-strong: 0 .267rem 4rem rgba(0, 0, 0, 0.15) !default;

$effect-default-border-radius: 20px !default;

// animations
$animation-fadeIn-duration: .25s;
$animation-fadeIn-delay: $animation-fadeIn-duration * 4 * 2;

$animation-ctaSearch-ratio: 1.1;
$animation-ctaSearch-translateY-add: 5px;
$animation-ctaSearch-duration: 3s;


// Typography
$font-family-base: 'Roboto', "Franklin Gothic Medium", Tahoma, system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-special: $font-family-base;
$font-size-base: ($font-size-root / $font-size-root) * 1rem;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$h1-font-size:                (45px / $font-size-root) * 1rem;
$h2-font-size:                $h1-font-size;
$h3-font-size:                (30px / $font-size-root) * 1rem;
$h4-font-size:                (20px / $font-size-root) * 1rem;
$h5-font-size:                (18px / $font-size-root) * 1rem;
$h6-font-size:                $font-size-base !default;

$h1-min-font-size:            (25px / $font-size-root) * 1rem;
$h2-min-font-size:            $h1-min-font-size;
$h3-min-font-size:            (20px / $font-size-root) * 1rem;
$h4-min-font-size:            (18px / $font-size-root) * 1rem;
$h5-min-font-size:            (16px / $font-size-root) * 1rem;
$h6-min-font-size:            $h6-font-size;

$lead-font-size:			  $h5-font-size;
$lead-min-font-size:		  $h5-min-font-size;

$small-font-size:             (12px / $font-size-root) * 1rem;

$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;

// Fluid Typo Viewport
$viewport-width-min: 768px;
$viewport-width-max: 1200px;

// Containers
$container-padding: 60px;
$container-max-width: 1140px;
$container-max-width--narrow: 960px;

// Grid
$grid-gutter-width: (30px / $font-size-root) * 1rem;

// Links
$link-color: inherit;
$link-hover-color: $color-bfi-brand-red;
$link-decoration: underline;
$link-hover-decoration: none;

// Buttons
$btn-font-family: $font-family-base;
$btn-font-size: $small-font-size;
$btn-font-weight: $font-weight-bolder;
$btn-line-height: 1;
$btn-min-height: (40px / 12px) * 1em;
$btn-padding: 0 1.667em;
$btn-border-width: 2px;
$btn-border-radius: 1.667em;
$btn-disabled-opacity: 0.4;
$btn-primary-bg: $color-light-blue-2-courses;
$btn-transition-duration: .3s;

$btn-outline-text-font-size: $font-size-base;
$btn-outline-text-line-height: $btn-line-height;
$btn-outline-text-padding: 1.333em 1.7333em;
$btn-outline-text-border-radius: 1.3336em;

// Forms
$form-gutter-width: $grid-gutter-width;
$formfield-color: $text-color;
$formfield-bg: $white;
$formfield-padding: ((10px / $font-size-root) * 1rem) ((20px / $font-size-root) * 1rem);
$formfield-border-width: 1px;
$formfield-border-radius: (($effect-default-border-radius / $font-size-root) * 1rem);
$formfield-border-color: $color-gray-light-2;
$formfield-focus-color: $formfield-color;
$formfield-focus-border-color: rgba($brand-primary, .5);
$formfield-focus-bg: $formfield-bg;

// Tables
$table-padding-x: (15px / $font-size-root) * 1rem;
$table-padding-y: (10px / $font-size-root) * 1rem;
$table-border-color: $color-gray-light-3;
$table-head-secondary-color: $text-color;
$table-head-secondary-bg: $table-border-color;

// Slide Buttons
$slide-btn-width: 60px;

// Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        outer: 1920px,
) !default;

$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xxxl);
$outerLimit: map-get($grid-breakpoints, outer);