@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    // convert values to px
    @if unit($min-font-size) != 'px' { $min-font-size: to-px($min-font-size); }
    @if unit($max-font-size) != 'px' { $max-font-size: to-px($max-font-size); }

    // get units
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: to-rem($min-font-size);
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: to-rem($max-font-size);
            }
        }
    } @else {
        @error "fluid-type(): Units don't match!"
    }
}

@mixin fluid-property($property, $min-vw, $max-vw, $min-property, $max-property) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-property);
    $u4: unit($max-property);

    @if $property and $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            #{$property}: $min-property;
            @media screen and (min-width: $min-vw) {
                #{$property}: calc(#{$min-property} + #{strip-unit($max-property - $min-property)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                #{$property}: $max-property;
            }
        }
    } @else {
        @error "fluid-property(): Units don't match!"
    }
}

/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */

@mixin fluidAll($property, $mobileValue, $value) {
    #{$property}: pxToVwMobile($mobileValue);
    @include media-breakpoint-up(md) {
        #{$property}: pxToVw($value);
    }
    @include media-breakpoint-up(xl) {
        #{$property}: $value;
    }
}

@mixin fluid($property, $value) {
    #{$property}: pxToVwMobile($value);
    @include media-breakpoint-up(md) {
        #{$property}: $value;
    }
}

/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */

@mixin fluidDiff($property, $mobileValue, $value) {
    #{$property}: pxToVwMobile($mobileValue);
    @include media-breakpoint-up(md) {
        #{$property}: $value;
    }
}

@mixin fluidDesktop($property, $value) {
    @include media-breakpoint-up(md) {
        #{$property}: pxToVw($value);
    }
    @include media-breakpoint-up(xl) {
        #{$property}: $value;
    }
}

@mixin fluidPlus($property, $value) {
    #{$property}: pxToVwPlus($value);
    @include media-breakpoint-up(xxxl) {
        #{$property}: $value;
    }
}

@mixin fluidOuter($property, $value) {
    #{$property}: pxToVwOuter($value);
    @include media-breakpoint-up(outer) {
        #{$property}: $value;
    }
}