
$main-nav-item--font-size: 17px !default;

#toggleMobileNav {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    width: 1px;
    height: 1px;
}

.hamburger {
    @extend .hamburger--elastic;
    color: $color-bfi-brand-blue;

    .hamburger-inner::after {
        width: 2em;
    }

    &.main-nav--toggle {
        @include open-mobile-nav {
            @extend .hamburger, .is-active;
            @extend .hamburger--elastic, .is-active;

            .hamburger-inner::after {
                width: 2.5em;
            }
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}

@mixin navItemHoverFocusActive {
    /*&.active > .main-nav-item--selector,
    &:hover > .main-nav-item--selector,
    &:focus-within > .main-nav-item--selector,

    &.active > .sub-nav-item--selector,
    &:hover > .sub-nav-item--selector,
    &:focus-within > .sub-nav-item--selector,

    > input[type=radio]:checked {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }*/

    /*[aria-expanded='true'] {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }*/

    &.active > .main-nav-item--selector,
    &.active > .sub-nav-item--selector {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }
}

.main-nav {
    @include media-breakpoint-up(xl) {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.main-nav ul {
    //@include reset-list;
    padding-left: 0;

    &, & li {
        list-style: none;
        margin: 0;
    }
}
ul.main-nav--list {
    font-size: to-rem($font-size-base);
    padding-top: to-rem(30px);

    @include media-breakpoint-between(xl, xxxl) {
        font-size: 1vw;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        height: 100%;

        > li {
            height: 100%;
            display: flex;
            align-items: center;
            flex-grow: 0;
            flex-basis: 20%;
        }
    }

    .main-nav-item--selector {
        display: none;
    }

    li {
        position: relative;

        & > input[type=radio] {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            cursor: pointer;

            &:checked {
                display: none;
            }
        }

        @include media-breakpoint-up(xl) {
            position: static;

            & > input[type=radio] {
                display: none;
                pointer-events: none;
            }
        }
    }
}

.main-nav--item--inner {
    @include media-breakpoint-up(xl) {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.main-nav--item {
    border-bottom: 1px dashed rgba($color-bfi-brand-blue, .2);

    &:first-child {
        border-top: 1px dashed rgba($color-bfi-brand-blue, .2);
    }

    @include media-breakpoint-up(xl) {
        border: none;

        .main-nav--item--inner {
            padding: 0 to-em(20px);
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: to-rem(20px);
                bottom: to-rem(15px);
                left: 0;
                width: 1px;
                border-left: 1px dashed rgba($color-bfi-brand-blue, .2);
            }
        }


        &:first-child {
            border: none;

            .main-nav--item--inner::before {
                display: none;
                content: none;
            }
        }
    }

    > .main-nav--item--inner a:not(.hamburger) {

        font-size: to-em($main-nav-item--font-size);
        color: $color-bfi-brand-blue;
        font-weight: $font-weight-bold;
        text-decoration: none;
        text-transform: uppercase;
        padding: to-em(13px, $main-nav-item--font-size) to-em(20px, $main-nav-item--font-size);

        transition: color $btn-transition-duration;
        transition-timing-function: ease-in-out;
        will-change: color;

        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas: "icon label arrow";
        align-items: center;

        @include media-breakpoint-up(xl) {
            grid-template-columns: auto 1fr;
            grid-template-areas: "icon label";
            padding-left: 0;
            padding-right: 0;
        }

        i, &::after {
            display: block;
            border-radius: 50%;
            overflow: hidden;
            width: to-em(40px, $main-nav-item--font-size);
            height: to-em(40px, $main-nav-item--font-size);
        }

        i {
            grid-area: icon;
            position: relative;
            background-color: currentColor;
            margin-right: to-em(15px, $main-nav-item--font-size);

            &::before {
                @extend .mask-icon;
                color: $white;
                display: block;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60%;
                height: 60%;
                transform: translate(-50%, -50%);
            }

            &.mask-icon-before--bfi-info {
                background-color: transparent;

                &::before {
                    color: currentColor;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &::after {
            opacity: 0;
            grid-area: arrow;
            content: '';
            margin-left: to-em(15px, $main-nav-item--font-size);
            background-color: transparent;
            border: 1px solid rgba(0, 55, 112, 0.1);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto to-em(14px, $main-nav-item--font-size);
            @include insertIcon('angle-right', $brand-primary);

            transition: transform $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: transform;

            @include media-breakpoint-up(xl) {
                display: none;
                content: none;
            }
        }

        &:hover, &:focus {
            color: $color-bfi-brand-red;
        }
    }
    &.has-dropdown > .main-nav--item--inner a {
        &::after {
            opacity: 1;
        }
    }

    @include navItemHoverFocusActive {
        a {
            color: $color-bfi-brand-red;

            &::after {
                transform: rotate(90deg);
            }
        }
    }

    &.hamburger-label {
        .main-nav--item--hamburger-label {
            display: none;
        }

        @include media-breakpoint-between(xl, xxxl) {
            .main-nav--item--hamburger-label {
                font-size: to-rem(10px);
            }
        }

        @include media-breakpoint-up(xl) {
            flex-basis: 0;

            .main-nav--item--inner > a:not(.hamburger) {
                display: none;
            }

            .main-nav--item--hamburger-label {
                display: block;
                color: $color-bfi-brand-blue;

                transition: color $btn-transition-duration;
                transition-timing-function: ease-in-out;
                will-change: color;
            }
            @include navItemHoverFocusActive {
                .main-nav--item--hamburger-label {
                    color: $color-bfi-brand-red;
                }
            }

        }
    }
}

@at-root {
    :root {
        --collapsibleMenuItemHeight: 4000px;
    }
}
.main-nav--item.has-dropdown {
    @include navItemHoverFocusActive {
        @include media-breakpoint-up(xl) {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);

                width: 0;
                height: 0;
                border-style: solid;
                border-width: to-em(20px) to-em(21.5px) 0 to-em(21.5px);
                border-color: $white transparent transparent transparent;
                z-index: 1;
            }
        }
    }

    .main-nav--dropdown {
        display: none;

        @include media-breakpoint-up(xl) {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: $color-mainNav-dropDown-bg;

            &--inner {
                position: relative;
                width: 100vw;
                width: calc(100vw - var(--scrollbarWidth));

                max-height: calc(95vh - 100px);
                overflow-y: auto;
                overscroll-behavior-y: contain;
                -webkit-overflow-scrolling: touch;



                > .sub-nav {
                    padding: to-rem(30px);
                    max-width: to-rem(900px);
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    @include navItemHoverFocusActive {
        & ~ .main-nav--dropdown {
            display: block;
        }
    }
}

@import "main-nav--sub-nav";
