html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    width: 100%;
    font-weight: $font-weight-light;

    @include media-breakpoint-down(md) {
        .offcanvas-nav--visible & {
            overflow-y: hidden;
        }
    }
}

::selection {
    background: $brand-primary;
    color: $white;
}