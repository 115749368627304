/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x           : 1em !default;
$hamburger-padding-y           : 1em !default;
$hamburger-layer-width         : 2.5em !default;
$hamburger-layer-height        : .2em !default;
$hamburger-layer-spacing       : .5em !default;
$hamburger-layer-color         : currentColor !default;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

$hamburger-types: (
        //boring,
        elastic
);


@import "../../build/node_modules/hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
    font-size: .6667em; // 10px
}