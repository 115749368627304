.cta-badge {
    display: flex;
    align-items: center;
    position: relative;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    padding: 2em;
    text-decoration: none;
    box-shadow: $effect-dropshadow-big;
    transform: rotate(-10deg);
    transition: color $btn-transition-duration, background-color $btn-transition-duration, transform $btn-transition-duration;
    transition-timing-function: ease-in-out;
    will-change: color, background-color, transform;

    font-size: to-rem(10px);

    @include media-breakpoint-up(md) {
        font-size: to-rem(20px);
        font-size: min(#{to-rem(20px)}, 1.43vw); // 1.43vw ~ 20px bei 1400px vw
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: .75em;
        bottom: .75em;
        left: .75em;
        right: .75em;
        border: .2em dashed currentColor;
        opacity: .5;
        border-radius: 50%;
    }


    background-color: $color-bfi-brand-blue;
    color: $white;
    &:hover, &:focus {
        color: $white;
        transform: rotate(0deg);
    }
}

.cta-badge--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    text-align: center;

    span {
        display: block;
        line-height: 1;
    }

    &-big {
        font-size: 1.55em;
    }
    &-small {
        font-size: .85em;
    }

    &::after {
        content: '';
        display: block;
        width: 1em;
        height: 1em;
        margin-top: .75em;

        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
        background-color: currentColor;
        mask-image: svg-load('arrow-right.svg');
    }

    &.cta-badge--text-no-icon {
        &::after {
            display: none;
            content: none;
        }
    }
}