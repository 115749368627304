.area--jumbotron-teaser-slider {
    padding-top: to-rem(80px);
    padding-bottom: to-rem(80px - 30px);
    position: relative;

    @include media-breakpoint-up(xl) {
        padding-bottom: 0;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        background-color: $brand-primary;
        background-image: $effect-gradient-bfi;
        background-size: 100% 100%;
        background-position: top left;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
            max-width: 80vw;
            border-radius: to-rem($effect-default-border-radius);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &--inner {
        position: relative;

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: currentColor;

            small {
                color: currentColor;
                font-size: .65em;
            }
        }
    }


    &--content,
    &--buttons {
        color: $white;
        margin-left: auto;
        margin-right: auto;
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
        max-width: $container-max-width + ($container-padding * 2);

        @include media-breakpoint-up(md) {
            padding-left: $container-padding;
            padding-right: $container-padding;
        }
    }


    &--buttons {
        display: none;

        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: to-rem(80px);

            .slide-btn--prev, .slide-btn--next {
                color: $white;
                background-color: transparent;
            }
        }
    }
}


.area--jumbotron-teaser-slider--slides {
    padding-top: to-rem($grid-gutter-width);
    padding-bottom: to-rem($grid-gutter-width);
    display: grid;
    grid: unquote('1fr / auto-flow min(80vw, #{to-rem(300px)})');
    grid-gap: to-rem($grid-gutter-width/2);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-up(xl) {
        padding-top: to-rem($grid-gutter-width * 2);
        padding-bottom: to-rem($grid-gutter-width * 2);
    }

    // scrollbar ausblenden
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }

    > * {
        scroll-snap-align: start;
        scroll-snap-stop: always;

        //&[data-slidecurrent=true] { outline: 2px solid green; } // debug outline für current slide
    }


    padding-left: var(--mainContentContainer-offsetLeftWithPadding);
    scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);

    // scroll abstand rechts
    &::after {
        display: block;
        content: '';
        width: unquote('calc(var(--mainContentContainer-offsetLeftWithPadding) - #{$grid-gutter-width/2})');
        height: 10px;
        scroll-snap-stop: normal;
    }
}

// teaser kategorien

// category nav item
.area--jumbotron-teaser-slider--category-nav--item {
    display: inline-block;
    color: $white;
    font-size: to-rem($font-size-base);
    cursor: pointer;
    border: none;
    background: transparent;

    span {
        display: block;
        text-align: left;
        background-color: transparent;
        font-weight: $font-weight-bold;
        padding: to-em(15px, $font-size-base) to-em(45px, $font-size-base) to-em(15px, $font-size-base) to-em(20px, $font-size-base);
        border-radius: to-rem($effect-default-border-radius);
        overflow: hidden;
        will-change: background-color;
        transition: background-color $btn-transition-duration;



        position: relative;
        &::after {
            content: '';
            display: block;
            width: to-rem(7px);
            height: to-rem(12px);
            position: absolute;
            top: 50%;
            right: to-rem(20px);
            transform: translateY(-50%);
            @include insertIcon('angle-right', $white);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            will-change: transform;
            transition: transform $btn-transition-duration, opacity $btn-transition-duration;
        }

        @include media-breakpoint-up(lg) {
            padding: to-em(15px, $font-size-base) to-em(20px, $font-size-base);

            &::after {
                content: none;
                display: none;
            }
        }
    }

    details[open] &,
    &.active {
        span {
            background-color: rgba($white, .15);

            &::after {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
}


.area--jumbotron-teaser-slider--category-nav {
    display: none;

    @include media-breakpoint-up(lg) {
        margin-top: to-rem(60px);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .area--jumbotron-teaser-slider--category-nav--item {
            flex-shrink: 0;
        }
    }
}

// mobile category nav
.area--jumbotron-teaser-slider--inner details {
    .area--jumbotron-teaser-slider--category-nav--item {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
        max-width: $container-max-width + ($container-padding * 2);


        // hide native arrow
        list-style: none;
        &::marker,
        &::-webkit-details-marker {
            display:none;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    &:first-of-type {
        .area--jumbotron-teaser-slider--category-nav--item {
            margin-top: to-rem(30px);
        }
    }
}