.area--cols {
    > .cols {
        > .col {
            > .area:first-child {
                margin-top: 0;
            }
            > .area:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.cols {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: $grid-gutter-width / 2;
    grid-row-gap: $grid-gutter-width / 2;

    @include media-breakpoint-up(lg) {
        grid-column-gap: $grid-gutter-width;
        grid-row-gap: $grid-gutter-width;
    }

    &.small-gutter {
        //grid-column-gap: $grid-gutter-width / 4;
        grid-row-gap: $grid-gutter-width / 4;

        @include media-breakpoint-up(lg) {
            //grid-column-gap: $grid-gutter-width / 2;
            grid-row-gap: $grid-gutter-width / 2;
        }
    }

    &.no-gutter {
        grid-column-gap: 0;
        grid-row-gap: 0;
    }

    &.overflow-scroll {
        grid: 1fr / auto-flow min(80vw, #{to-rem(300px)});

        overflow-x: auto;
        scroll-snap-type: x proximity;
        overscroll-behavior-x: contain;
        -webkit-overflow-scrolling: touch;

        // scrollbar ausblenden
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }

        > * {
            scroll-snap-align: start;
            scroll-snap-stop: always;
        }

        @include media-breakpoint-up(md) {
            grid-template-rows: initial;
            grid-auto-flow: column;
            grid-auto-columns: initial;


            > * {
                scroll-snap-align: unset;
                scroll-snap-stop: unset;
            }
        }
    }

    &--2 {
        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);

            &.ratio {
                &--25-75 {
                    grid-template-columns: 25% 1fr;
                }

                &--40-60 {
                    grid-template-columns: 40% 1fr;
                }

                &--60-40 {
                    grid-template-columns: 60% 1fr;
                }

                &--75-25 {
                    grid-template-columns: 75% 1fr;
                }
            }
        }
    }

    &--md--2 {
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);

            &.ratio {
                &--25-75 {
                    grid-template-columns: 25% 1fr;
                }

                &--40-60 {
                    grid-template-columns: 40% 1fr;
                }

                &--60-40 {
                    grid-template-columns: 60% 1fr;
                }

                &--75-25 {
                    grid-template-columns: 75% 1fr;
                }
            }
        }
    }

    &--lg--2 {
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(2, 1fr);

            &.ratio {
                &--25-75 {
                    grid-template-columns: 25% 1fr;
                }

                &--40-60 {
                    grid-template-columns: 40% 1fr;
                }

                &--60-40 {
                    grid-template-columns: 60% 1fr;
                }

                &--75-25 {
                    grid-template-columns: 75% 1fr;
                }
            }
        }
    }

    &--xs--2 {
        grid-template-columns: repeat(2, 1fr);

        &.ratio {
            &--25-75 {
                grid-template-columns: 25% 1fr;
            }

            &--40-60 {
                grid-template-columns: 40% 1fr;
            }

            &--60-40 {
                grid-template-columns: 60% 1fr;
            }

            &--75-25 {
                grid-template-columns: 75% 1fr;
            }
        }
    }

    &--3 {
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);

            &.ratio {
                &--50-25-25 {
                    grid-template-columns: 50% 25% 25%;
                }

                &--25-50-25 {
                    grid-template-columns: 25% 50% 25%;
                }

                &--25-25-50 {
                    grid-template-columns: 25% 25% 50%;
                }
            }
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &--4 {

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);

            &.ratio {
                &--40-20-20-20 {
                    grid-template-columns: 40% 20% 20% 20%;
                }

                &--20-40-20-20 {
                    grid-template-columns: 20% 40% 20% 20%;
                }

                &--20-20-40-20 {
                    grid-template-columns: 20% 20% 40% 20%;
                }

                &--20-20-20-40 {
                    grid-template-columns: 20% 20% 20% 40%;
                }
            }
        }
    }

    &--5 {
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(5, 1fr);
        }

        @include media-breakpoint-between(lg, xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-between(md, lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &--6 {
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(6, 1fr);
        }

        @include media-breakpoint-between(lg, xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-between(md, lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .col {
        min-width: 0; // see https://css-tricks.com/preventing-a-grid-blowout/

        &--2 {
            grid-column: auto / span 2;
        }
        &--sm--2 {
            @include media-breakpoint-up(sm) {
                grid-column: auto / span 2;
            }
        }
        &--md--2 {
            @include media-breakpoint-up(md) {
                grid-column: auto / span 2;
            }
        }
        &--lg--2 {
            @include media-breakpoint-up(lg) {
                grid-column: auto / span 2;
            }
        }
        &--xl--2 {
            @include media-breakpoint-up(xl) {
                grid-column: auto / span 2;
            }
        }


        &--3 {
            grid-column: auto / span 3;
        }
        &--sm--3 {
            @include media-breakpoint-up(sm) {
                grid-column: auto / span 3;
            }
        }
        &--md--3 {
            @include media-breakpoint-up(md) {
                grid-column: auto / span 3;
            }
        }
        &--lg--3 {
            @include media-breakpoint-up(lg) {
                grid-column: auto / span 3;
            }
        }
        &--xl--3 {
            @include media-breakpoint-up(xl) {
                grid-column: auto / span 3;
            }
        }



        &--4 {
            grid-column: auto / span 4;
        }
        &--sm--4 {
            @include media-breakpoint-up(sm) {
                grid-column: auto / span 4;
            }
        }
        &--md--4 {
            @include media-breakpoint-up(md) {
                grid-column: auto / span 4;
            }
        }
        &--lg--4 {
            @include media-breakpoint-up(lg) {
                grid-column: auto / span 4;
            }
        }
        &--xl--4 {
            @include media-breakpoint-up(xl) {
                grid-column: auto / span 4;
            }
        }



        &--5 {
            grid-column: auto / span 5;
        }
        &--sm--5 {
            @include media-breakpoint-up(sm) {
                grid-column: auto / span 5;
            }
        }
        &--md--5 {
            @include media-breakpoint-up(md) {
                grid-column: auto / span 5;
            }
        }
        &--lg--5 {
            @include media-breakpoint-up(lg) {
                grid-column: auto / span 5;
            }
        }
        &--xl--5 {
            @include media-breakpoint-up(xl) {
                grid-column: auto / span 5;
            }
        }



        &--6 {
            grid-column: auto / span 6;
        }
        &--sm--6 {
            @include media-breakpoint-up(sm) {
                grid-column: auto / span 6;
            }
        }
        &--md--6 {
            @include media-breakpoint-up(md) {
                grid-column: auto / span 6;
            }
        }
        &--lg--6 {
            @include media-breakpoint-up(lg) {
                grid-column: auto / span 6;
            }
        }
        &--xl--6 {
            @include media-breakpoint-up(xl) {
                grid-column: auto / span 6;
            }
        }


    }
}


@include editmode {
    // Pimcore adds a wrapper div around the columns when in editmode:
    // Frontend: .cols > column
    // Editmode: .cols > wrapper-div > column

    // This just copies the .col classes for their respective editmode containers
    .area--cols {
        > .cols {
            display: block;

            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                display: grid;
                grid-template-columns: 100%;
                grid-column-gap: $grid-gutter-width / 2;
                grid-row-gap: $grid-gutter-width / 2;

                @include media-breakpoint-up(lg) {
                    grid-column-gap: $grid-gutter-width;
                    grid-row-gap: $grid-gutter-width;
                }

                .pimcore_block_buttons + .col {
                    > .pimcore_editable_areablock > .pimcore_area_entry:first-child {
                        margin-top: 0;
                    }
                    > .pimcore_editable_areablock > .pimcore_area_entry:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.small-gutter {
                > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                    //grid-column-gap: $grid-gutter-width / 4;
                    grid-row-gap: $grid-gutter-width / 4;

                    @include media-breakpoint-up(lg) {
                        //grid-column-gap: $grid-gutter-width / 2;
                        grid-row-gap: $grid-gutter-width / 2;
                    }
                }
            }

            &.no-gutter {
                > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                    grid-column-gap: 0;
                    grid-row-gap: 0;
                }
            }

            &--2 {
                @include media-breakpoint-up(sm) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    &.ratio {
                        &--25-75 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 25% 75%;
                            }
                        }

                        &--40-60 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 40% 60%;
                            }
                        }

                        &--60-40 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 60% 40%;
                            }
                        }

                        &--75-25 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 75% 25%;
                            }
                        }
                    }
                }
            }

            &--3 {
                @include media-breakpoint-up(md) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(3, 1fr);
                    }

                    &.ratio {
                        &--50-25-25 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 50% 25% 25%;
                            }
                        }

                        &--25-50-25 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 25% 50% 25%;
                            }
                        }

                        &--25-25-50 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 25% 25% 50%;
                            }
                        }
                    }
                }

                @include media-breakpoint-between(sm, md) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }

            &--4 {
                @include media-breakpoint-up(md) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    &.ratio {
                        &--40-20-20-20 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 40% 20% 20% 20%;
                            }
                        }

                        &--20-40-20-20 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 20% 40% 20% 20%;
                            }
                        }

                        &--20-20-40-20 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 20% 20% 40% 20%;
                            }
                        }

                        &--20-20-20-40 {
                            > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                                grid-template-columns: 20% 20% 20% 40%;
                            }
                        }
                    }
                }

                @include media-breakpoint-between(sm, md) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }

            &--5 {
                @include media-breakpoint-up(xl) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(5, 1fr);
                    }
                }

                @include media-breakpoint-between(lg, xl) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(4, 1fr);
                    }
                }

                @include media-breakpoint-between(md, lg) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }

                @include media-breakpoint-between(sm, md) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }

            &--6 {
                @include media-breakpoint-up(xl) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(6, 1fr);
                    }
                }

                @include media-breakpoint-between(lg, xl) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(4, 1fr);
                    }
                }

                @include media-breakpoint-between(md, lg) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }

                @include media-breakpoint-between(sm, md) {
                    > .pimcore_editable_block:not(.pimcore_block_limitnotreached) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}