.page-header--cta {

    &--inner {
        @include media-breakpoint-up(md) {
            margin-left: auto;
            margin-right: auto;
            padding-left: $container-padding;
            padding-right: $container-padding;
            max-width: $container-max-width + ($container-padding * 2);
        }
    }

    &--inner-wrap {
        @include media-breakpoint-up(xl) {
            //display: grid;
            //grid-template-columns: 1fr auto;
            //grid-template-areas: "ctaBadge ctaSearch";
            position: relative;
            height: to-rem(100px);


            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                max-width: to-rem(640px);
                padding-top: 100% / (640/20);
                background-image: svg-load('layout/svg/cta-search-bg.svg');
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: 100% 100%;
                transform: translateY(-98%);
                transform: translateY(calc(-100% + 2px));
                pointer-events: none;
                z-index: 0;

                .page-header--cta-no-search & {
                    display: none;
                }
            }
        }
    }
}

.page-header--cta--badge {
    grid-area: ctaBadge;

    position: absolute;
    top: to-rem(25px);
    right: to-rem(15px);

    @include media-breakpoint-up(xl) {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;

        .landingpage-without-mainnav & {
            left: auto;
            right: 0;
        }
    }
}


.page-header--cta--search {
    grid-area: ctaSearch;
    position: relative;
    padding-left: $container-padding / 2;
    padding-right: $container-padding / 2;

    max-width: to-rem(580px);
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(sm) {
        max-width: to-rem(640px);
        padding-left: $container-padding;
        padding-right: $container-padding;
    }

    @include media-breakpoint-up(xl) {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;

        &::before {
            display: none;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //height: to-rem(21px);
        padding-top: 100% / (375/20);
        background-image: svg-load('layout/svg/cta-search-bg.svg');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% 100%;
        transform: translateY(-98%);
        pointer-events: none;
        z-index: 0;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        //padding-top: 100% / (520/15);
        height: 1em;
        position: relative;
        top: to-rem(-30px);
        margin-left: auto;
        margin-right: auto;
        font-size: $font-size-base;

        background-color: $black;
        opacity: 0.3;
        filter: blur(1em);

        @include media-breakpoint-up(xl) {
            top: auto;
            bottom: to-rem(-15px);
        }
    }

    > .cta-search {
        @at-root {
            :root {
                --ctaSearchTranslateYBase: -70px;
                --ctaSearchTranslateYHover: -77px;

                @include media-breakpoint-up(xl) {
                    --ctaSearchTranslateYBase: 0px;
                    --ctaSearchTranslateYHover: -7px;
                }
            }
        }
        transform: translateY(var(--ctaSearchTranslateYBase));
    }

    & {
        @include media-breakpoint-up(lg) {
            &::after,
            > .cta-search {
                animation-duration: $animation-ctaSearch-duration;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
            }
            &::after {
                animation-name: animation-ctaSearch-shadow;
            }
            > .cta-search {
                animation-name: animation-ctaSearch-floating;
            }

            &:focus-within {
                &::after {
                    animation: none;
                }
                > .cta-search {
                    animation: none;
                }
            }
        }
    }
}