
.footer-social-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    @include editmode {
        display: block;

        > .pimcore_editable_block {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: to-rem(30px);

            .pimcore_editable_select {
                display: block;
                margin-top: to-rem(15px);
            }
        }
    }

    .footer-social-nav--item {
        position: relative;
        display: block;
        padding: 0;
        margin: 0;
        font-size: to-em(10px);
        width: 4.5em;
        height: 4.5em;
        overflow: hidden;
        text-indent: -9999em;
        color: $white;
        background-color: currentColor;
        border: 1px solid currentColor;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: $effect-dropshadow-big;
        margin-left: to-em(15px, 10px);

        transition: transform $btn-transition-duration, box-shadow $btn-transition-duration, opacity $btn-transition-duration;
        transition-timing-function: ease-in-out;
        will-change: transform, box-shadow;

        &:first-child {
            margin-left: 0;
        }

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2.4em;
            height: 2.4em;
            transform: translate(-50%, -50%);
            color: $white;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center center;
            background-color: $black;

            transition: background-color $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: background-color;
        }


        @include media-breakpoint-up(md) {
            width: 6em;
            height: 6em;
        }
        @include media-breakpoint-up(xl) {
            &:hover, &:focus {
                transform: translateY(-5%);
                box-shadow: $effect-dropshadow-big-strong;
            }

            $use-brands: facebook, xing, youtube, instagram, linkedin;
            @each $brand in $use-brands {
                &[class*=#{$brand}] {
                    &:hover, &:focus {
                        &::before {
                            background-color: brand-color($brand);
                        }
                    }
                }
            }
        }
    }
}