

.footer-bottom {
    padding: to-rem(40px) 0;

    background-color: $brand-primary;
    background-image: $effect-gradient-bfi;
    background-size: 100% 100%;
    background-position: top left;
    color: $white;
    font-weight: $font-weight-normal;

    &--inner {
        @extend .container;
        text-align: center;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-areas: "info conecto";
            align-items: center;
        }
    }


    p {
        grid-area: text;
        margin-bottom: to-em(20px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--info {
        grid-area: info;
        margin-bottom: to-rem(50px);

        @include media-breakpoint-up(md) {
            text-align: left;
            margin-bottom: 0;

            p {
                display: inline-block;
                margin-bottom: 0;
                margin-left: to-rem(50px);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        @include editmode {
            @include media-breakpoint-up(md) {
                .pimcore_editable_input {
                    display: inline-block;
                    margin-left: 1ch;
                }
            }
        }
    }

    &--info--links {
        grid-area: links;
    }

    a {
        text-decoration: none;

        &.link-arrow {
            margin-left: to-em(25px);
            &:first-child {
                margin-left: 0;
            }

            &::before {
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }
        }

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    @include editmode {
        .pimcore_editable_block {
            .pimcore_block_entry {
                display: inline-block;
            }
        }
    }

}


.conecto-copyright {
    grid-area: conecto;
    display: inline-block;
    width: to-rem(90px);
    height: to-rem(7px);
    min-height: to-rem(7px);
    text-align: left;
    text-indent: -9999em;
    overflow: hidden;
    background-image: svg-load('layout/svg/conecto.svg', fill=$white);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}