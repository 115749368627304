.area {
    @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 60px, 80px);
    @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 60px, 80px);

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child:not(.btn) {
        margin-bottom: 0;
    }

    &.is-narrow {
        margin-left: auto;
        margin-right: auto;
        max-width: to-em(750px);
    }

    &.full-width {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
    }
}


// individuelle Abstände
.margin,
.area.margin {
    &-top--none {
        margin-top: 0;
    }

    &-top--tiny {
        margin-top: to-rem(10px);
    }

    &-top--small {
        margin-top: to-rem(15px);
    }

    &-top--default {
        margin-top: to-rem(30px);
    }

    &-top--medium {
        @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 60px, 80px);
    }

    &-top--large {
        @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 80px, 160px);
    }

    &-bottom--none {
        margin-bottom: 0;
    }

    &-bottom--tiny {
        margin-bottom: to-rem(10px);
    }

    &-bottom--small {
        margin-bottom: to-rem(15px);
    }

    &-bottom--default {
        margin-bottom: to-rem(30px);
    }

    &-bottom--medium {
        @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 60px, 80px);
    }

    &-bottom--large {
        @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 80px, 160px);
    }
}



@include editmode {
    .pimcore_area_entry {
        @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 60px, 80px);
        @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 60px, 80px);

        > .area {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}