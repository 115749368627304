
.page-navigation {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: $effect-dropshadow-big;

    @include media-breakpoint-up(lg) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include media-breakpoint-up(xxxl) {
        padding-left: $container-padding;
        padding-right: $container-padding;
    }
}

.page-navigation--container {
    display: grid;
    grid-template-columns: to-em(55px) 1fr auto auto;
    grid-template-rows: 1fr;
    grid-template-areas: "mainNavToggle logo actionButtonCart";
    align-items: center;

    @include media-breakpoint-up(xl) {
        grid-template-columns: auto 1fr auto auto;
        grid-template-areas: "logo mainNav actionButtonSearch actionButtonCart";
        grid-gap: to-em(10px);
    }
    @include media-breakpoint-up(xxxl) {
        grid-gap: to-em(20px);
    }

    .logo {
        justify-self: center;
        grid-area: logo;
        width: to-em(120px);
        padding: to-em(9px) to-em(10px);

        @include media-breakpoint-up(xl) {
            padding: to-em(15px) 0;
            width: to-em(128px);
        }

        .logo-svg--claim {
            display: none;

            .frontpage & {
                display: block;
            }
        }
    }

    .landingpage-without-mainnav & {
        grid-template-columns: to-em(55px) 1fr auto;
        grid-template-areas: "spacer logo actionButtonCart";

        > * {
            display: none !important;
        }

        .logo {
            display: block !important;
            margin-left: auto;
            margin-right: auto;
        }

        .header-action-button--btn-cart {
            display: block !important;

        }
    }

    .main-nav--toggle {
        grid-area: mainNavToggle;
    }

    .header-action-button--btn-cart {
        grid-area: actionButtonCart;
    }
    .header-action-button--btn-search {
        grid-area: actionButtonSearch;

        position: fixed;
        bottom: to-rem(15px);
        right: to-rem(15px);

        @include open-mobile-nav {
            opacity: 0;
            pointer-events: none;
        }

        @include media-breakpoint-up(xl) {
            position: relative;
            bottom: auto;
            right: auto;
        }
    }
}

.main-nav-wrap {
    grid-area: mainNav;
    justify-self: center;
    background-color: $color-mainNav-dropDown-bg;

    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 100%);
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;

    max-width: 0;
    transition: max-width $btn-transition-duration;
    transition-timing-function: ease-in-out;
    will-change: max-width;

    @include open-mobile-nav {
        max-width: 100vw;

        @include media-breakpoint-up(md) {
            max-width: to-rem(450px);
        }
    }

    @include media-breakpoint-up(xl) {
        background-color: transparent;
        max-width: none;
        position: static;
        top: auto;
        left: auto;
        overflow-x: unset;
        overflow-y: unset;
        height: 100%;
        display: flex;
        align-items: center;
    }


    .main-nav {
        max-width: $container-max-width + ($container-padding * 2);
        margin-left: auto;
        margin-right: auto;
    }
}