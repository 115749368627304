.area.area--header {
    &-h3,
    &-h4 {
        &:not([class*=margin-bottom]) {
            margin-bottom: to-rem(30px);
        }
    }
    &-h5,
    &-h6 {
        &:not([class*=margin-bottom]) {
            margin-bottom: 0;
        }
    }
}