.area--testimonial-slider {
    position: relative;
    //margin-bottom: to-rem(60px);

    &--buttons {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);

        .area--testimonial-slider-secondary & {
            .slide-btn--prev, .slide-btn--next {
                color: $color-bfi-brand-red;
            }
        }
    }

    &.area--testimonial-slider-small {
    }
}


.area--testimonial-slider--slides {
    border-radius: to-rem($effect-default-border-radius);
    padding-bottom: to-rem($slide-btn-width/2);

    color: $brand-primary;
    background-color: $color-light-blue-1;
    border-color: $color-light-blue-1;

    .area--testimonial-slider-secondary & {
        color: $color-danger;
        background-color: $color-red-light;
        border-color: $color-red-light;


    }


    display: grid;
    grid: 1fr / auto-flow 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    -webkit-overflow-scrolling: touch;

    // scrollbar ausblenden
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }

    > * {
        scroll-snap-align: start;
        scroll-snap-stop: always;
    }
}


.area--testimonial-entry {
    padding: to-rem($effect-default-border-radius * 2) to-rem($effect-default-border-radius);
    margin: 0;

    @include media-breakpoint-up(lg) {
        padding-top: to-rem(60px);
        padding-bottom: to-rem(60px);
    }

    &--inner {
        width: 100%;
        max-width: to-rem(945px);
        margin-left: auto;
        margin-right: auto;
    }

    &--author {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0;
        margin-bottom: to-rem(30px);
    }

    &--author--name {
        strong {
            display: block;
        }
    }

    &--author--image {
        position: relative;
        width: 100%;
        max-width: to-rem(160px);
        overflow: hidden;
        margin-bottom: to-rem(20px);

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        picture, img, video, iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    &--content {
        @extend .lead;
        margin: 0;
        text-align: center;

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &--media {
        grid-column: 1/-1;


        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
}




.area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry {
    @include media-breakpoint-up(lg) {
        padding-top: to-rem(100px);
        padding-bottom: to-rem(100px);
    }

    &--inner {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-areas: "image content";
            grid-template-columns: auto 1fr;
            grid-column-gap: $grid-gutter-width / 2;
            grid-row-gap: $grid-gutter-width / 2;
        }
        @include media-breakpoint-up(lg) {
            grid-column-gap: $grid-gutter-width;
            grid-row-gap: $grid-gutter-width;
        }
        @include media-breakpoint-up(xxl) {
            grid-column-gap: $grid-gutter-width * 2;
            grid-row-gap: $grid-gutter-width * 2;
        }
    }

    &--author {
        @include media-breakpoint-up(md) {
            width: 25vw;
            max-width: to-rem(250px);
            margin: 0;
        }
    }

    &--content {
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
}