.footer-infobox {
    padding: to-rem(30px);
    background-color: $white;
    border-radius: $effect-default-border-radius;

    margin-bottom: to-rem(15px);
    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin: 0;
    }

    p {
        &.icon-text {
            display: flex;
            align-items: flex-start;
            margin-left: 0;
            font-weight: $font-weight-normal;
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        
        a {
            text-decoration: none;
            
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $text-color;
        margin-bottom: to-rem(15px);
    }
}