.search-box {
    position: relative;
    max-width: to-rem(360px);

    input[type=text],
    input[type=search] {
        //line-height: 1.4667;
        padding: $formfield-padding;
        padding-right: to-rem(100px);
        width: 100%;
        color: $formfield-color;
        background-color: $formfield-bg;
        border: $formfield-border-width solid $formfield-border-color;
        border-radius: $formfield-border-radius;
        position: relative;


        &:focus {
            outline: 0;
            color: $formfield-focus-color;
            background-color: $formfield-focus-bg;
            border-color: $formfield-focus-border-color;
            box-shadow: 0 0 0 to-rem(2px) $formfield-focus-border-color;
        }
        &[readonly], &[disabled] {
            color: rgba($formfield-color, .5);
            background-color: rgba($black, 0.1);
            border-color: rgba($formfield-border-color, .3);
            cursor: not-allowed;
        }

        // validation icon
        &:not(:focus):not(:placeholder-shown):invalid,
        &[required]:not(:focus):not(:placeholder-shown):invalid{
            border-color: $color-danger;
        }
    }

    &--submit {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        margin: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}