.share-action {
    grid-area: share;
    text-align: right;
    position: relative;
}

.share-action--btn {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-weight: $font-weight-normal;
    font-size: to-rem($small-font-size);
    cursor: pointer;

    @include media-breakpoint-up(md) {
        font-size: to-rem($font-size-base);
    }

    &::before {
        display: block;
        content: '';
        width: 1em;
        height: 1em;
        line-height: 1em;
        margin-right: .2em;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
        background-color: currentColor;

        @include insertMaskIcon('share');

        @include media-breakpoint-up(md) {
            margin-right: .6em;
        }
    }
}

.share-action--dialog {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: to-rem(137px);
    background-color: $white;
    border-radius: $effect-default-border-radius;
    overflow: hidden;
    box-shadow: $effect-dropshadow-big;

    .share-action.open & {
        display: block;
    }

    &--head {
        position: relative;

        &--label {
            text-align: left;
            color: $color-bfi-brand-blue;
            font-weight: $font-weight-bold;
            padding: to-rem(10px) to-rem(15px) 0 to-rem(15px);
        }

        &--close {
            display: flex;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border: none;
            border-radius: 0;
            width: to-rem(40px);
            font-size: to-rem(30px);
            background: transparent;
            cursor: pointer;

            i {
                opacity: .7;
            }

            @include media-breakpoint-up(lg) {
                &:hover, &:focus {
                    i {
                        opacity: 1;
                    }
                }
            }
        }
    }

    ul {
        margin: 0;
        list-style: none;
        padding: to-rem(7px) 0 to-rem(3px) 0;
    }
    li {
        list-style: none;
        display: block;
    }

    .btn {
        display: flex;
        justify-content: flex-start;
        min-height: 0;
        text-transform: none;
        font-weight: $font-weight-normal;
        margin: 0;
        padding: to-rem(7px) to-rem(15px);

        i {
            font-size: 1.5em;
        }
    }
}