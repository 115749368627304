.footer {
    position: relative;
    border-top: 1px solid $color-light-blue-1;
    margin-top: to-rem(100px);

    @include editmode {
        padding-top: to-rem(100px);
    }
}



@import "footer-social-nav";
@import "footer-main";
@import "footer-popular-links";
@import "footer-newsletter-small";
@import "footer-info";
@import "footer-infobox";
@import "footer-bottom";