
.footer-popular-links {
    grid-area: popularLinks;
    margin-top: to-rem(60px);
    margin-bottom: to-rem(60px);

    &--list {
        @include reset-list;

        li {
            margin-bottom: to-rem(15px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            li {
                flex-basis: 50%;
            }
        }

        @include editmode {
            > .pimcore_editable_block {
                width: 100%;

                @include media-breakpoint-up(lg) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;

                    > div {
                        flex-basis: 50%;
                    }
                }

                li {
                    flex-basis: unset;
                }
            }
        }
    }
}