
.footer-info {
    background-color: $color-light-blue-1;
    padding: to-rem(60px) 0;

    &--inner {
        @extend .container;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: to-rem(30px);
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include editmode {
            display: block !important;

            > .pimcore_editable_block {
                @extend .container;

                @include media-breakpoint-up(md) {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    gap: to-rem(30px);
                }
                @include media-breakpoint-up(lg) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
}