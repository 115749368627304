

ul {
    > li {
        list-style: square;
        &::marker {
            color: $brand-secondary;
        }
    }

    ul li {
        list-style: disc;
        &::marker {
            color: currentColor;
        }
    }
}

ol {
    counter-reset: ordered-list;

    li {
        list-style: none;
        color: $text-color;
        background-color: $color-light-blue-1;
        border-radius: to-em(20px);
        font-weight: $font-weight-normal;
        min-height: to-em(30px);
        margin-bottom: to-em(15px);
        padding: to-em(10px) to-em(10px) to-em(10px) to-em(50px);
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: counter(ordered-list);
            counter-increment: ordered-list;

            display: block;
            position: absolute;
            top: to-em(5px);
            left: to-em(5px);
            background-color: $brand-secondary;
            color: $white;
            width: to-em(30px);
            height: to-em(30px);
            line-height: to-em(30px);
            font-weight: $font-weight-bolder;
            text-align: center;
            border-radius: 50%;
        }
    }
}