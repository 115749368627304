.area--embed {

    .embera-embed-responsive {
        position: relative;
        width: 100%;
        height: 0;

        // default 3:2
        & {
            padding-bottom: 66.66666%;
        }

        // video 16:9
        &--video {
            padding-bottom: 56.25%;
        }

        &-item, iframe {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        iframe {
            border: none;
        }
    }
}