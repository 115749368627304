.cta-search {
    background-image: $effect-gradient-bfi;
    background-size: 100% 100%;
    background-position: top left;
    color: $white;
    border-radius: $effect-default-border-radius;
    overflow: hidden;
    padding: to-rem(30px);
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: to-rem(80px);
        text-align: left;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: inherit;
        margin: 0;
        text-transform: uppercase;

        small, .small {
            color: inherit;
            text-transform: none;
            margin-bottom: to-em(8px);

            @include media-breakpoint-up(md) {
                font-size: $font-size-base;
            }
        }
    }

    &--fieldset {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: "inputField submitButton";
        align-items: center;
        border-radius: $effect-default-border-radius;
        overflow: hidden;
        margin-top: to-rem(15px);

        @include media-breakpoint-up(md) {
            margin-top: to-rem(30px);
        }
    }

    &--field, &--submit {
        display: block;
        margin: 0;
        border: 1px solid $white;
    }

    &--field {
        grid-area: inputField;
        display: block;
        width: 100%;
        height: 100%;
        padding-left: to-rem(20px);
        font-size: $btn-font-size;
        outline: 0 !important;
    }

    &--submit {
        @extend .btn;

        grid-area: submitButton;
        color: $white;
        background-color: $color-light-blue-2-courses;
        border-color: $color-light-blue-2-courses;
        border-radius: 0;
    }

    &--info-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $small-font-size;
        margin-top: to-rem(15px);

        @include media-breakpoint-up(md) {
            margin-top: to-rem(30px);
            font-size: $font-size-base;
        }

        a {
            color: $white;
            text-decoration: none;

            &:hover, &:focus {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}