.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba($black, .6);
    padding-top: 20vh;
    overflow: hidden;

    display: flex;
    max-height: 0;
    opacity: 0;
    transition: opacity $btn-transition-duration;
    transition-timing-function: cubic-bezier(0.7,0,0.9,1);
    pointer-events: none;

    &.show {
        opacity: 1;
        max-height: 100%;
        pointer-events: all;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;
        align-items: center;
    }

    &--content {
        max-width: to-rem(640px);
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }

    &--fieldset {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
        grid-template-areas: "inputField submitButton";
        align-items: center;
        border-radius: to-em($effect-default-border-radius);
        overflow: hidden;
        @include fluid-type($viewport-width-min, $viewport-width-max, $font-size-base, $h1-min-font-size);
    }

    &--field, &--submit {
        display: block;
        margin: 0;
        border: 1px solid $white;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
    }

    &--field {
        grid-area: inputField;
        display: block;
        width: 100%;
        height: 100%;
        padding-left: to-em(20px);
        font-size: to-em(12px);
    }

    &--submit {
        @extend .btn;
        font-size: to-em(12px);

        grid-area: submitButton;
        color: $white;
        background-color: $color-light-blue-2-courses;
        border-color: $color-light-blue-2-courses;
        border-radius: 0;
    }
}