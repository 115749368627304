
.area--frontpage-main-headline {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 35%;
        left: 0;
        width: 100%;
        height: calc(var(--frontpage-mainHeadline-offsetTop) - var(--frontpage-pageHeaderHero-height));
        //background: rgba(#fb0, .2);
        z-index: -1;
        background-image: url('../images/layout/frontpage-bg-dotted-headline-mobile.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 110% auto;
        pointer-events: none;

        @include media-breakpoint-up(sm) {

        }

        @include media-breakpoint-up(md) {
            bottom: 6%;
        }

        @include media-breakpoint-up(xl) {
            width: 80vw;
            height: calc(var(--frontpage-mainHeadline-offsetTop) - var(--frontpage-pageHeaderHero-height));
            bottom: 50%;
            background-image: url('../images/layout/svg/frontpage-bg-dotted-headline.svg');
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: 1529px auto;
        }
    }


    .container {
        padding-left: 10vw;
    }
    .area--header {
        position: relative;
        padding-left: to-em(15px + 15px + 15px + 15px);

        @include media-breakpoint-up(sm) {
            max-width: 55vw;
        }

        @include media-breakpoint-up(xl) {
            padding-left: to-em(30px + 12px + 12px + 30px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: to-em(45px);
            height: to-em(45px);
            background-color: $color-bfi-brand-blue;
            border: to-em(12px) solid $white;
            border-radius: 50%;

            @include media-breakpoint-up(xl) {
                width: to-em(54px);
                height: to-em(54px);
                border: to-em(12px) solid $white;
            }
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            max-width: to-rem(570px);
            max-width: 22ch;
        }
    }
}