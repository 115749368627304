@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(../fonts/googlefonts/Roboto-italic-300.ttf) format('truetype'), 
		 url(../fonts/googlefonts/Roboto-italic-300.woff) format('woff'), 
		 url(../fonts/googlefonts/Roboto-italic-300.woff2) format('woff2');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/googlefonts/Roboto-italic-400.ttf) format('truetype'), 
		 url(../fonts/googlefonts/Roboto-italic-400.woff) format('woff'), 
		 url(../fonts/googlefonts/Roboto-italic-400.woff2) format('woff2');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../fonts/googlefonts/Roboto-normal-300.ttf) format('truetype'), 
		 url(../fonts/googlefonts/Roboto-normal-300.woff) format('woff'), 
		 url(../fonts/googlefonts/Roboto-normal-300.woff2) format('woff2');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/googlefonts/Roboto-normal-400.ttf) format('truetype'), 
		 url(../fonts/googlefonts/Roboto-normal-400.woff) format('woff'), 
		 url(../fonts/googlefonts/Roboto-normal-400.woff2) format('woff2');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../fonts/googlefonts/Roboto-normal-700.ttf) format('truetype'), 
		 url(../fonts/googlefonts/Roboto-normal-700.woff) format('woff'), 
		 url(../fonts/googlefonts/Roboto-normal-700.woff2) format('woff2');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(../fonts/googlefonts/Roboto-normal-900.ttf) format('truetype'), 
		 url(../fonts/googlefonts/Roboto-normal-900.woff) format('woff'), 
		 url(../fonts/googlefonts/Roboto-normal-900.woff2) format('woff2');
	unicode-range: U+0-10FFFF;
}

