
@mixin header-action-button--rounded {
    border-radius: 50%;
    box-shadow: $effect-dropshadow-big;
}

.header-action-button {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    font-size: to-em(10px);
    width: 5.5em;
    height: 5.5em;
    overflow: hidden;
    text-indent: -9999em;
    background-color: currentColor;
    border: 1px solid currentColor;
    cursor: pointer;
    transition: transform $btn-transition-duration, box-shadow $btn-transition-duration, opacity $btn-transition-duration;
    transition-timing-function: ease-in-out;
    will-change: transform, box-shadow;

    @include media-breakpoint-up(md) {
        width: 6em;
        height: 6em;
    }

    @include media-breakpoint-between(xl, xxxl) {
        font-size: to-rem(8px);
    }
    @include media-breakpoint-up(xl) {
        align-self: end;
        transform: translateY(50%);

        &:hover, &:focus {
            transform: translateY(45%);
            box-shadow: $effect-dropshadow-big-strong;
        }
    }

    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2.4em;
        height: 2.4em;
        transform: translate(-50%, -50%);
        color: $white;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
        background-color: currentColor;
    }

    &:hover, &:focus {
        color: currentColor;
    }

    &--btn-search {
        @include header-action-button--rounded;
        color: $color-light-blue-2-courses;

        &::before {
            mask-image: svg-load('search.svg');
        }

        &:hover, &:focus {
            color: $color-light-blue-2-courses;
        }
    }

    &--btn-cart {
        color: $white;

        &:hover, &:focus {
            color: $white;
        }

        &[data-items]:not([data-items=""])::after {
            content: attr(data-items);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            background-color: $color-bfi-brand-red;
            font-size: 1em;
            text-indent: 0;
            text-align: center;
            text-decoration: none;
            position: absolute;
            top: 50%;
            right: 50%;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            transform: translate(-45%,-7%);
            pointer-events: none;
        }

        &::before {
            mask-image: svg-load('cart.svg');
            background-color: $color-bfi-brand-blue;
        }

        &:hover, &:focus {
            color: $white;
        }

        @include media-breakpoint-up(xl) {
            @include header-action-button--rounded;
        }

    }
}

@import "header-action-cart-dialog";