// base logo
.logo-svg {
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    &--claim {
        margin-top: 9%;
    }
}