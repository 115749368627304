
.footer-main {
    padding: to-rem(20px) 0;
    font-weight: $font-weight-normal;

    &--inner {
        @extend .container;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-areas: "popularLinks newsletter";
            align-items: flex-start;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr auto;
        }
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $text-color;
        margin-bottom: to-rem(30px);
    }

    h1, h2, h3, h4,
    .h1, .h2, .h3, .h4 {
        text-transform: uppercase;
    }
}