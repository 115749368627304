.slide-btns {
    display: flex;
    align-items: center;
}
.slide-btn {
    &--prev, &--next {
        display: block;
        font-size: to-rem($font-size-base);
        width: to-em($slide-btn-width);
        height: to-em($slide-btn-width);
        overflow: hidden;
        border-radius: 50%;
        text-indent: -9999em;
        color: $color-bfi-brand-blue;
        border: 1px solid currentColor;
        background-color: currentColor;
        background-size: 21% auto;
        background-repeat: no-repeat;
        background-position: center center;
        transition: background-color $btn-transition-duration;
        transition-timing-function: ease-in-out;
        cursor: pointer;
        margin: 0;

        &[disabled] {
            opacity: .2;
            cursor: not-allowed;
        }
    }
    &--prev {
        @include insertIcon('angle-left', $white);
    }
    &--next {
        @include insertIcon('angle-right', $white);
    }
}
.slide-btn--prev + .slide-btn--next,
.slide-btn--next + .slide-btn--prev {
    margin-left: to-em(30px);
}