
@at-root {
    :root {
        --mainContentContainer-width: auto;
        --mainContentContainer-innerWidth: auto;
        --mainContentContainer-paddingLeft: #{$container-padding / 2};
        --mainContentContainer-paddingRight: #{$container-padding / 2};
        --mainContentContainer-marginLeft: 0px;
        --mainContentContainer-marginRight: 0px;
        --mainContentContainer-offsetLeft: 0px;
        --mainContentContainer-offsetLeftWithPadding: #{$container-padding / 2};

        @include media-breakpoint-up(md) {
            --mainContentContainer-width: auto;
            --mainContentContainer-innerWidth: auto;
            --mainContentContainer-paddingLeft: #{$container-padding};
            --mainContentContainer-paddingRight: #{$container-padding};
            --mainContentContainer-marginLeft: 0px;
            --mainContentContainer-marginRight: 0px;
            --mainContentContainer-offsetLeft: 0px;
            --mainContentContainer-offsetLeftWithPadding: #{$container-padding};
        }
    }
}