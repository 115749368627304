.area--parallax {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    min-height: 50vw;
    max-height: 80vh;

    @include media-breakpoint-up(sm) {
        min-height: 40vw;
    }

    .area-thumbnail--caption,
    .area-thumbnail--copyright {
        display: none;
    }

    &--media {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $black;
        overflow: hidden;

        &--item {
            width: 100%;
            height: 100%;

            picture, img, video, iframe {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: .6;
            }
        }
    }


    &--content-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &--content {
        color: $white;
        padding: to-rem($grid-gutter-width);

        @include media-breakpoint-up(sm) {
            max-width: 80vw;
        }
        @include media-breakpoint-up(md) {
            max-width: 70vw;
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: currentColor;

            * {
                color: currentColor;
            }
        }

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }

        &--buttons {
        }
    }
}