@mixin tooltip-pseudo($font-size-base: $font-size-base) {
    &[aria-description]:not([aria-description=""]) {
        $space-top: to-rem(5px);
        $size-arrow: to-rem(15px);
        position: relative;

        @include media-breakpoint-up(md) {
            &::before,
            &::after {
                display: block;
                position: absolute;
                left: 50%;
                background-color: $white;
                opacity: 0;
                transition: opacity $btn-transition-duration, transform $btn-transition-duration;
                transition-timing-function: ease-in-out;
                pointer-events: none;
                text-align: center;
                font-size: to-em(12px, $font-size-base);
                font-style: normal;
                line-height: 1.2;
                color: $text-color;
                text-decoration: none;
            }

            &::before {
                content: attr(aria-description);
                bottom: calc(100% + #{$space-top} + #{$size-arrow / 2});
                width: auto;
                min-width: 20ch;
                padding: to-em(10px, 12px);
                border-radius: 1em;
                box-shadow: $effect-dropshadow-default;
                transform: translate(-50%, 0%);
            }

            &::after {
                content: '';
                bottom: calc(100% + #{$space-top});
                width: $size-arrow;
                height: $size-arrow;
                transform: translate(-50%, 0%) rotate(45deg);
            }

            &:hover, &:focus {
                &::after {
                    opacity: 1;
                    transform: translate(-50%, 0%) rotate(45deg);
                }
                &::before {
                    opacity: 1;
                    transform: translate(-50%, 0%);
                }
            }
        }
    }
}

.aria-tooltip {
    @include tooltip-pseudo;
}