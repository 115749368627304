.checkout-summary--data {
    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: min(50%, 350px) 1fr;
    }

    @include media-breakpoint-up(lg) {
        &.checkout-summary--data-with-actions {
            grid-template-columns: min(50%, 350px) 1fr auto;
        }
    }
}

.checkout-summary-data--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: to-rem(10px);

    .btn {
        margin: 0;
        color: $color-gray-light-1;

        &:hover, &:focus {
            color: $text-color;
        }
    }

    @include media-breakpoint-up(md) {
        grid-column: 1 / span 2;
    }
    @include media-breakpoint-up(lg) {
        grid-column: auto;
        align-self: self-end;
        margin-top: 0;
    }
}

.checkout-summary-data--table {
    th {
        font-weight: $font-weight-bold;
        width: to-rem(130px);

        @include media-breakpoint-up(xl) {
            width: to-rem(160px);
        }
    }
}

.checkout-summary-data--entry {
}