.area--textmedia {

    .area--thumbnail {
        margin: 0;
    }

    .area--text {
        &--inner {
            padding-top: 30px;

            @include media-breakpoint-up(md) {
                padding-top: 0;
            }

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    // volle breite
    &.full-width {
        .area--text {
            &--inner {
                padding: to-rem(30px);

                @include media-breakpoint-up(xl) {
                    padding: 12% 15% 12% 0;
                }
            }
        }

        .thumbnail--right {
            .area--text {
                &--inner {
                    padding-left: 15%;
                    padding-right: to-rem(30px);
                }
            }
        }

        .area--thumbnail {
            position: relative;
            margin-bottom: 0;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                width: 100%;
                height: 100%;

                img, video {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;

                    width: 100%;
                    max-width: none;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

    }

    // farbig hinterlegt
    &.is-highlighted {
        position: relative;

        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);

        // highlight farbe
        background-color: $brand-primary;
        background-image: $effect-gradient-bfi;
        background-size: 100% 100%;
        background-position: top left;

        > .cols {
            > .col {
                &.area--text {
                    @include media-breakpoint-only(md) {
                        padding-left: 30px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-left: 0;
                    }
                }

                &.thumbnail--right {
                    &.area--text {
                        padding: 0 150px 0 0;

                        @include media-breakpoint-only(md) {
                            padding-right: 30px;
                        }

                        @include media-breakpoint-down(sm) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .area--text {
            color: $white;

            h1, h2, h3, h4, h5, h6 {
                color: currentColor;
            }

            &--inner {
                padding: 30px;

                @include media-breakpoint-up(xl) {
                    padding: 12% 15%;
                }
            }

            a:not(.btn) {
                color: currentColor;

                &:hover, &:focus {
                    color: currentColor;
                }
            }

            .thumbnail--right & {

            }
        }
    }

    &.text-center-vertically {
        .area--text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .thumbnail--right {
        @include media-breakpoint-up(md) {
            [class*="col"]:first-child {
                order: 2;
            }

            [class*="col"]:last-child {
                order: 1;
            }
        }
    }

    // Spezialformatierung für cols
    > .cols--6 {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 0;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(6, 1fr);
        }

        @include media-breakpoint-down(md) {
            > [class*="col-"] {
                grid-column: auto / auto;
            }
        }
    }
}