@at-root {
    :root {
        --courseDetail-sidebarWidth: min(457px, 35vw)
    }
}

.courses-page--container {
    .card-item.card-item-course-detail {
        max-width: to-rem(457px);

        margin-top: unquote('calc(var(--courseHeroRatioTop) * -1 - var(--mainContentPaddingTop))');
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-start;

        .courses-page--content {
            flex-basis: calc(100% - var(--courseDetail-sidebarWidth));
            flex-shrink: 1;
            flex-grow: 0;
            width: calc(100% - var(--courseDetail-sidebarWidth));
            padding-right: to-rem(60px);
        }

        .courses-page--sidebar {
            flex-basis: var(--courseDetail-sidebarWidth);
            flex-shrink: 0;
            flex-grow: 0;
            width: var(--courseDetail-sidebarWidth);

            .card-item.card-item-course-detail {
                max-width: none;
            }
        }
    }
}


.courses-page--additional-info {
    > * {
        &:not(.card-item):not(.area):not(.infobox) {
            padding-left: to-rem(20px);
            padding-right: to-rem(20px);
        }
    }
    > h4, > .h4 {
        margin-bottom: to-rem(30px);
        text-transform: uppercase;
    }
}


.person-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "image name";
    align-items: center;
    gap: to-rem(15px);

    &--image {
        grid-area: image;
        border-radius: 50%;
        overflow: hidden;

        img, picture, video, &--dummy {
            display: block;
            object-fit: cover;
            @include fluid-property('width', $viewport-width-min, $viewport-width-max, 40px, 60px);
            @include fluid-property('height', $viewport-width-min, $viewport-width-max, 40px, 60px);
        }

        &--dummy {
            background-color: transparent;
            background-size: 70% auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: svg-load('icons/bfi/bfi-graduation.svg');
            border: 1px solid $color-gray-light-3;
            border-radius: 50%;
        }
    }

    &--name {
        grid-area: name;
    }

    & + .person-item {
        margin-top: to-rem(15px);
    }
}