.area--textmedia {
    &--logos, &--logo {
        margin-bottom: $paragraph-margin-bottom;

        &--logo {
            iframe, img, picture, video {
                border-radius: 0;
                max-width: to-rem(830px);
            }
        }
    }

    &--logos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: to-rem($grid-gutter-width / 2);
        align-items: center;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}