.card-item {
    display: block;
    font-size: to-rem($font-size-base);
    border-radius: to-rem($effect-default-border-radius);
    //overflow: hidden;
    position: relative;
    box-shadow: $effect-dropshadow-big;
    background-color: $white;
    min-height: 100%;

    &-icon {
        background-color: transparent;
        box-shadow: $effect-dropshadow-big-hidden;
        text-align: center;
        border: 1px solid $color-gray-light-3;
        @include fluid-property(padding-top, $viewport-width-min, $viewport-width-max, 30px, 60px);
        @include fluid-property(padding-bottom, $viewport-width-min, $viewport-width-max, 30px, 60px);

        .card-item--content {
            padding-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            border-color: transparent;
        }
    }

    &:not(.card-item--no-hover):focus-within {
        box-shadow: $effect-dropshadow-big, 0 0 0 2pt $color-light-blue-2-courses;
    }

    @include media-breakpoint-up(lg) {
        &:not(.card-item--no-hover) {
            transition: transform $btn-transition-duration, box-shadow $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: transform, box-shadow;

            &:hover, &:focus {
                box-shadow: $effect-dropshadow-big-strong;
                transform: translate3d(0, -5px, 0);
            }
        }
    }

    &--stretched-link {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-indent: -9999em;
        text-align: left;
        opacity: 0;
    }

    &--head {
        position: relative;
    }

    &--head--media {
        position: relative;
        border-radius: to-rem($effect-default-border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;

        &:empty {
            background-image: $effect-gradient-bfi;
        }

        &::before {
            content: '';
            display: block;
            padding-top: 54%;
        }

        .card-item-icon & {
            margin-left: auto;
            margin-right: auto;
            border-radius: 0;
            @include fluid-property(max-width, $viewport-width-min, $viewport-width-max, 80px, 150px);

            &::before {
                padding-top: 100%;
            }

            picture, img, video {
                object-fit: contain;
            }
        }

        picture, img, video {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            width: calc(100% + 3px);
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    &--head--headline {
        position: absolute;
        bottom: 0;
        left: 0;
        //z-index: 1;

        color: $white;
        width: 100%;
        padding: 0 to-rem(20px) to-rem(20px) to-rem(20px);

        @include media-breakpoint-up(md) {
            padding-left: to-rem($grid-gutter-width);
            padding-right: to-rem($grid-gutter-width);
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: currentColor;

            small {
                color: currentColor;
                font-weight: $font-weight-light;
                margin-top: to-rem(10px);
                font-size: to-rem($font-size-base);
            }
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6,
        p,
        hr {
            margin: to-rem(10px) 0;
        }

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &--head--tools {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        padding: to-rem(10px);
    }

    &--content {
        padding: to-rem($grid-gutter-width) 0;


        .border-top,
        .border-bottom {
            position: relative;
        }
        .border-top::before,
        .border-bottom::after {
            content: '';
            display: block;
            position: absolute;
            left: to-rem($grid-gutter-width);
            right: to-rem($grid-gutter-width);
            height: to-rem(1px);
            background-color: $color-gray-light-3;

        }
        .border-top {
            padding-top: to-rem(10px);

            &::before {
                top: 0;
            }
        }
        .border-bottom {
            padding-bottom: to-rem(10px);

            &::after {
                bottom: 0;
            }
        }
    }
    &--content-item {
        position: relative;
        padding: to-rem(10px) to-rem(20px);
        border-collapse: collapse;

        @include media-breakpoint-up(md) {
            padding-left: to-rem($grid-gutter-width);
            padding-right: to-rem($grid-gutter-width);
        }

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }

        a {
            z-index: 1;

            &:not(.btn) {
                position: relative;
            }
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: currentColor;

            small {
                font-weight: $font-weight-light;
                margin-top: to-rem(10px);
                font-size: to-rem($font-size-base);
            }
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6,
        p,
        hr {
            margin: to-rem(10px) 0;
        }

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }

        .icon-text {
            flex-shrink: 0;
            font-size: to-rem($small-font-size);
        }

        &--icon-texts {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            gap: to-rem(10px);

            .icon-text {
                margin-left: 0 !important;
            }

            & + p:not(.small) {
                margin-top: to-rem(15px);
            }
        }
    }

    &--content--course-info {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        font-weight: $font-weight-normal;
        padding-top: to-rem(20px);

        &--price {
            font-weight: $font-weight-bold;
        }

        &--number {
            margin-top: to-rem(10px);
            font-size: to-rem($small-font-size);
            flex-basis: 100%;
            text-align: right;
        }

        &--col-info {
            align-self: flex-end;
            text-align: left;
            > * {
                margin-top: 0;
                margin-bottom: 0;
            }

            .card-item--content--course-info--number {
                text-align: left;
            }

            .card-item--content--course-info--price {
                @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, 25px);
            }
        }
        &--col-button {
            align-self: flex-end;
            flex-shrink: 0;
            flex-grow: 0;

            > .btn {
                margin: 0;
            }
        }
    }

    &--content--additional-info {
        padding-top: to-rem(20px);

        p {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &--content-item--buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        padding-top: to-rem(30px);

        .btn {
            margin: 0;
        }


        &-multiple {
            padding-top: 0;

            @media (max-width: 400px) {
                display: block;

                .btn {
                    display: flex;
                    width: 100%;
                    margin-bottom: to-rem(10px);
                    margin-left: 0 !important;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &--course-filter {
        &--filter,
        &--result {
            @extend .card-item--content-item;
        }
    }
    &--course-filter--filter {
        margin-top: to-rem(10px);
        padding-bottom: to-rem(20px);

        @include media-breakpoint-up(md) {
            padding-bottom: to-rem(40px);
        }
    }
    &--course-filter--result {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        border-top: 1px solid $color-gray-light-3;

        &.hidden {
            display: none;
        }

        // accordion
        &--header:not(.no-accordion) {
            // hide native arrow
            list-style: none;
            &::marker,
            &::-webkit-details-marker {
                display:none;
            }

            position: relative;
            &::after {
                content: '';
                display: block;
                width: to-rem(8px);
                height: to-rem(14px);
                position: absolute;
                top: to-rem(20px);
                right: 0;
                @include insertIcon('angle-right', $black);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 1;
                will-change: transform, opacify;
                transition: transform $btn-transition-duration, opacity $btn-transition-duration;

                @include media-breakpoint-up(md) {
                    top: to-rem(45px);
                }
            }

            .card-item--course-filter--result[open] & {
                padding-bottom: to-rem(20px);

                &::after {
                    opacity: .2;
                    transform: rotate(90deg);
                }
            }
        }


        &--header {
            display: block;
            padding-top: to-rem(20px);
            padding-bottom: to-rem(20px);
            padding-right: to-rem(14px);

            .icon-text {
                display: flex;
                font-size: to-rem($font-size-base);

                @include media-breakpoint-up(md) {
                    display: inline-flex;
                }
            }

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: to-rem(7px);
            }

            @include media-breakpoint-up(md) {
                padding-top: to-rem(40px);
                padding-bottom: to-rem(40px);
            }

            &.no-accordion {
                padding-bottom: to-rem(20px);
            }
        }

        &--content {
            padding-bottom: to-rem(20px);

            @include media-breakpoint-up(md) {
                padding-bottom: to-rem(40px);
            }
        }
    }

    &--download-buttons {
        padding-top: to-rem(20px);

        > strong {
            display: block;
            text-transform: uppercase;
            font-size: to-rem($small-font-size);
            font-weight: $font-weight-bolder;
            margin-bottom: to-rem(15px);

            @include media-breakpoint-up(md) {
                font-size: to-rem($font-size-base);
                font-weight: $font-weight-bold;
            }
        }

        .btn-text-icon.btn-block {
            margin-bottom: to-rem(10px);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


$card-item-promo--content-item-paddding-top: 35px;
.card-item-promo {
    position: relative;
    overflow: hidden;

    .card-item--head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &--media {
            border-bottom-left-radius: to-rem($effect-default-border-radius);
            border-bottom-right-radius: to-rem($effect-default-border-radius);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            height: calc(100% - 2px);
            background-color: $brand-primary;
            background-image: $effect-gradient-bfi;
            background-size: 100% 100%;
            background-position: top left;

            img, video {
                opacity: .4;
                mix-blend-mode: luminosity;
            }
        }
    }

    &--head--headline {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: $white;
        font-size: to-rem(40px);
        line-height: 1;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        padding: to-rem(30px);
    }

    @include media-breakpoint-up(lg) {
        .card-item--head--media {
            transition: height $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: height;

            img, video {
                transition: transform $btn-transition-duration;
                transition-timing-function: ease-in-out;
                will-change: transform;
            }
        }
        .card-item--content {
            transition: transform $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: transform;
        }

        &:hover, &:focus {
            .card-item--head--media {
                height: 100%;

                img, video {
                    transform: scale(1.2);
                }
            }
            .card-item--content {
                transform: translate3d(0,100%,0);
            }
        }
    }

    &::before {
        content: '';
        display: block;
        padding-top: 129%;
    }

    .card-item--content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 0;

        > .card-item--content-item.card-item-promo--content-item {
            background-color: $white;
            position: relative;
            padding-top: to-rem(50px);
            text-align: center;

            &:last-child {
                padding-bottom: to-rem($grid-gutter-width);
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: to-rem($card-item-promo--content-item-paddding-top);
                transform: translateY(-100%);
                background-image: svg-load('layout/svg/card-item-promo--rounded.svg');
                background-position: bottom left;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            p {
                margin-bottom: 0;
            }
        }

        .card-item-promo--button {
            display: flex;
            width: auto;
            margin: 0;
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            white-space: nowrap;
        }
    }
}