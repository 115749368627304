.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: background-color $btn-transition-duration;

    .modal-container-visible & {
        display: flex;
        pointer-events: all;
        height: 100%;
        background-color: rgba($black, .5);
    }
}


.modal {
    display: block;
    font-size: to-rem($font-size-base);
    border-radius: to-rem($effect-default-border-radius);
    box-shadow: $effect-dropshadow-big;
    background-color: $white;
    width: 80vw;
    max-width: to-rem(480px);
    margin-left: auto;
    margin-right: auto;

    &--content {
        position: relative;
        overflow: hidden;
        border-radius: to-rem($effect-default-border-radius);
        padding: to-rem($grid-gutter-width);
        text-align: center;

    }

    &--header {
        font-weight: $font-weight-light;
        color: $text-color;
        margin-bottom: 0;
    }

    &--body {
        margin-top: to-rem(15px);
    }

    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
    }
}