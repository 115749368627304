.area--teaser-list-overflow {
}

.area--teaser-list-overflow--items {
    @include media-breakpoint-down(lg) {
        display: grid;
        grid: unquote('1fr / auto-flow min(60vw, #{to-rem(270px)})');
        grid-gap: to-rem($grid-gutter-width/2);
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overscroll-behavior-x: contain;
        -webkit-overflow-scrolling: touch;
        padding-left: var(--mainContentContainer-offsetLeftWithPadding);
        scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);

        // scrollbar ausblenden
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }

        > * {
            scroll-snap-align: start;
            scroll-snap-stop: always;
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .area--teaser-list-overflow--item {
            width: calc(min(33vw, 340px) - 30px);
        }
    }
}