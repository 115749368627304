

.area--media {
    .area--thumbnail {
        margin: 0;
    }
}

// overflow-scroll für bilder nebeneinander
.area--media--multiple {
    > .cols {
        //@extend .overflow-scroll;
    }

    // padding left für bilder die direkte children vom  main content sind.
    // somit wird eine linie mit dem restlichen inhalt eingehalten
    .main-content--container > &:not(.full-width) {

        // .full-width formatierungen anwenden
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);

        padding-left: var(--mainContentContainer-offsetLeftWithPadding);
        scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);


        // .full-width für höhere auflösungen wieder rückgängig machen
        @include media-breakpoint-up(md) {
            position: initial;
            left: initial;
            transform: initial;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            scroll-padding: 0;

        }

        & > .cols {
            // scroll abstand rechts
            &::after {
                display: block;
                content: '';
                width: unquote('calc(var(--mainContentContainer-offsetLeftWithPadding) - #{$grid-gutter-width/2})');
                height: 10px;
                scroll-snap-stop: normal;
            }

            &.no-gutter::after {
                width: unquote('var(--mainContentContainer-offsetLeftWithPadding)');
            }
        }

        @include media-breakpoint-up(sm) {
            & > .cols.cols--2 {
                padding-right: var(--mainContentContainer-offsetLeftWithPadding);
                &::after {
                    display: none;
                    content: none;
                }
            }
        }

        @include media-breakpoint-up(md) {
            // scroll abstand rechts entfernen
            & > .cols {
                &.cols--2 {
                    padding-right: 0;
                }
                &::after {
                    display: none;
                    content: none;
                }
            }
        }
    }
}