.icon,
.inline-icon {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 1em;
}

.icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &--color--white {
        filter: saturate(0) brightness(0) invert(100%) opacity(1);
    }
}

.mask-icon {
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
    background-color: currentColor;
}

@include generateIconSet($icon-list);
@include generateMaskIconSet($icon-list);

// Color Variants sollten besser manuell für tatsächlich benötigte Icons erstellt werden,
// damit die data.css nicht zu groß wird
//@include generateIconSetColorVariants($icon-list, $white, "white");


.icon-text {
    display: inline-flex;
    align-items: center;
    line-height: 1;

    > .icon,
    > .inline-icon {
        flex-shrink: 0;
        flex-grow: 0;
        font-size: to-em(18px);
        margin-right: to-rem(10px);
    }

    & + & {
        margin-left: to-rem(10px);
    }
}