.checkout {
}

.checkout-section {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        top: 0;
        height: 100%;
        border-left-style: dashed;
        border-left-color: rgba($color-bfi-brand-blue, .2);

        @include fluid-property(left, $viewport-width-min, $viewport-width-max, 45px/2, 68px/2);
        @include fluid-property(border-left-width, $viewport-width-min, $viewport-width-max, 1px, 2px);
    }

    &:last-child {
        &::before {
            content: none;
            display: none;
        }
    }

    .checkout-section--header,
    .checkout-section--content {
        position: relative;
    }
}

.checkout-section--header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding-bottom: to-rem(15px);
    text-decoration: none;

    @include fluid-property(grid-gap, $viewport-width-min, $viewport-width-max, 10px, 30px);
}

.checkout-section--indicator,
.checkout-section--content::before {
    display: block;
    font-style: normal;
    text-align: center;
    color: $white;
    font-weight: $font-weight-normal;
    border-style: solid;
    border-color: $white;
    border-radius: 50%;
    background-color: $color-bfi-brand-blue;

    @include fluid-type($viewport-width-min, $viewport-width-max, 18px, 20px);
    @include fluid-property(width, $viewport-width-min, $viewport-width-max, 45px, 68px);
    @include fluid-property(height, $viewport-width-min, $viewport-width-max, 45px, 68px);
    @include fluid-property(border-width, $viewport-width-min, $viewport-width-max, 7.5px, 10px);
    @include fluid-property(line-height, $viewport-width-min, $viewport-width-max, 30px, 48px);


    .checkout-section.active & {
        background-color: $color-light-blue-2-courses;
    }
    .checkout-section.done & {
        background-color: $color-success;
    }
}

.checkout-section--content::before {
    content: none;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    color: transparent;
    border-color: transparent;

    @include media-breakpoint-up(lg) {
        content: '';
    }
}

.checkout-section--headline {
    .checkout-section.done & {
        color: $color-gray-light-1;
    }
}


.checkout-section--content {
    &--inner {
        background-color: $white;
        padding: to-rem(15px) 0;
    }

    @include fluid-property(grid-gap, $viewport-width-min, $viewport-width-max, 10px, 30px);

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: top;
    }
}

@import "checkout-cart";
@import "checkout-summary";