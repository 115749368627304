@keyframes animation-ctaSearch-floating {
    0% {
        transform: translateY(var(--ctaSearchTranslateYBase));
    }
    50% {
        transform: translateY(var(--ctaSearchTranslateYHover));
    }
    100% {
        transform: translateY(var(--ctaSearchTranslateYBase));
    }
}
@keyframes animation-ctaSearch-shadow {
    0% {
        width:100%;
    }
    50% {
        width: 100% / $animation-ctaSearch-ratio;
    }
    100% {
        width:100%;
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}



@mixin fadeInBaseAnimation($animationName: fadeIn, $duration: $animation-fadeIn-duration, $delay: $animation-fadeIn-delay) {
    opacity: 0;
    animation-name: $animationName;
    animation-duration: $duration;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: $delay;
}

@mixin fadeInAnimation($duration: $animation-fadeIn-duration, $delay: $animation-fadeIn-delay) {
    @include fadeInBaseAnimation(fadeIn, $duration, $delay);
}

@mixin fadeInDownAnimation($duration: $animation-fadeIn-duration, $delay: $animation-fadeIn-delay) {
    @include fadeInBaseAnimation(fadeInDown, $duration, $delay);
}