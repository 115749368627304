.area--mobile-collapse {
    position: relative;


    > label {
        display: flex;
        justify-content: center;
        margin-top: to-rem(30px);
    }

    > input[type=checkbox] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        width: 1px;
        height: 1px;
    }
    > input[type=checkbox]:checked {
        ~ label {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        > label {
            display: none;
        }

        .area--mobile-collapse--content,
        input[type=checkbox]:not(:checked) ~ .area--mobile-collapse--content {
            max-height: none;

            &::before {
                content: none;
                display: none;
            }
        }
    }
}

.area--mobile-collapse--content {
    position: relative;

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    input[type=checkbox]:not(:checked) ~ & {
        max-height: to-rem(130px);
        overflow: hidden;

        &::before {
            content: "";
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 4em;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
        }
    }
}