.area--text {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: .5em;
    }

    @include media-breakpoint-only(xs) {
        &.text-center {
            text-align: left;
        }
    }

    .area--header {
        margin-bottom: to-rem(30px);
    }
}