
.page-header--tools .breadcrumb {
    grid-area: breadcrumb;

    &--list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        li {
            all: initial;
            display: inline-flex;
            align-items: center;
            margin-right: to-rem(20px);

            color: inherit;
            font-family: inherit;
            font-weight: $font-weight-normal;
            font-size: to-rem($small-font-size);

            @include media-breakpoint-up(md) {
                font-size: to-rem($font-size-base);
            }


            &::before {
                all: initial;
                content: '';
                display: block;
                flex-shrink: 0;
                flex-grow: 0;
                width: to-rem(6px);
                height: to-rem(10px);
                margin-right: to-rem(10px);
                @include insertIcon('angle-right', $white);
                background-size: contain;
                background-repeat: no-repeat;
            }

            &:first-child {
                &::before {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                display: none;
                &:nth-last-child(2) {
                    display: inline-flex;
                    &::before {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        a {
            text-decoration: none;

            &:hover, &:focus {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}