.area--highlight-container {

}


.highlight-container {
    border-radius: to-rem($effect-default-border-radius);
    overflow: hidden;
    background-color: $white;
    box-shadow: $effect-dropshadow-big;

    &--title,
    &--content {
        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &--title {
        background-color: $brand-primary;
        font-weight: $font-weight-bold;
        color: $white;
        padding: to-rem($grid-gutter-width / 2) to-rem($grid-gutter-width / 2) to-rem($grid-gutter-width / 2) to-rem($grid-gutter-width);

        @include media-breakpoint-up(sm) {
            padding: to-rem($effect-default-border-radius) to-rem($effect-default-border-radius * 2);
        }

        * {
            color: currentColor;
        }
    }

    &--content {
        padding: to-rem($grid-gutter-width / 2);

        @include media-breakpoint-up(sm) {
            padding: to-rem($effect-default-border-radius * 2);
        }

        > hr {
            margin-left: to-rem($grid-gutter-width / 2 * -1);
            margin-right: to-rem($grid-gutter-width / 2 * -1);

            @include media-breakpoint-up(sm) {
                margin-left: to-rem($effect-default-border-radius * 2 * -1);
                margin-right: to-rem($effect-default-border-radius * 2 * -1);
            }
        }
    }
}