.accordion {
    overflow: hidden;
    border: 1px solid $color-gray-light-3;
    border-radius: to-rem($effect-default-border-radius);
    background-color: $white;

    &--header {
        line-height: 1.2;
        font-family: $font-family-special;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $white;
        background-color: $brand-primary;
        @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);
        @include hyphens-auto(true);

        padding: to-rem($grid-gutter-width / 2);
        padding-left: to-em(55px, $h5-font-size);

        // hide native arrow
        list-style: none;
        &::marker,
        &::-webkit-details-marker {
            display:none;
        }

        // custom arrow
        @include insertIcon('angle-right', $white);
        background-size: auto to-em(14px, $h5-font-size);
        background-repeat: no-repeat;
        background-position: to-em(18.5px, $h5-font-size) center;

        .accordion[open] & {
            @include insertIcon('angle-down', $brand-primary);
            background-size: to-em(14px, $h5-font-size) auto;
            background-color: $white;
            color: $brand-primary;
        }
    }

    &--collapsible {
        padding: to-rem($grid-gutter-width / 2) to-rem(20px) to-rem(20px) to-rem(20px);
    }

    &--content {
        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.accordion + .accordion {
    margin-top: to-rem(10px);
}

.accordion-simple {
    > summary {
        // hide native arrow
        list-style: none;
        &::marker,
        &::-webkit-details-marker {
            display:none;
        }
    }

    //&[open] {}
}