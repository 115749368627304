.video-controls {
    position: relative;
}


.video-controls--playpause {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    max-width: 80px;
    border: none;
    background-color: $color-bfi-brand-red;
    opacity: .9;
    border-radius: 100%;
    padding: 0;
    cursor: pointer;
    transition: opacity $btn-transition-duration;

    background-image: svg-load('play.svg', fill=$white);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px auto;

    &[data-state='pause'] {
        opacity: 0;
        background-image: svg-load('pause.svg', fill=$white);

        .video-controls:hover & {
            opacity: .9;
        }
    }

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}