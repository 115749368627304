@import "helpers-display";
@import "helpers-spacing";

.embed-responsive {
    position: relative;
    width: 100%;
    height: 0;

    &-1by1 {
        padding-bottom: 100%;
    }

    &-3by2 {
        padding-bottom: 66.66666%;
    }

    &-16by9 {
        padding-bottom: 56.25%;
    }

    > *, > .pimcore_editable_video > * {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    > iframe {
        border: none;
    }
}


@include editmode {
    .embed-responsive > script {
        display: none;
    }

    .area--thumbnail .embed-responsive .pimcore_editable_video {
        position: absolute;
    }

    .embed-responsive > * > .pimcore_editable_video iframe {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}