.area--frontpage-news {

    &--buttons {
        text-align: center;
    }
}

@include media-breakpoint-down(lg) {
    .area--frontpage-news--promo {
        padding-left: var(--mainContentContainer-offsetLeftWithPadding);
        margin-bottom: to-rem(30px);

        .card-item {
            max-width: unquote('min(85vw, #{to-rem(300px)})');
        }
    }

    .area--frontpage-news--items {
        display: grid;
        grid: unquote('1fr / auto-flow min(85vw, #{to-rem(300px)})');
        grid-gap: to-rem($grid-gutter-width/2);
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overscroll-behavior-x: contain;
        -webkit-overflow-scrolling: touch;
        padding-left: var(--mainContentContainer-offsetLeftWithPadding);
        scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);
        padding-top: to-rem(60px);
        padding-bottom: to-rem(60px);
        transform: translateY(to-rem(-60px));
        margin-bottom: to-rem(-60px);

        // scrollbar ausblenden
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }

        > * {
            scroll-snap-align: start;
            scroll-snap-stop: always;
        }
    }
}


@include media-breakpoint-up(lg) {
    .area--frontpage-news-container {
        background-image: url('../images/layout/frontpage-news--bg-line.svg'),
                          url('../images/layout/frontpage-news--bg-team.png');
        background-repeat: no-repeat, no-repeat;
        background-position: 50% to-rem(-100px), center right;
        background-size: 1920px 1500px, contain;
    }
    .area--frontpage-news--wrapper {
        margin-left: auto;
        margin-right: auto;
        padding-left: $container-padding;
        padding-right: $container-padding;
        max-width: $container-max-width + ($container-padding * 2);
    }
    .area--frontpage-news {
        max-width: to-rem(660px);
        position: relative;


        .card-item {
            width: to-rem(300px);
        }

        &--promo {
            position: relative;
            padding-top: to-rem( 300px * (1.29 / 2) + 60px );

            .card-item {
                display: none;

                &:first-child {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }

        &--items {
            display: flex;
            flex-wrap: wrap;
            gap: to-rem(60px);

            &--item {
                &:nth-child(2) {
                    transform: translateY(50%);
                }
            }
        }

        &--buttons {
            position: absolute;
            bottom: to-rem(100px);
            right: 0;
            width: to-rem(300px);
            text-align: center;
        }
    }
}


@include media-breakpoint-up(xl) {

    .area--frontpage-news-container {
        .container {
            position: relative;
            min-height: to-rem(150px);
        }


        .area--header {
            position: absolute;
            top: to-rem(-65px);
            left: to-rem(30px);
            padding-left: to-em(15px + 15px + 15px + 15px);

            @include media-breakpoint-up(sm) {
                max-width: 55vw;
            }

            @include media-breakpoint-up(xl) {
                padding-left: to-em(30px + 12px + 12px + 30px);
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: to-em(45px);
                height: to-em(45px);
                background-color: $color-bfi-brand-blue;
                border: to-em(12px) solid $white;
                border-radius: 50%;

                @include media-breakpoint-up(xl) {
                    width: to-em(54px);
                    height: to-em(54px);
                    border: to-em(12px) solid $white;
                }
            }

            h1, h2, h3, h4, h5, h6,
            .h1, .h2, .h3, .h4, .h5, .h6 {
                max-width: to-rem(390px);
                max-width: 15ch;
            }
        }

    }
}