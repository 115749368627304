.page-hero--entry {
    position: relative;

    &--media {
        position: relative;
        border-radius: $effect-default-border-radius;
        box-shadow: $effect-dropshadow-big;
        background-color: $black;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            //padding-top: 100% / (315/250);
            overflow: hidden;
            height: 67vw;
            max-height: 70vh;

            @include media-breakpoint-up(md) {
                height: 0;
                padding-top: 100% / (1140/500);
            }
        }

        > .page-hero--entry--media--item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .75;
        }

        picture, img, video,
        .pimcore_editable_video {
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            object-fit: cover;
        }

        .pimcore_editable_video_progress img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    &--content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: to-rem(20px) to-rem(30px);
        color: $white;
        //text-align: center;

        @include media-breakpoint-up(md) {
            padding: to-rem(40px) to-rem(60px);
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: inherit;
            margin: 0;
            text-transform: uppercase;

            small {
                color: inherit;
                text-transform: none;
                @include fluid-type($viewport-width-min, $viewport-width-max, $small-font-size, $h4-min-font-size);
            }

            @include media-breakpoint-up(lg) {
                hyphens: none;
            }
        }

        p {
            @extend .lead;
            margin: to-rem(10px) 0 0 0;
            font-weight: $font-weight-bold;
        }

        &--buttons {
            margin-top: 1em;

            .btn {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .btn-outline,
        .btn-text {
            color: $white;
        }

        .btn-outline {
            box-shadow: none !important;
            transition: color $btn-transition-duration, background-color $btn-transition-duration, border-color $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: color, background-color, border-color;

            @include btn-hover-state {
                color: $white;
                border-color: $color-bfi-brand-red;
                background-color: $color-bfi-brand-red;
            }
        }
    }

    @include editmode {
        .media-wrapper--edit {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

// content positionierung
.page-hero--entry--content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;

    &.v-align-top { align-items: flex-start; }
    &.v-align-center { align-items: center; }
    &.v-align-bottom { align-items: flex-end; }

    &.h-align-left { justify-content: flex-start; text-align: left; }
    &.h-align-center { justify-content: center; text-align: center; }
    &.h-align-right { justify-content: flex-end; text-align: right; }

    @each $breakpoint, $width in $grid-breakpoints {
        @include media-breakpoint-up($breakpoint) {
            &.v-align-top--#{$breakpoint} { align-items: flex-start; }
            &.v-align-center--#{$breakpoint} { align-items: center; }
            &.v-align-bottom--#{$breakpoint} { align-items: flex-end; }

            &.h-align-left--#{$breakpoint} { justify-content: flex-start; text-align: left; }
            &.h-align-center--#{$breakpoint} { justify-content: center; text-align: center; }
            &.h-align-right--#{$breakpoint} { justify-content: flex-end; text-align: right; }
        }
    }
}

.page-hero--small {
    @extend .container;
}


.page-hero--big .page-hero--entry {
    &--media {
        border-radius: 0;
        box-shadow: none;

        &::before {
            //padding-top: 100% / (375/410);
            height: 130vw;
            max-height: 70vh;
            min-height: to-rem(300px);

            @include media-breakpoint-up(md) {
                height: 60vw;
            }
            @include media-breakpoint-up(xl) {
                height: 0;
                padding-top: 100% / (1920/720);
                min-height: 720px;
            }
        }
    }

    &--content {
        bottom: to-rem(70px);

        &--inner {
            max-width: 70vw;
            @include media-breakpoint-up(sm) {
                max-width: 60vw;
            }
            @include media-breakpoint-up(md) {
                max-width: 50vw;
            }
            @include media-breakpoint-up(xl) {
                max-width: 40vw;
            }
            @include media-breakpoint-up(xxl) {
                max-width: 44vw;
            }
            @include media-breakpoint-up(xxxl) {
                max-width: to-rem(770px);
            }
        }

        h1, .h1 {
            color: inherit;
            margin: 0;
            text-transform: none;
            @include fluid-type($viewport-width-min, $viewport-width-max, to-rem(25px), to-rem(60px));
        }


        // fadeIn animation
        &--inner {
            @include fadeInAnimation($animation-fadeIn-duration * 2, $animation-fadeIn-delay);

            //> * {
            //    @include fadeInAnimation($animation-fadeIn-duration * 2, $animation-fadeIn-delay);
            //}
            //@for $i from 2 through 10 {
            //    > *:nth-child(#{$i}) {
            //        animation-delay: $animation-fadeIn-delay + $animation-fadeIn-duration*2 + (($animation-fadeIn-duration/2) * $i);
            //    }
            //}
        }
    }
}