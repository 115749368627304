table.contenttable,
.area--text table,
.area--table table {
    width: 100%;
    border: none;

    th,
    td {
        padding: $table-padding-y $table-padding-x;

        &:first-child {
            padding-left: $table-padding-x * 2;
        }
        &:last-child {
            padding-right: $table-padding-x * 2;
        }

        min-width: 25vw;

        @include media-breakpoint-up(md) {
            min-width: 0;
        }
    }

    thead {
        tr:first-child th {
            background-color: $brand-primary;
            color: $white;
        }

        tr:not(:first-child) th {
            background-color: $table-head-secondary-bg;
            color: $table-head-secondary-color;
        }
    }

    td {
        border-bottom: 1px solid $table-border-color;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.table-responsive {
    @include media-breakpoint-down(sm) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}