.infobox {
    position: relative;
    border-radius: to-rem($effect-default-border-radius);
    padding: to-rem(20px);

    @include media-breakpoint-up(sm) {
        padding: to-rem(40px);
    }

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    > h1, > h2, > h3, > h4, > h5, > h6,
    > .h1, > .h2, > .h3, > .h4, > .h5, > .h6 {
        margin-bottom: 1em;
    }

    &--title {
        margin-bottom: 1em;
        * {
            color: currentColor;
        }
        > *:first-child {
            margin-bottom: 0;
        }
    }

    // Standard und .infobox-light Farbschema
    & {
        border: 1px solid $color-gray-light-3;
        background-color: $white;

        .infobox--title {
            color: $brand-primary;
        }
    }

    &-primary {
        color: $brand-primary;
        background-color: $color-light-blue-1;
        border-color: $color-light-blue-1;

        .infobox--title {
            color: currentColor;
        }
    }

    &-success {
        color: $color-success;
        background-color: $color-green-light;
        border-color: $color-green-light;

        .infobox--title {
            color: currentColor;
        }
    }

    &-danger {
        color: $color-danger;
        background-color: $color-red-light;
        border-color: $color-red-light;

        .infobox--title {
            color: currentColor;
        }
    }
}