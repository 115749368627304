

@each $bp, $value in $grid-breakpoints {
    @include media-breakpoint-up($bp) {
        .mt-#{$bp}-0 { margin-top: 0 !important; }
        .mb-#{$bp}-0 { margin-bottom: 0 !important; }
        .ml-#{$bp}-0 { margin-left: 0 !important; }
        .mr-#{$bp}-0 { margin-right: 0 !important; }
        .mx-#{$bp}-0 { margin-left: 0 !important; margin-right: 0 !important; }
        .my-#{$bp}-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
    }
}