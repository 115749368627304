.page-header {
    position: relative;

    .subpage & {
        background-image: $effect-gradient-bfi;
        background-size: 100% 50%;
        background-position: top left;
        background-repeat: no-repeat;
        color: $white;
    }

    .page--courses-detail & {
        background-size: 100% 100%;
    }
}

.page-header--container {
    @extend .container;
}

@import "page-header--hero";
@import "page-header--tools";
@import "page-header--tools--breadcrumb";
@import "page-header--tools--share-action";
@import "page-header--cta";
@import "page-header--cta--search";
@import "page-header--cta--badge";
@import "page-header--courses-hero";