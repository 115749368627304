h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.2;
    font-family: $font-family-special;
    font-weight: $font-weight-bold;
    margin-top: 0;
    margin-bottom: 0;
    color: $brand-primary;

    @include hyphens-auto(true);

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    small {
        display: block;
        margin-top: 0.25em;
        line-height: 1.15;
        font-size: .6em;
        font-weight: $font-weight-normal;
        word-break: normal;
        color: $text-color;
    }
}


h1, .h1 {
    text-transform: uppercase;
    @include fluid-type($viewport-width-min, $viewport-width-max, $h1-min-font-size, $h1-font-size);
}


h2, .h2 {
    text-transform: uppercase;
    @include fluid-type($viewport-width-min, $viewport-width-max, $h2-min-font-size, $h2-font-size);
}


h3, .h3 {
    text-transform: uppercase;
    @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);

    small {
        font-size: .8em;

        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }
    }
}


h4, .h4 {
    text-transform: none;
    @include fluid-type($viewport-width-min, $viewport-width-max, $h4-min-font-size, $h4-font-size);

    small {
        font-size: .8em;
        font-size: unquote('max(.8em, #{$font-size-base})');

        @include media-breakpoint-down(md) {
            font-size: $small-font-size;
        }
    }
}


h5, .h5 {
    text-transform: none;
    @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);

    small {
        font-size: .8em;
        font-size: unquote('max(.8em, #{$font-size-base})');

        @include media-breakpoint-down(md) {
            font-size: $small-font-size;
        }
    }
}


h6, .h6 {
    text-transform: none;
    @include fluid-type($viewport-width-min, $viewport-width-max, $h6-min-font-size, $h6-font-size);

    small {
        font-size: $small-font-size;
    }
}


hr {
    border: none;
    border-top: 1px solid $color-gray-light-3;
    margin-top: to-rem(30px);
    margin-bottom: to-rem(30px);
    opacity: 1;

    @include media-breakpoint-up(lg) {
        margin-top: to-rem(60px);
        margin-bottom: to-rem(60px);
    }
}

p {
    margin-bottom: $paragraph-margin-bottom;
}

.lead {
    @include fluid-type($viewport-width-min, $viewport-width-max, $lead-min-font-size, $lead-font-size);
    line-height: 1.6;
    margin-bottom: $paragraph-margin-bottom * 1.2;
}

.small {
    font-size: to-rem($small-font-size);
    font-weight: $font-weight-normal;
}

// text alignment
.text {
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-nocase {
        text-transform: none;
    }
}

// font-weights
.text {
    &-light { font-weight: $font-weight-light; }
    &-normal { font-weight: $font-weight-normal; }
    &-bold { font-weight: $font-weight-bold; }
    &-bolder { font-weight: $font-weight-bolder; }
}

// colors
.text-color {
    &-normal { color: $text-color; }
    &-muted { color: $text-color-muted; }
    &-primary { color: $brand-primary; }
    &-secondary { color: $brand-secondary; }
    &-danger { color: $color-danger; }
}
