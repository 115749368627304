@mixin btn-on-dark-bg {
    .area--textmedia.is-highlighted &,
    .area--parallax--content &{
        @content;
    }
}

.btn {
    //display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: $btn-min-height;
    min-width: 10ch;
    padding: $btn-padding;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    color: $text-color;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    border-radius: $btn-border-radius;
    box-shadow: none;
    transition: background-color $btn-transition-duration, background-position $btn-transition-duration, color $btn-transition-duration, border $btn-transition-duration;
    transition-timing-function: cubic-bezier(0.7,0,0.9,1);

    margin-top: 1em;
    margin-bottom: 1em;

    overflow: hidden;
    position: relative;

    @include editmode {
        &.btn_pimcore_editmode + .pimcore_editable_link > .pimcore_editable_link_text {
            display: none;
        }
    }

    > i {
        $btn-icon-font-size: 21px;
        display: inline-block;
        font-size: to-em($btn-icon-font-size, $btn-font-size);
        width: 1em;
        height: 1em;
        margin-right: to-em(10px, $btn-icon-font-size);
        flex-shrink: 0;

        svg {
            display: block;
            width: 100%;
            height: auto;

            > * {
                //fill: currentColor;
            }
        }

        &[class*='mask-icon'] {
            @extend .mask-icon;
        }
    }


    @include btn-hover-state {
        color: $text-color;
        text-decoration: none;

        &::after {
            transform: translate(-50%, -50%);
        }
    }

    &:focus {
        //outline: none;
    }

    &:active,
    &.active {

    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
    }

    // Large Button
    &-lg {
        font-size: $btn-font-size * 1.4;
    }

    // Small Button
    &-sm {
        font-size: $btn-font-size * 0.8;
    }




    &-block {
        display: flex;
        width: 100%;
        max-width: 70vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @include media-breakpoint-up(sm) {
            max-width: 50vw;
        }

        @include media-breakpoint-up(xl) {
            max-width: 30vw;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 20vw;
        }
    }

    // Primary Button
    &-primary {
        @include custom-button-variant($color-light-blue-2-courses, $color-light-blue-2-courses, $white, $color-bfi-brand-red, $color-bfi-brand-red, $white);
    }

    // Secondary Button
    &-secondary {
        @include custom-button-variant($color-bfi-brand-blue, $color-bfi-brand-blue, $white, $white, $color-bfi-brand-red, $color-bfi-brand-red);
    }

    // Outline Button
    &-outline {
        border-color: $color-gray-light-3;
        border-width: 1px;
        color: $color-bfi-brand-blue;
        box-shadow: $effect-dropshadow-default-hidden;
        transition: box-shadow $btn-transition-duration;
        transition-timing-function: ease-in-out;

        @include btn-hover-state {
            color: $color-bfi-brand-blue;
            box-shadow: $effect-dropshadow-default;
        }

        @include btn-on-dark-bg {
            color: $white;
        }
    }

    // Outline Text Button
    &-outline-text {
        font-size: $btn-outline-text-font-size;
        font-weight: $font-weight-light;
        padding: $btn-outline-text-padding;
        border-radius: $btn-outline-text-border-radius;
        border-width: 1px;
        border-color: $color-gray-light-3;
        color: $text-color;
        box-shadow: $effect-dropshadow-default-hidden;
        transition: box-shadow $btn-transition-duration;
        transition-timing-function: ease-in-out;

        text-decoration: underline;
        text-transform: none;
        
        &.btn-block {
            text-align: left;
            justify-content: left;
        }

        > i {
            font-size: to-em(25px, $btn-outline-text-font-size);
            color: $color-bfi-brand-red;
        }

        @include btn-hover-state {
            color: $color-bfi-brand-blue;
            box-shadow: $effect-dropshadow-default;
            text-decoration: underline;
        }

        @include btn-on-dark-bg {
            color: $white;
        }
    }
    &-text-icon {
        font-size: $btn-outline-text-font-size;
        font-weight: $font-weight-light;
        padding: 0;
        border-radius: 0;
        border-width: 0;
        color: $text-color;
        box-shadow: none;
        min-height: 0;
        min-width: 0;
        margin: 0;
        justify-content: left;
        text-align: left;

        text-decoration: underline;
        text-transform: none;

        > i {
            font-size: to-em(25px, $btn-outline-text-font-size);
            color: $color-bfi-brand-red;
        }

        @include btn-hover-state {
            text-decoration: none;
        }

        @include btn-on-dark-bg {
            color: $white;
        }
    }


    // Text Button
    &-text {
        @include btn-hover-state {
            text-decoration: underline;
        }

        @include btn-on-dark-bg {
            color: $white;
        }
    }

    &-icon {
        min-width: 0;
        padding: 0 to-rem(15px);

        > i {
            margin-right: 0;
        }
    }
}

.btn:not(.btn-block) + .btn:not(.btn-block) {
    margin-left: to-rem(10px);
}


// buttons in area cols
.area > .cols > .btn {
    margin-top: 0;
    margin-bottom: 0;

    @include media-breakpoint-only(xs) {
        max-width: none;
    }
}