.a11y--jump-link {
    background-color: $black;
    color: $white;
    text-decoration: none;
    margin: 0;
    padding: .5em 1em;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 1000000;

    &:active, &:focus {
        display: block;
        width: 100%;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}