.link-arrow,
a.link-arrow {
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;

    &::before {
        content: '';
        display: block;
        flex-shrink: 0;
        flex-grow: 0;
        width: to-rem(6px);
        height: to-rem(10px);
        margin-right: to-rem(5px);
        @include insertIcon('angle-right');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:hover, &:focus {
        color: inherit;
    }
}