.area--search-result {
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

.search-result {
    border-radius: to-rem($effect-default-border-radius);
    padding: to-rem(20px);
    color: $text-color;
    background-color: $color-light-blue-1;
    border-color: $color-light-blue-1;

    margin-top: to-rem(10px);
    margin-bottom: to-rem(10px);

    @include media-breakpoint-up(sm) {
        padding: to-rem(30px);
    }

    &:focus-within {
        outline: 3px solid #6ABBF3;
    }

    > a {
        text-decoration: none;
        color: currentColor;

        &:hover, &:focus {
            color: currentColor;
            text-decoration: underline;
        }
    }

    &--headline {
        color: $brand-primary;
        margin-bottom: to-rem(5px);
        text-transform: none;
        text-decoration: none;
    }
    &--url {
        font-size: $small-font-size;
        margin-bottom: to-rem(5px);
        text-decoration: underline;
    }
    &--content {
        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
}