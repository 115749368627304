@import "../components/tables";

table.contenttable,
.area--text table,
.area--table table {
    border-radius: to-rem($effect-default-border-radius);
    overflow: hidden;
    background-color: $white;
    box-shadow: $effect-dropshadow-big;

    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        td {
            min-width: to-rem(200px);
            max-width: 60vw;
            font-size: to-em(12px);
        }
    }
}