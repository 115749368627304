
.page-header--tools {

    padding: to-rem(20px) 0;
    background-image: $effect-gradient-bfi;
    background-size: 100% 100%;
    background-position: top left;

    @include media-breakpoint-up(md) {
        padding: to-rem(40px) 0;
    }

    &--inner {
        @extend .container;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: "breadcrumb share";
        align-items: center;
    }
}
