.area--content-container {
    @include fluid-property('padding-top', $viewport-width-min, $viewport-width-max, 60px, 100px);
    @include fluid-property('padding-bottom', $viewport-width-min, $viewport-width-max, 60px, 100px);

    &.highlight {
        background-color: $brand-primary;
        background-image: $effect-gradient-bfi;
        background-size: 100% 100%;
        background-position: top left;
        color: $white;

        .area--content-container--inner {
            h1, h2, h3, h4, h5, h6,
            .h1, .h2, .h3, .h4, .h5, .h6 {
                color: currentColor;

                small {
                    color: currentColor;
                    font-size: .65em;
                }
            }
            li::marker {
                color: currentColor;
            }
        }
    }
}

.area--content-container--inner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include fluid-property('padding-left', $viewport-width-min, $viewport-width-max, 30px, 60px);
    @include fluid-property('padding-right', $viewport-width-min, $viewport-width-max, 30px, 60px);

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}
