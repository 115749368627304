
.footer-newsletter-small {
    grid-area: newsletter;
    margin-top: to-rem(60px);
    margin-bottom: to-rem(60px);

    @include media-breakpoint-up(md) {
        max-width: to-rem(360px);
    }

    &--fieldset {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: "inputField submitButton";
        align-items: center;
        box-shadow: $effect-dropshadow-big;
        border-radius: $effect-default-border-radius;
        overflow: hidden;
    }

    &--field, &--submit {
        display: block;
        margin: 0;
        border: 1px solid $white;
    }

    &--field {
        grid-area: inputField;
        display: block;
        width: 100%;
        height: 100%;
        padding-left: to-rem(20px);
        font-size: $btn-font-size;
    }

    &--submit {
        @extend .btn;

        grid-area: submitButton;
        color: $white;
        background-color: $color-bfi-brand-blue;
        border-color: $color-bfi-brand-blue;
        border-radius: 0;

        &:hover, &:focus {
            background-color: $color-light-blue-2-courses;
            border-color: $color-light-blue-2-courses;
        }
    }
}