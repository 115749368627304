@import '../../build/node_modules/tarteaucitronjs/css/tarteaucitron';

html body #tarteaucitronRoot {

    #tarteaucitronAlertBig {
        box-sizing: border-box;
        background-color: $color-bfi-brand-blue;
        padding: $grid-gutter-width / 2;
        padding-top: to-rem(25px);
        bottom: 0;
        left: 0;
        //font-size: $small-font-size !important;
        font-size: $small-font-size !important;

        @include media-breakpoint-up(md) {
            font-size: $font-size-base !important;
            max-width: to-rem(375px);
            left: $grid-gutter-width;
            border-radius: $effect-default-border-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: $effect-dropshadow-big;
        }

        #tarteaucitronDisclaimerAlert {
            font-size: 1em !important;
            margin-bottom: 2em;
        }

        button {
            display: block;
            box-sizing: border-box;
            width: 100%;
            margin: .5em 0;
            border-radius: $btn-border-radius;

            font-size: 1em !important;
            //font-size: $btn-font-size !important;
            font-family: $btn-font-family;
            //font-weight: $btn-font-weight;
            line-height: $btn-line-height;

            padding: $btn-padding;
            min-height: $btn-min-height;

            &#tarteaucitronPrivacyUrl {
                min-height: 0;
                margin-top: 1.5em;
                font-size: $btn-font-size !important;
            }
        }
    }
}