
.header-action-button--btn-cart {
    overflow: visible;

    &.open {
        .header-action-cart-dialog {
            display: block;
        }
    }

    &--toggle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        overflow: hidden;
        text-indent: -99999em;
        background-color: transparent;
    }
}

.header-action-cart-dialog {
    display: none;
    position: absolute;
    top: calc(100% + 15px);
    right: to-rem(15px);
    background-color: $white;
    border-radius: $effect-default-border-radius;
    box-shadow: $effect-dropshadow-big;
    color: $text-color;
    text-indent: 0;
    cursor: default;
    padding: to-em(30px) 0;

    font-size: $font-size-base;
    @include fluid-type($viewport-width-min, $viewport-width-max, $small-font-size, $font-size-base);

    * {
        text-indent: 0;
    }

    width: to-em(365px);
    max-width: 80vw;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: $effect-default-border-radius;
        width: to-em(20px);
        height: to-em(20px);
        background-color: $white;
        transform: rotate(45deg) translateY(-25%);
    }

    @include media-breakpoint-up(lg) {
        right: 0;
    }

    &--content {
        border-radius: $effect-default-border-radius;
        max-height: 50vh;
        overflow-y: auto;
        overscroll-behavior-y: contain;
        -webkit-overflow-scrolling: touch;
        margin: 0 to-em(15px);
    }
}

.header-action-cart-dialog--item {
    padding: to-em(30px) to-em(15px);
    border-bottom: 1px solid $color-gray-light-3;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    display: block;
    text-decoration: none;
    &:hover, &:focus {
        color: $text-color;
        text-decoration: none;
    }

    &--headline {
        font-size: to-em(18px);
        font-weight: $font-weight-bold;
        color: $text-color;
        margin-bottom: to-em(10px);

        small, .small {
            display: block;
            font-weight: normal;
            font-size: $small-font-size;
        }
    }

    &--date {
        font-weight: $font-weight-bolder;
        margin-bottom: to-em(10px);
    }

    &--price {
        font-weight: $font-weight-bold;
    }
}

.header-action-cart-dialog--buttons {
    padding: to-em(30px);
    padding-bottom: 0;
    text-align: right;

    .btn {
        margin: 0;
    }
}