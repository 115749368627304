/**
 *
 * Form Container
 *
 */
.form-container,
.form-builder-wrapper {
    fieldset {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        legend:not(.label) {
            display: block;
            font-size: to-rem($small-font-size);
            font-weight: $font-weight-normal;
            color: $color-gray-dark;
        }
    }

    .formbuilder-row:not(.full_width_row) fieldset .form-group > .row {
        @extend .cols, .cols--2;
    }

    .cols,
    .formbuilder-row:not(.full_width_row) fieldset .form-group > .row {
        grid-column-gap: $form-gutter-width;
        grid-row-gap: $form-gutter-width;
        margin-bottom: $form-gutter-width;

        > [class*='col-'] {
            @extend .col;
        }
    }
}

.form-group {
    margin-bottom: $form-gutter-width;

    .cols & {
        margin-bottom: 0;
    }

    &.form-group-horizontal {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;

            > * {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                margin-left: to-rem(30px);

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

// Form group for buttons -> align right
.form-group.form-buttons,
.formbuilder-row.form-buttons > .form-group {
    //text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;

    .btn {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
        &.col {
            > .btn {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/**
 *
 * Form Fields
 *
 */
.form-container,
.form-builder-wrapper,
.card-form-container {

    // label
    label:not(input + label),
    .label:not(input + .label) {
        font-weight: $font-weight-bolder;
        font-size: $small-font-size;
        text-transform: uppercase;
        margin-bottom: to-rem(5px);
    }

    // hilfetext
    input + small,
    select + small,
    textarea + small {
        display: block;
        text-align: right;
        padding: $formfield-padding;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: $font-weight-normal;
        color: $color-gray-dark;
        margin-top: to-em(3px, $small-font-size);
    }

    // general input fields
    input[type=text],
    input[type=search],
    input[type=number],
    input[type=tel],
    input[type=email],
    input[type=date],
    input[type=datetime-local],
    input[type=password],
    select,
    textarea {
        //line-height: 1.4667;
        padding: $formfield-padding;
        width: 100%;
        color: $formfield-color;
        background-color: $formfield-bg;
        border: $formfield-border-width solid $formfield-border-color;
        border-radius: $formfield-border-radius;
        position: relative;


        &:focus {
            outline: 0;
            color: $formfield-focus-color;
            background-color: $formfield-focus-bg;
            border-color: $formfield-focus-border-color;
            box-shadow: 0 0 0 to-rem(2px) $formfield-focus-border-color;
        }
        &[readonly], &[disabled] {
            color: rgba($formfield-color, .5);
            background-color: rgba($black, 0.1);
            border-color: rgba($formfield-border-color, .3);
            cursor: not-allowed;
        }

        // validation icon
        /*&:not(:focus):not(:placeholder-shown):invalid,
        &[required]:not(:focus):not(:placeholder-shown):invalid{
            border-color: $color-danger;
        }*/

        &:not(:focus):not(:placeholder-shown):invalid,
        &.has-error:invalid {
            border-color: $color-danger;

            & + small {
                color: $color-danger;
            }
        }
    }

    .invalid-feedback {
        @extend .small;
        color: $color-danger;
        text-transform: none;
    }


    // komisches spacing bei den feldern abfangen
    // date felder sind höher als andere
    input[type=date],
    input[type=datetime-local] {
        padding-top: ((8px / $font-size-root) * 1rem);
        padding-bottom: ((9px / $font-size-root) * 1rem);
    }

    // textarea
    textarea {
        min-height: 8em;
    }


    // select
    select {
        cursor: pointer;
        
        &,
        &:focus {
            appearance: none;

            @include insertIcon('caret-down', $black);
            background-size: to-em(8px) auto;
            background-repeat: no-repeat;
            background-position: right to-em(20px) center;
        }
    }

    .form-label {
        text-transform: uppercase;
        color: $text-color;
        font-weight: $font-weight-bold;
        margin-bottom: to-em(15px);
    }

    // checkbox
    .form-check {
        display: flex;
        margin-bottom: to-em(15px);

        > * {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        appearance: none;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: to-em(22px);
        box-sizing: border-box;
        vertical-align: top;
        width: to-em(22px);
        height: to-em(22px);
        margin-right: to-em(15px);
        border: 1px solid $color-gray-light-2;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }
    input[type="checkbox"] {
        border-radius: to-em(5px);
        background-size: to-em(10px) auto;

        &:checked {
            background-color: $color-light-blue-2-courses;
            border-color: $color-light-blue-2-courses;
            @include insertIcon('check', $white);
        }
    }
    input[type="radio"] {
        border-radius: 50%;
        background-size: to-em(10px) to-em(10px);
        @include insertIcon('solid/circle', $color-gray-light-3);

        &:checked {
            @include insertIcon('solid/circle', $color-light-blue-2-courses);
        }
    }

    input.search-keyword {
        background-size: to-em(16px) to-em(16px);
        background-repeat: no-repeat;
        background-position: right to-em(20px) center;

        @include insertIcon('search', $black);
    }

    // submit
    button.btn {
        cursor: pointer;
    }
}

.form-captcha {
    img {
        margin-bottom: to-em(15px);
    }
}


.form-infotext-scroll {
    overflow: hidden;
    background-color: $formfield-bg;
    border: $formfield-border-width solid $formfield-border-color;
    border-radius: $formfield-border-radius;
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: to-rem(18px);
        z-index: 1;
    }

    &:before {
        top: 0;
        background: linear-gradient(to bottom,  rgba($white,1) 0%,rgba($white,0) 100%);
    }
    &:after {
        bottom: 0;
        background: linear-gradient(to bottom,  rgba($white,0) 0%,rgba($white,1) 100%);
    }

    &--content {
        @extend .small;
        color: $text-color-muted;

        padding: to-rem(20px);
        width: 100%;
        max-height: min(50vh, 300px);
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }

        ul {
            padding-left: to-rem(15px);

            > li {
                &::marker {
                    color: currentColor;
                }
            }
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            font-size: 1em;
            line-height: 1.2;
            color: currentColor;
            text-align: left;
            text-transform: none;
            font-weight: $font-weight-bold;
            margin-bottom: 1em;
            margin-top: 1em;
        }
    }
}





/**
 *
 *
 * Optimizations for pimcore-formbuilder
 * https://github.com/dachcom-digital/pimcore-formbuilder
 *
 * @todo: check if needed
 *
 */

// hide honeypot
// https://github.com/dachcom-digital/pimcore-formbuilder/blob/master/docs/90_FrontendTips.md#hide-the-honeypot-for-real
input[name$='[inputUserName]'] {
    display: none;
}

// Hide reCAPTCHA Badge on documents without pages
// https://github.com/dachcom-digital/pimcore-formbuilder/blob/master/docs/90_FrontendTips.md#hide-recaptcha-badge-on-documents-without-pages
html:not(.form-builder-rec3-available) .grecaptcha-badge {
    visibility: hidden;
}

// * for required fields
// https://github.com/dachcom-digital/pimcore-formbuilder/blob/master/docs/90_FrontendTips.md#required-fields
// .invalid-feedback is always rendered (different form default
.required .invalid-feedback:before{
    content: ' *';
    color: $black;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
}

@include editmode {
    .pimcore_area_entry[type="formbuilder_form"] .pimcore_area_edit_button {
        z-index: 9999;
        top: 16px;
        right: 8px;
    }
}
