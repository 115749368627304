
// external Links
.main-content {
    p, li, table {
        a[href*="//"]:not([href*="#{$site-host}"]),
        a[target='_blank'] {
            &:not(.btn) {
                &::after {
                    content: '';
                    display: inline-block;
                    width: to-rem(10px);
                    height: to-rem(10px);
                    margin-left: to-rem(5px);
                    margin-right: to-rem(2px);
                    background-image: svg-load('external-link-alt.svg');
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    opacity: .5;
                }
            }
        }
    }
}