.area.area--course-filter {
    margin-bottom: to-rem(40px);
}

.area--course-filter + .area {
    margin-top: to-rem(40px);
}

.course-filter {
    background-color: $color-light-blue-1;
    border: 1px solid #D8E2ED;
    border-radius: $effect-default-border-radius;
    overflow: hidden;

    h4, .h4 {
        color: $color-bfi-brand-blue;
        text-transform: uppercase;
    }
    label, .label {
        color: $color-bfi-brand-blue;
    }

    &--header {
        padding: to-rem(20px) to-rem(44px) to-rem(20px) to-rem(20px);

        // hide native arrow
        list-style: none;
        &::marker,
        &::-webkit-details-marker {
            display:none;
        }

        // icon
        background-size: to-em(23px) to-em(23px);
        background-repeat: no-repeat;
        background-position: right to-em(20px) center;
        @include insertIcon('filter', $color-bfi-brand-blue);

        .course-filter[open] & {
            background-size: to-em(23px) to-em(23px);
            @include insertIcon('close-custom', $color-bfi-brand-blue);
        }
    }

    &--collapse {
    }

    &--content {
        padding: to-rem(30px);
        padding-top: to-rem(10px);
    }
}

.course-filter--content {


    input[type=text],
    input[type=number],
    input[type=email],
    input[type=date],
    input[type=datetime-local],
    input[type=password],
    select,
    textarea {
        //border-color: transparent;
    }


    input[type="checkbox"],
    input[type="radio"] {
        //border-color: transparent;
        background-color: $white;
    }

    .form-check {
        @include media-breakpoint-up(sm) {
            display: inline-flex;
            margin-left: to-em(15px);

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    .form-buttons {
        flex-wrap: wrap-reverse;
        justify-content: center;

        .btn {
            margin-left: auto !important;
            width: 100%;
        }

        @include media-breakpoint-between(md, lg) {
            flex-wrap: nowrap;
            justify-content: flex-end;

            .btn {
                width: auto;
                margin-left: to-rem(10px) !important;
            }
        }
    }
}


.course-filter--cols {
    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: 315px 1fr 190px;
        grid-template-areas: "keyword attributes buttons";
        grid-column-gap: $grid-gutter-width;
    }

    .course-filter--col {
        &-keyword {
            grid-area: keyword;
        }
        &-attributes {
            grid-area: attributes;
        }
        &-buttons {
            grid-area: buttons;
        }
    }
}