.tag {
    display: inline-block;
    font-size: to-rem(12px);
    font-style: normal;
    font-weight: $font-weight-normal;
    padding: to-em(5px, 12px) to-em(8px, 12px);
    color: $color-gray-dark;
    border: 1px solid $color-gray-light-1;
    border-radius: to-em(5px, 12px);
    background-color: $white;
    margin: to-rem(2.5px) to-rem(5px) to-rem(2.5px) 0;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
        color: $color-gray-dark;
    }

    &[aria-description]:not([aria-description=""]):not(a) {
        cursor: help;
    }

    @include tooltip-pseudo;
}