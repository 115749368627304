:root {
    --mainContentPaddingTop: #{to-rem(60px)};
    --mainContentPaddingBottom: #{to-rem(60px)};

    @include media-breakpoint-up(lg) {
        --mainContentPaddingTop: #{to-rem(100px)};
        --mainContentPaddingBottom: #{to-rem(100px)};
    }
}

.main-content {
    padding-top: var(--mainContentPaddingTop);
    padding-bottom: var(--mainContentPaddingBottom);

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

.main-content--container {
    > *:first-child:not(.sr-only) {
        margin-top: 0;
    }
    > .sr-only:first-child + .area {
        margin-top: 0;
    }
    > *:last-child:not(.btn) {
        margin-bottom: 0;
    }

    @include editmode {
        > div[data-name="content"] {
            > .pimcore_area_entry {
                &:first-child {
                    > .area {
                        margin-top: 0;
                    }
                }

                &:last-child {
                    > .area {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}