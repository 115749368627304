
@at-root {
    :root {
        --scrollbarWidth: 0px;

        @include media-breakpoint-up(xl) {
            // Default Windows 10 Desktop
            &:not([data-useragent*='Mac']) {
                --scrollbarWidth: 17px;
            }
        }
    }
}