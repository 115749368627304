@charset "UTF-8";
/*** IMPORT CONFIG ***/
/*** IMPORT VENDOR ***/
/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.5s ease;
}
#baguetteBox-overlay.visible {
  opacity: 1;
}
#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}
#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}
#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left 0.4s ease, transform 0.4s ease;
}
#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight 0.4s ease-out;
}
#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft 0.4s ease-out;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}
.baguetteBox-button#previous-button, .baguetteBox-button#next-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color 0.4s ease;
}
.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}
.baguetteBox-button#next-button {
  right: 2%;
}
.baguetteBox-button#previous-button {
  left: 2%;
}
.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}
.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle {
  padding: 1em 1em;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle:hover {
  opacity: 1;
}
.hamburger.is-active:hover, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
#toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after,
#toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::after {
  background-color: currentColor;
}

.hamburger-box {
  width: 2.5em;
  height: 1.6em;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.1em;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 2.5em;
  height: 0.2em;
  background-color: currentColor;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -0.7em;
}
.hamburger-inner::after {
  bottom: -0.7em;
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner, .hamburger .hamburger-inner, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner {
  top: 0.1em;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before, .hamburger .hamburger-inner::before, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::before {
  top: 0.7em;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after, .hamburger .hamburger-inner::after, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::after {
  top: 1.4em;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner, .is-active.hamburger .hamburger-inner, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner {
  transform: translate3d(0, 0.7em, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before, .is-active.hamburger .hamburger-inner::before, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after, .is-active.hamburger .hamburger-inner::after, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::after {
  transform: translate3d(0, -1.4em, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

.hamburger, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle {
  font-size: 0.6667em;
}

/**
 * IMPORT FONTS
 * ------------
 * insert fonts in .build/.fonts
 * execute gulp fonts
 */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/googlefonts/Roboto-italic-300.ttf) format("truetype"), url(../fonts/googlefonts/Roboto-italic-300.woff) format("woff"), url(../fonts/googlefonts/Roboto-italic-300.woff2) format("woff2");
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/googlefonts/Roboto-italic-400.ttf) format("truetype"), url(../fonts/googlefonts/Roboto-italic-400.woff) format("woff"), url(../fonts/googlefonts/Roboto-italic-400.woff2) format("woff2");
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/googlefonts/Roboto-normal-300.ttf) format("truetype"), url(../fonts/googlefonts/Roboto-normal-300.woff) format("woff"), url(../fonts/googlefonts/Roboto-normal-300.woff2) format("woff2");
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/googlefonts/Roboto-normal-400.ttf) format("truetype"), url(../fonts/googlefonts/Roboto-normal-400.woff) format("woff"), url(../fonts/googlefonts/Roboto-normal-400.woff2) format("woff2");
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/googlefonts/Roboto-normal-700.ttf) format("truetype"), url(../fonts/googlefonts/Roboto-normal-700.woff) format("woff"), url(../fonts/googlefonts/Roboto-normal-700.woff2) format("woff2");
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/googlefonts/Roboto-normal-900.ttf) format("truetype"), url(../fonts/googlefonts/Roboto-normal-900.woff) format("woff"), url(../fonts/googlefonts/Roboto-normal-900.woff2) format("woff2");
  unicode-range: U+0-10FFFF;
}
/*** IMPORT BASIC STUFF ***/
/* Fluid for mobile (375px) */
/* Fluid up to xl */
/* Fluid up to xlplus */
/* Fluid up to outer */
/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */
/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */
/* Container Mimicry */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Show focus styles on keyboard focus. */
:focus-visible {
  outline: 3px solid #6ABBF3;
  outline-offset: 5px;
}

/* Hide focus styles if they're not needed, for example,
when an element receives focus via the mouse. */
:focus:not(:focus-visible) {
  outline: 0;
}

html {
  font-family: sans-serif;
  font-size: 15px;
  line-height: 1.3333;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3333;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: inherit;
  text-decoration: underline;
  background-color: transparent;
}
a:hover {
  color: #C61932;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

button:focus {
  /*outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;*/
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@keyframes animation-ctaSearch-floating {
  0% {
    transform: translateY(var(--ctaSearchTranslateYBase));
  }
  50% {
    transform: translateY(var(--ctaSearchTranslateYHover));
  }
  100% {
    transform: translateY(var(--ctaSearchTranslateYBase));
  }
}
@keyframes animation-ctaSearch-shadow {
  0% {
    width: 100%;
  }
  50% {
    width: 90.9090909091%;
  }
  100% {
    width: 100%;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.d-none {
  display: none !important;
}

.d-xs-none {
  display: none !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-none {
    display: none !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-none {
    display: none !important;
  }
}
.d-inline {
  display: inline !important;
}

.d-xs-inline {
  display: inline !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-inline {
    display: inline !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-inline {
    display: inline !important;
  }
}
.d-inline-block {
  display: inline-block !important;
}

.d-xs-inline-block {
  display: inline-block !important;
}

@media (min-width: 576px) {
  .d-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-inline-block {
    display: inline-block !important;
  }
}
.d-block {
  display: block !important;
}

.d-xs-block {
  display: block !important;
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-block {
    display: block !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-block {
    display: block !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-block {
    display: block !important;
  }
}
.d-grid {
  display: grid !important;
}

.d-xs-grid {
  display: grid !important;
}

@media (min-width: 576px) {
  .d-sm-grid {
    display: grid !important;
  }
}
@media (min-width: 768px) {
  .d-md-grid {
    display: grid !important;
  }
}
@media (min-width: 992px) {
  .d-lg-grid {
    display: grid !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-grid {
    display: grid !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-grid {
    display: grid !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-grid {
    display: grid !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-grid {
    display: grid !important;
  }
}
.d-table {
  display: table !important;
}

.d-xs-table {
  display: table !important;
}

@media (min-width: 576px) {
  .d-sm-table {
    display: table !important;
  }
}
@media (min-width: 768px) {
  .d-md-table {
    display: table !important;
  }
}
@media (min-width: 992px) {
  .d-lg-table {
    display: table !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-table {
    display: table !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-table {
    display: table !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-table {
    display: table !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-table {
    display: table !important;
  }
}
.d-table-cell {
  display: table-cell !important;
}

.d-xs-table-cell {
  display: table-cell !important;
}

@media (min-width: 576px) {
  .d-sm-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 768px) {
  .d-md-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 992px) {
  .d-lg-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-table-cell {
    display: table-cell !important;
  }
}
.d-table-row {
  display: table-row !important;
}

.d-xs-table-row {
  display: table-row !important;
}

@media (min-width: 576px) {
  .d-sm-table-row {
    display: table-row !important;
  }
}
@media (min-width: 768px) {
  .d-md-table-row {
    display: table-row !important;
  }
}
@media (min-width: 992px) {
  .d-lg-table-row {
    display: table-row !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-table-row {
    display: table-row !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-table-row {
    display: table-row !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-table-row {
    display: table-row !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-table-row {
    display: table-row !important;
  }
}
.d-flex {
  display: flex !important;
}

.d-xs-flex {
  display: flex !important;
}

@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-flex {
    display: flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-flex {
    display: flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-flex {
    display: flex !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-flex {
    display: flex !important;
  }
}
.d-inline-flex {
  display: inline-flex !important;
}

.d-xs-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1920px) {
  .d-outer-inline-flex {
    display: inline-flex !important;
  }
}
.mt-xs-0 {
  margin-top: 0 !important;
}

.mb-xs-0 {
  margin-bottom: 0 !important;
}

.ml-xs-0 {
  margin-left: 0 !important;
}

.mr-xs-0 {
  margin-right: 0 !important;
}

.mx-xs-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-xs-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0 {
    margin-left: 0 !important;
  }

  .mr-xxl-0 {
    margin-right: 0 !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1600px) {
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }

  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxxl-0 {
    margin-left: 0 !important;
  }

  .mr-xxxl-0 {
    margin-right: 0 !important;
  }

  .mx-xxxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1920px) {
  .mt-outer-0 {
    margin-top: 0 !important;
  }

  .mb-outer-0 {
    margin-bottom: 0 !important;
  }

  .ml-outer-0 {
    margin-left: 0 !important;
  }

  .mr-outer-0 {
    margin-right: 0 !important;
  }

  .mx-outer-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-outer-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.embed-responsive {
  position: relative;
  width: 100%;
  height: 0;
}
.embed-responsive-1by1 {
  padding-bottom: 100%;
}
.embed-responsive-3by2 {
  padding-bottom: 66.66666%;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive > *, .embed-responsive > .pimcore_editable_video > * {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.embed-responsive > iframe {
  border: none;
}

:root {
  --scrollbarWidth: 0px;
}
@media (min-width: 1200px) {
  :root:not([data-useragent*=Mac]) {
    --scrollbarWidth: 17px;
  }
}

:root {
  --mainContentContainer-width: auto;
  --mainContentContainer-innerWidth: auto;
  --mainContentContainer-paddingLeft: 30px;
  --mainContentContainer-paddingRight: 30px;
  --mainContentContainer-marginLeft: 0px;
  --mainContentContainer-marginRight: 0px;
  --mainContentContainer-offsetLeft: 0px;
  --mainContentContainer-offsetLeftWithPadding: 30px;
}
@media (min-width: 768px) {
  :root {
    --mainContentContainer-width: auto;
    --mainContentContainer-innerWidth: auto;
    --mainContentContainer-paddingLeft: 60px;
    --mainContentContainer-paddingRight: 60px;
    --mainContentContainer-marginLeft: 0px;
    --mainContentContainer-marginRight: 0px;
    --mainContentContainer-offsetLeft: 0px;
    --mainContentContainer-offsetLeftWithPadding: 60px;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .area--thumbnail--caption, .cart-big--item--title--headline, .h5, .h6 {
  line-height: 1.2;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: #003770;
  hyphens: auto;
  word-break: break-all;
}
@supports (hyphens: auto) {
  h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .area--thumbnail--caption, .cart-big--item--title--headline, .h5, .h6 {
    word-break: normal;
  }
}
@media (min-width: 992px) {
  h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .area--thumbnail--caption, .cart-big--item--title--headline, .h5, .h6 {
    word-break: normal;
  }
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
.h1 a, .h2 a, .h3 a, .h4 a, .area--thumbnail--caption a, .cart-big--item--title--headline a, .h5 a, .h6 a {
  color: inherit;
  text-decoration: none;
}
h1 a:hover, h1 a:focus, h2 a:hover, h2 a:focus, h3 a:hover, h3 a:focus, h4 a:hover, h4 a:focus, h5 a:hover, h5 a:focus, h6 a:hover, h6 a:focus,
.h1 a:hover,
.h1 a:focus, .h2 a:hover, .h2 a:focus, .h3 a:hover, .h3 a:focus, .h4 a:hover, .area--thumbnail--caption a:hover, .cart-big--item--title--headline a:hover, .h4 a:focus, .area--thumbnail--caption a:focus, .cart-big--item--title--headline a:focus, .h5 a:hover, .h5 a:focus, .h6 a:hover, .h6 a:focus {
  color: inherit;
  text-decoration: none;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .area--thumbnail--caption small, .cart-big--item--title--headline small, .h5 small, .h6 small {
  display: block;
  margin-top: 0.25em;
  line-height: 1.15;
  font-size: 0.6em;
  font-weight: 400;
  word-break: normal;
  color: #000;
}

h1, .h1 {
  text-transform: uppercase;
}
h1, .h1 {
  font-size: 1.6666666667rem;
}
@media screen and (min-width: 768px) {
  h1, .h1 {
    font-size: calc(25px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  text-transform: uppercase;
}
h2, .h2 {
  font-size: 1.6666666667rem;
}
@media screen and (min-width: 768px) {
  h2, .h2 {
    font-size: calc(25px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  h2, .h2 {
    font-size: 3rem;
  }
}

h3, .h3 {
  text-transform: uppercase;
}
h3, .h3 {
  font-size: 1.3333333333rem;
}
@media screen and (min-width: 768px) {
  h3, .h3 {
    font-size: calc(20px + 10 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  h3, .h3 {
    font-size: 2rem;
  }
}
h3 small, .h3 small {
  font-size: 0.8em;
}
@media (max-width: 767.98px) {
  h3 small, .h3 small {
    font-size: 1rem;
  }
}

h4, .h4, .area--thumbnail--caption, .cart-big--item--title--headline {
  text-transform: none;
}
h4, .h4, .area--thumbnail--caption, .cart-big--item--title--headline {
  font-size: 1.2rem;
}
@media screen and (min-width: 768px) {
  h4, .h4, .area--thumbnail--caption, .cart-big--item--title--headline {
    font-size: calc(18px + 2 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  h4, .h4, .area--thumbnail--caption, .cart-big--item--title--headline {
    font-size: 1.3333333333rem;
  }
}
h4 small, .h4 small, .area--thumbnail--caption small, .cart-big--item--title--headline small {
  font-size: 0.8em;
  font-size: max(.8em, 1rem);
}
@media (max-width: 767.98px) {
  h4 small, .h4 small, .area--thumbnail--caption small, .cart-big--item--title--headline small {
    font-size: 0.8rem;
  }
}

h5, .h5 {
  text-transform: none;
}
h5, .h5 {
  font-size: 1.0666666667rem;
}
@media screen and (min-width: 768px) {
  h5, .h5 {
    font-size: calc(16px + 2 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.2rem;
  }
}
h5 small, .h5 small {
  font-size: 0.8em;
  font-size: max(.8em, 1rem);
}
@media (max-width: 767.98px) {
  h5 small, .h5 small {
    font-size: 0.8rem;
  }
}

h6, .h6 {
  text-transform: none;
}
h6, .h6 {
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  h6, .h6 {
    font-size: calc(15px + 0 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  h6, .h6 {
    font-size: 1rem;
  }
}
h6 small, .h6 small {
  font-size: 0.8rem;
}

hr {
  border: none;
  border-top: 1px solid #eee;
  margin-top: 2rem;
  margin-bottom: 2rem;
  opacity: 1;
}
@media (min-width: 992px) {
  hr {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

p {
  margin-bottom: 1em;
}

.lead, .area--testimonial-entry--content, .page-hero--entry--content p {
  line-height: 1.6;
  margin-bottom: 1.2em;
}
.lead, .area--testimonial-entry--content, .page-hero--entry--content p {
  font-size: 1.0666666667rem;
}
@media screen and (min-width: 768px) {
  .lead, .area--testimonial-entry--content, .page-hero--entry--content p {
    font-size: calc(16px + 2 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .lead, .area--testimonial-entry--content, .page-hero--entry--content p {
    font-size: 1.2rem;
  }
}

.small, .cart-big--item--title--info, .form-infotext-scroll--content, .form-container .invalid-feedback,
.form-builder-wrapper .invalid-feedback,
.card-form-container .invalid-feedback {
  font-size: 0.8rem;
  font-weight: 400;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-nocase {
  text-transform: none;
}

.text-light {
  font-weight: 300;
}
.text-normal {
  font-weight: 400;
}
.text-bold {
  font-weight: 700;
}
.text-bolder {
  font-weight: 900;
}

.text-color-normal {
  color: #000;
}
.text-color-muted {
  color: #757575;
}
.text-color-primary {
  color: #003770;
}
.text-color-secondary {
  color: #C61932;
}
.text-color-danger {
  color: #B83D3D;
}

div#tarteaucitronAlertBig:focus {
  outline: 0;
}

.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%;
}

#tarteaucitronContentWrapper {
  display: unset;
}

/** 14042021 **/
span.tarteaucitronReadmoreSeparator {
  display: inline !important;
}

/******/
/** 09052021 **/
.tarteaucitronName .tacCurrentStatus, .tarteaucitronName .tarteaucitronReadmoreSeparator {
  color: #333 !important;
  font-size: 12px !important;
  text-transform: capitalize;
}

/**************/
/** 27032021 **/
button.tarteaucitron-toggle-group {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 700 !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-weight: 500 !important;
  font-size: 14px;
  margin-top: 7px;
}

.tarteaucitronLine {
  border-left: 0px solid transparent !important;
}

/*****/
/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron ul#tarteaucitronServices_mandatory .tarteaucitronDeny {
    display: none !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
    margin-bottom: 8px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }

  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }

  li.tarteaucitronLine .tarteaucitronName span {
    width: 80% !important;
    display: inline-block;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: "▴";
  }
}
@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: "▴";
    margin-left: 15px;
  }
}
/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol, #tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
  content: "";
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 21px !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
  box-shadow: 0 40px 60px #545454;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }

  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}
@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }

  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }

  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}
/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee;
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitron a {
  color: rgb(66, 66, 66);
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  color: #333;
  font-family: sans-serif !important;
  font-size: 14px;
  line-height: normal;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
  height: auto;
  overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  background: #333333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: rgba(51, 51, 51, 0.1);
  border-left: 5px solid transparent;
  margin: 0;
  overflow: hidden;
  padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #333;
  border: 3px solid #333;
  border-left: 9px solid #333;
  border-top: 5px solid #333;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: left;
  width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  background: gray;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9C1A1A;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  color: #333;
  font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px;
}

span#tarteaucitronDisclaimerAlert {
  padding: 0 10px;
  display: inline-block;
}

#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #333 !important;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #333;
  color: #fff;
  display: none;
  font-size: 15px !important;
  left: 0;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 10px 0 10px 0;
  margin: auto;
  width: 100%;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font: 15px verdana;
  color: #fff;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  line-height: 1.2;
  padding: 5px 10px;
  text-decoration: none;
  margin-left: 7px;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: #9C1A1A;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
  background: #fff;
  color: #333;
  font-size: 13px;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px;
}

#tarteaucitronPercentage {
  background: #0A0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0;
}

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}

.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
  border: none;
}

#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  content: "✗";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: "✓";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: "✛";
  display: inline-block;
  color: white;
}

/***
 * Small alert
 */
.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: #333;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  background-color: gray;
  border-radius: 5px;
  display: block;
  height: 8px;
  margin-bottom: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background: #333;
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  color: #fff;
  font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
  color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
  color: #333;
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
  background: #333;
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1B870B;
  display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  height: 240px;
  width: 120px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #555;
}

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em !important;
  margin-top: 4px !important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  color: #FFFF;
  font-size: 1.8rem;
  cursor: pointer;
  top: 10px;
  right: 26px;
}

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

html body #tarteaucitronRoot #tarteaucitronAlertBig {
  box-sizing: border-box;
  background-color: #003770;
  padding: 1rem;
  padding-top: 1.6666666667rem;
  bottom: 0;
  left: 0;
  font-size: 0.8rem !important;
}
@media (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitronAlertBig {
    font-size: 1rem !important;
    max-width: 25rem;
    left: 2rem;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  }
}
html body #tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  font-size: 1em !important;
  margin-bottom: 2em;
}
html body #tarteaucitronRoot #tarteaucitronAlertBig button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5em 0;
  border-radius: 1.667em;
  font-size: 1em !important;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1;
  padding: 0 1.667em;
  min-height: 3.3333333333em;
}
html body #tarteaucitronRoot #tarteaucitronAlertBig button#tarteaucitronPrivacyUrl {
  min-height: 0;
  margin-top: 1.5em;
  font-size: 0.8rem !important;
}

/*** IMPORT COMPONENTS ***/
.a11y--jump-link {
  background-color: #000;
  color: #fff;
  text-decoration: none;
  margin: 0;
  padding: 0.5em 1em;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1000000;
}
.a11y--jump-link:active, .a11y--jump-link:focus {
  display: block;
  width: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.logo-svg svg {
  display: block;
  width: 100%;
  height: 100%;
}
.logo-svg--claim {
  margin-top: 9%;
}

#toggleMobileNav {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

.hamburger, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle {
  color: #003770;
}
.hamburger .hamburger-inner::after, #toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::after {
  width: 2em;
}
#toggleMobileNav:checked ~ .page-wrap .hamburger.main-nav--toggle .hamburger-inner::after {
  width: 2.5em;
}
@media (min-width: 1200px) {
  .hamburger.main-nav--toggle, #toggleMobileNav:checked ~ .page-wrap .main-nav--toggle.hamburger {
    display: none;
  }
}

@media (min-width: 1200px) {
  .main-nav {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.main-nav ul {
  padding-left: 0;
}
.main-nav ul, .main-nav ul li {
  list-style: none;
  margin: 0;
}

ul.main-nav--list {
  font-size: 1rem;
  padding-top: 2rem;
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  ul.main-nav--list {
    font-size: 1vw;
  }
}
@media (min-width: 1200px) {
  ul.main-nav--list {
    padding-top: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  ul.main-nav--list > li {
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-basis: 20%;
  }
}
ul.main-nav--list .main-nav-item--selector {
  display: none;
}
ul.main-nav--list li {
  position: relative;
}
ul.main-nav--list li > input[type=radio] {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}
ul.main-nav--list li > input[type=radio]:checked {
  display: none;
}
@media (min-width: 1200px) {
  ul.main-nav--list li {
    position: static;
  }
  ul.main-nav--list li > input[type=radio] {
    display: none;
    pointer-events: none;
  }
}

@media (min-width: 1200px) {
  .main-nav--item--inner {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.main-nav--item {
  border-bottom: 1px dashed rgba(0, 55, 112, 0.2);
  /*&.active > .main-nav-item--selector,
  &:hover > .main-nav-item--selector,
  &:focus-within > .main-nav-item--selector,

  &.active > .sub-nav-item--selector,
  &:hover > .sub-nav-item--selector,
  &:focus-within > .sub-nav-item--selector,

  > input[type=radio]:checked {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*[aria-expanded='true'] {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
}
.main-nav--item:first-child {
  border-top: 1px dashed rgba(0, 55, 112, 0.2);
}
@media (min-width: 1200px) {
  .main-nav--item {
    border: none;
  }
  .main-nav--item .main-nav--item--inner {
    padding: 0 1.3333333333em;
  }
  .main-nav--item .main-nav--item--inner::before {
    content: "";
    display: block;
    position: absolute;
    top: 1.3333333333rem;
    bottom: 1rem;
    left: 0;
    width: 1px;
    border-left: 1px dashed rgba(0, 55, 112, 0.2);
  }
  .main-nav--item:first-child {
    border: none;
  }
  .main-nav--item:first-child .main-nav--item--inner::before {
    display: none;
    content: none;
  }
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger) {
  font-size: 1.1333333333em;
  color: #003770;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.7647058824em 1.1764705882em;
  transition: color 0.3s;
  transition-timing-function: ease-in-out;
  will-change: color;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon label arrow";
  align-items: center;
}
@media (min-width: 1200px) {
  .main-nav--item > .main-nav--item--inner a:not(.hamburger) {
    grid-template-columns: auto 1fr;
    grid-template-areas: "icon label";
    padding-left: 0;
    padding-right: 0;
  }
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger) i, .main-nav--item > .main-nav--item--inner a:not(.hamburger)::after {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 2.3529411765em;
  height: 2.3529411765em;
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger) i {
  grid-area: icon;
  position: relative;
  background-color: currentColor;
  margin-right: 0.8823529412em;
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger) i::before {
  color: #fff;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  transform: translate(-50%, -50%);
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger) i.mask-icon-before--bfi-info {
  background-color: transparent;
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger) i.mask-icon-before--bfi-info::before {
  color: currentColor;
  width: 100%;
  height: 100%;
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger)::after {
  opacity: 0;
  grid-area: arrow;
  content: "";
  margin-left: 0.8823529412em;
  background-color: transparent;
  border: 1px solid rgba(0, 55, 112, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 0.8235294118em;
  background-image: svg-load("angle-right.svg", fill=#003770);
  transition: transform 0.3s;
  transition-timing-function: ease-in-out;
  will-change: transform;
}
@media (min-width: 1200px) {
  .main-nav--item > .main-nav--item--inner a:not(.hamburger)::after {
    display: none;
    content: none;
  }
}
.main-nav--item > .main-nav--item--inner a:not(.hamburger):hover, .main-nav--item > .main-nav--item--inner a:not(.hamburger):focus {
  color: #C61932;
}
.main-nav--item.has-dropdown > .main-nav--item--inner a::after {
  opacity: 1;
}
.main-nav--item.active > .main-nav-item--selector ~ .main-nav--item--inner a, .main-nav--item.active > .main-nav-item--selector ~ .sub-nav--item--inner a, .main-nav--item.active > .sub-nav-item--selector ~ .main-nav--item--inner a, .main-nav--item.active > .sub-nav-item--selector ~ .sub-nav--item--inner a {
  color: #C61932;
}
.main-nav--item.active > .main-nav-item--selector ~ .main-nav--item--inner a::after, .main-nav--item.active > .main-nav-item--selector ~ .sub-nav--item--inner a::after, .main-nav--item.active > .sub-nav-item--selector ~ .main-nav--item--inner a::after, .main-nav--item.active > .sub-nav-item--selector ~ .sub-nav--item--inner a::after {
  transform: rotate(90deg);
}
.main-nav--item.hamburger-label .main-nav--item--hamburger-label {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .main-nav--item.hamburger-label .main-nav--item--hamburger-label {
    font-size: 0.6666666667rem;
  }
}
@media (min-width: 1200px) {
  .main-nav--item.hamburger-label {
    flex-basis: 0;
    /*&.active > .main-nav-item--selector,
    &:hover > .main-nav-item--selector,
    &:focus-within > .main-nav-item--selector,

    &.active > .sub-nav-item--selector,
    &:hover > .sub-nav-item--selector,
    &:focus-within > .sub-nav-item--selector,

    > input[type=radio]:checked {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }*/
    /*[aria-expanded='true'] {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }*/
  }
  .main-nav--item.hamburger-label .main-nav--item--inner > a:not(.hamburger) {
    display: none;
  }
  .main-nav--item.hamburger-label .main-nav--item--hamburger-label {
    display: block;
    color: #003770;
    transition: color 0.3s;
    transition-timing-function: ease-in-out;
    will-change: color;
  }
  .main-nav--item.hamburger-label.active > .main-nav-item--selector ~ .main-nav--item--inner .main-nav--item--hamburger-label, .main-nav--item.hamburger-label.active > .main-nav-item--selector ~ .sub-nav--item--inner .main-nav--item--hamburger-label, .main-nav--item.hamburger-label.active > .sub-nav-item--selector ~ .main-nav--item--inner .main-nav--item--hamburger-label, .main-nav--item.hamburger-label.active > .sub-nav-item--selector ~ .sub-nav--item--inner .main-nav--item--hamburger-label {
    color: #C61932;
  }
}

:root {
  --collapsibleMenuItemHeight: 4000px;
}

.main-nav--item.has-dropdown {
  /*&.active > .main-nav-item--selector,
  &:hover > .main-nav-item--selector,
  &:focus-within > .main-nav-item--selector,

  &.active > .sub-nav-item--selector,
  &:hover > .sub-nav-item--selector,
  &:focus-within > .sub-nav-item--selector,

  > input[type=radio]:checked {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*[aria-expanded='true'] {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*&.active > .main-nav-item--selector,
  &:hover > .main-nav-item--selector,
  &:focus-within > .main-nav-item--selector,

  &.active > .sub-nav-item--selector,
  &:hover > .sub-nav-item--selector,
  &:focus-within > .sub-nav-item--selector,

  > input[type=radio]:checked {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*[aria-expanded='true'] {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
}
@media (min-width: 1200px) {
  .main-nav--item.has-dropdown.active > .main-nav-item--selector ~ .main-nav--item--inner::after, .main-nav--item.has-dropdown.active > .main-nav-item--selector ~ .sub-nav--item--inner::after, .main-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .main-nav--item--inner::after, .main-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .sub-nav--item--inner::after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1.3333333333em 1.4333333333em 0 1.4333333333em;
    border-color: #fff transparent transparent transparent;
    z-index: 1;
  }
}
.main-nav--item.has-dropdown .main-nav--dropdown {
  display: none;
}
@media (min-width: 1200px) {
  .main-nav--item.has-dropdown .main-nav--dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #F0F5FB;
  }
  .main-nav--item.has-dropdown .main-nav--dropdown--inner {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    max-height: calc(95vh - 100px);
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
  }
  .main-nav--item.has-dropdown .main-nav--dropdown--inner > .sub-nav {
    padding: 2rem;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.main-nav--item.has-dropdown.active > .main-nav-item--selector ~ .main-nav--item--inner ~ .main-nav--dropdown, .main-nav--item.has-dropdown.active > .main-nav-item--selector ~ .sub-nav--item--inner ~ .main-nav--dropdown, .main-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .main-nav--item--inner ~ .main-nav--dropdown, .main-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .sub-nav--item--inner ~ .main-nav--dropdown {
  display: block;
}

.sub-nav--item.has-dropdown {
  /*&.active > .main-nav-item--selector,
  &:hover > .main-nav-item--selector,
  &:focus-within > .main-nav-item--selector,

  &.active > .sub-nav-item--selector,
  &:hover > .sub-nav-item--selector,
  &:focus-within > .sub-nav-item--selector,

  > input[type=radio]:checked {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*[aria-expanded='true'] {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
}
.sub-nav--item.has-dropdown .sub-nav {
  display: none;
}
.sub-nav--item.has-dropdown.active > .main-nav-item--selector ~ .main-nav--item--inner > .sub-nav, .sub-nav--item.has-dropdown.active > .main-nav-item--selector ~ .sub-nav--item--inner > .sub-nav, .sub-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .main-nav--item--inner > .sub-nav, .sub-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .sub-nav--item--inner > .sub-nav {
  display: block;
}

.sub-nav--item {
  /*&.active > .main-nav-item--selector,
  &:hover > .main-nav-item--selector,
  &:focus-within > .main-nav-item--selector,

  &.active > .sub-nav-item--selector,
  &:hover > .sub-nav-item--selector,
  &:focus-within > .sub-nav-item--selector,

  > input[type=radio]:checked {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*[aria-expanded='true'] {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
}
.sub-nav--item--inner--menuitem {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label arrow";
  align-items: center;
  padding: 1em 1.3333333333em 1em 2em;
}
.sub-nav--item--inner--menuitem > a {
  grid-area: label;
}
.sub-nav--item--inner--menuitem > button {
  display: block;
  overflow: hidden;
  height: 0.9333333333em;
  width: 2.3529411765em;
  opacity: 0;
  grid-area: arrow;
  margin-left: 1em;
  background-color: transparent;
  border: 1px solid transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 0.9333333333em;
  background-image: svg-load("angle-right.svg", fill=#003770);
  transition: transform 0.3s, opacity 0.3s, border-color 0.3s;
  transition-timing-function: ease-in-out;
  will-change: transform, opacity, border-color;
}
.sub-nav--item--inner--menuitem > a {
  color: #003770;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s, opacity 0.3s;
  transition-timing-function: ease-in-out;
  will-change: color, background-color, border-color, opacity;
}
.sub-nav--item--inner--menuitem > a:hover {
  background-color: #fff;
}
.sub-nav--item.has-dropdown > .sub-nav--item--inner > .sub-nav--item--inner--menuitem > button {
  opacity: 1;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .sub-nav--item.has-dropdown > .sub-nav--item--inner > .sub-nav--item--inner--menuitem > button {
    width: 2.3529411765em;
    height: 2.3529411765em;
    background-color: transparent;
    border: 1px solid rgba(0, 55, 112, 0.1);
    border-radius: 50%;
    background-size: auto 0.8235294118em;
    opacity: 1;
  }
}
.sub-nav--item.has-dropdown {
  /*&.active > .main-nav-item--selector,
  &:hover > .main-nav-item--selector,
  &:focus-within > .main-nav-item--selector,

  &.active > .sub-nav-item--selector,
  &:hover > .sub-nav-item--selector,
  &:focus-within > .sub-nav-item--selector,

  > input[type=radio]:checked {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
  /*[aria-expanded='true'] {
      & ~ .main-nav--item--inner,
      & ~ .sub-nav--item--inner {
          @content
      }
  }*/
}
.sub-nav--item.has-dropdown.active > .main-nav-item--selector ~ .main-nav--item--inner > .sub-nav--item--inner--menuitem > button, .sub-nav--item.has-dropdown.active > .main-nav-item--selector ~ .sub-nav--item--inner > .sub-nav--item--inner--menuitem > button, .sub-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .main-nav--item--inner > .sub-nav--item--inner--menuitem > button, .sub-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .sub-nav--item--inner > .sub-nav--item--inner--menuitem > button {
  transform: rotate(90deg);
  opacity: 1;
}
@media (min-width: 1200px) {
  .sub-nav--item.has-dropdown.active > .main-nav-item--selector ~ .main-nav--item--inner > .sub-nav--item--inner--menuitem > button, .sub-nav--item.has-dropdown.active > .main-nav-item--selector ~ .sub-nav--item--inner > .sub-nav--item--inner--menuitem > button, .sub-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .main-nav--item--inner > .sub-nav--item--inner--menuitem > button, .sub-nav--item.has-dropdown.active > .sub-nav-item--selector ~ .sub-nav--item--inner > .sub-nav--item--inner--menuitem > button {
    transform: rotate(0deg);
    opacity: 1;
    background-color: rgba(0, 55, 112, 0.1);
  }
}
.sub-nav--item.active > .main-nav-item--selector ~ .main-nav--item--inner, .sub-nav--item.active > .main-nav-item--selector ~ .sub-nav--item--inner, .sub-nav--item.active > .sub-nav-item--selector ~ .main-nav--item--inner, .sub-nav--item.active > .sub-nav-item--selector ~ .sub-nav--item--inner {
  background-color: #fff;
}
.sub-nav--item:hover > .sub-nav-item--selector ~ .sub-nav--item--inner {
  background-color: #fff;
}
.sub-nav--item .sub-nav--list .sub-nav--item:last-child {
  padding-bottom: 1em;
}

.main-nav--dropdown--inner > .sub-nav > .sub-nav--list > .sub-nav--item:last-child {
  margin-bottom: 2em;
}
.main-nav--dropdown--inner > .sub-nav > .sub-nav--list > .sub-nav--item > .sub-nav--item--inner > a {
  padding-top: 1.3333333333em;
  padding-bottom: 1.3333333333em;
  font-weight: 700;
  background-color: transparent;
}
@media (min-width: 1200px) {
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list > .sub-nav--item > .sub-nav--item--inner > a {
    padding-top: 0.6666666667em;
    padding-bottom: 0.6666666667em;
  }
}
.main-nav--dropdown--inner > .sub-nav > .sub-nav--list > .sub-nav--item > .sub-nav--item--inner > a::after {
  border-radius: 50%;
  border-color: rgba(0, 55, 112, 0.1);
  width: 2.6666666667em;
  height: 2.6666666667em;
  margin-right: 0;
  margin-top: -0.6666666667em;
  margin-bottom: -0.6666666667em;
}
@media (min-width: 1200px) {
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list {
    width: 50%;
    position: relative;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 1px dashed rgba(0, 55, 112, 0.2);
    pointer-events: none;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list > .sub-nav--item {
    padding-right: 2rem;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    min-height: 100%;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav {
    display: none !important;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav > .sub-nav--list {
    position: sticky;
    top: 2rem;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item {
    padding-left: 2rem;
    /*&.active > .main-nav-item--selector,
    &:hover > .main-nav-item--selector,
    &:focus-within > .main-nav-item--selector,

    &.active > .sub-nav-item--selector,
    &:hover > .sub-nav-item--selector,
    &:focus-within > .sub-nav-item--selector,

    > input[type=radio]:checked {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }*/
    /*[aria-expanded='true'] {
        & ~ .main-nav--item--inner,
        & ~ .sub-nav--item--inner {
            @content
        }
    }*/
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item > .sub-nav--item--inner > .sub-nav--item--inner--menuitem {
    padding: 0;
    display: block;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item > .sub-nav--item--inner > .sub-nav--item--inner--menuitem a {
    display: block;
    border: 1px solid transparent;
    font-weight: 400;
    padding: 0.5333333333em 1em;
    margin-bottom: 0.1333333333em;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item > .sub-nav--item--inner > .sub-nav--item--inner--menuitem a::after {
    content: none;
    display: none;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item > .sub-nav--item--inner > .sub-nav--item--inner--menuitem button {
    display: none;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item > .sub-nav--item--inner:hover {
    background-color: transparent;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item > .sub-nav--item--inner:hover > a {
    background-color: transparent;
    border-color: rgba(0, 55, 112, 0.1);
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .main-nav-item--selector ~ .main-nav--item--inner, .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .main-nav-item--selector ~ .sub-nav--item--inner, .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .sub-nav-item--selector ~ .main-nav--item--inner, .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .sub-nav-item--selector ~ .sub-nav--item--inner {
    background-color: transparent;
  }
  .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .main-nav-item--selector ~ .main-nav--item--inner > a, .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .main-nav-item--selector ~ .sub-nav--item--inner > a, .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .sub-nav-item--selector ~ .main-nav--item--inner > a, .main-nav--dropdown--inner > .sub-nav > .sub-nav--list .sub-nav .sub-nav--item.active > .sub-nav-item--selector ~ .sub-nav--item--inner > a {
    border-color: rgba(0, 55, 112, 0.1);
  }
}

.header-action-button {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 0.6666666667em;
  width: 5.5em;
  height: 5.5em;
  overflow: hidden;
  text-indent: -9999em;
  background-color: currentColor;
  border: 1px solid currentColor;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
  transition-timing-function: ease-in-out;
  will-change: transform, box-shadow;
}
@media (min-width: 768px) {
  .header-action-button {
    width: 6em;
    height: 6em;
  }
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .header-action-button {
    font-size: 0.5333333333rem;
  }
}
@media (min-width: 1200px) {
  .header-action-button {
    align-self: end;
    transform: translateY(50%);
  }
  .header-action-button:hover, .header-action-button:focus {
    transform: translateY(45%);
    box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.15);
  }
}
.header-action-button::before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.4em;
  height: 2.4em;
  transform: translate(-50%, -50%);
  color: #fff;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: currentColor;
}
.header-action-button:hover, .header-action-button:focus {
  color: currentColor;
}
.header-action-button--btn-search {
  border-radius: 50%;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  color: #6ABBF3;
}
.header-action-button--btn-search::before {
  mask-image: svg-load("search.svg");
}
.header-action-button--btn-search:hover, .header-action-button--btn-search:focus {
  color: #6ABBF3;
}
.header-action-button--btn-cart {
  color: #fff;
}
.header-action-button--btn-cart:hover, .header-action-button--btn-cart:focus {
  color: #fff;
}
.header-action-button--btn-cart[data-items]:not([data-items=""])::after {
  content: attr(data-items);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #C61932;
  font-size: 1em;
  text-indent: 0;
  text-align: center;
  text-decoration: none;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  transform: translate(-45%, -7%);
  pointer-events: none;
}
.header-action-button--btn-cart::before {
  mask-image: svg-load("cart.svg");
  background-color: #003770;
}
.header-action-button--btn-cart:hover, .header-action-button--btn-cart:focus {
  color: #fff;
}
@media (min-width: 1200px) {
  .header-action-button--btn-cart {
    border-radius: 50%;
    box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  }
}

.header-action-button--btn-cart {
  overflow: visible;
}
.header-action-button--btn-cart.open .header-action-cart-dialog {
  display: block;
}
.header-action-button--btn-cart--toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  text-indent: -99999em;
  background-color: transparent;
}

.header-action-cart-dialog {
  display: none;
  position: absolute;
  top: calc(100% + 15px);
  right: 1rem;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  color: #000;
  text-indent: 0;
  cursor: default;
  padding: 2em 0;
  font-size: 1rem;
  width: 24.3333333333em;
  max-width: 80vw;
}
.header-action-cart-dialog {
  font-size: 0.8rem;
}
@media screen and (min-width: 768px) {
  .header-action-cart-dialog {
    font-size: calc(12px + 3 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .header-action-cart-dialog {
    font-size: 1rem;
  }
}
.header-action-cart-dialog * {
  text-indent: 0;
}
.header-action-cart-dialog::before {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  width: 1.3333333333em;
  height: 1.3333333333em;
  background-color: #fff;
  transform: rotate(45deg) translateY(-25%);
}
@media (min-width: 992px) {
  .header-action-cart-dialog {
    right: 0;
  }
}
.header-action-cart-dialog--content {
  border-radius: 20px;
  max-height: 50vh;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  margin: 0 1em;
}

.header-action-cart-dialog--item {
  padding: 2em 1em;
  border-bottom: 1px solid #eee;
  display: block;
  text-decoration: none;
}
.header-action-cart-dialog--item:first-child {
  padding-top: 0;
}
.header-action-cart-dialog--item:last-child {
  margin-bottom: 0;
}
.header-action-cart-dialog--item:hover, .header-action-cart-dialog--item:focus {
  color: #000;
  text-decoration: none;
}
.header-action-cart-dialog--item--headline {
  font-size: 1.2em;
  font-weight: 700;
  color: #000;
  margin-bottom: 0.6666666667em;
}
.header-action-cart-dialog--item--headline small, .header-action-cart-dialog--item--headline .small, .header-action-cart-dialog--item--headline .cart-big--item--title--info, .header-action-cart-dialog--item--headline .form-infotext-scroll--content, .header-action-cart-dialog--item--headline .form-container .invalid-feedback, .form-container .header-action-cart-dialog--item--headline .invalid-feedback,
.header-action-cart-dialog--item--headline .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .header-action-cart-dialog--item--headline .invalid-feedback,
.header-action-cart-dialog--item--headline .card-form-container .invalid-feedback,
.card-form-container .header-action-cart-dialog--item--headline .invalid-feedback {
  display: block;
  font-weight: normal;
  font-size: 0.8rem;
}
.header-action-cart-dialog--item--date {
  font-weight: 900;
  margin-bottom: 0.6666666667em;
}
.header-action-cart-dialog--item--price {
  font-weight: 700;
}

.header-action-cart-dialog--buttons {
  padding: 2em;
  padding-bottom: 0;
  text-align: right;
}
.header-action-cart-dialog--buttons .btn, .header-action-cart-dialog--buttons .footer-newsletter-small--submit, .header-action-cart-dialog--buttons .cta-search--submit, .header-action-cart-dialog--buttons .search-overlay--submit {
  margin: 0;
}

.container, .footer-bottom--inner, .footer-info--inner, .footer-main--inner, .courses-hero--inner, .page-header--tools--inner, .page-hero--small, .page-header--container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1260px;
}
@media (min-width: 768px) {
  .container, .footer-bottom--inner, .footer-info--inner, .footer-main--inner, .courses-hero--inner, .page-header--tools--inner, .page-hero--small, .page-header--container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.container--narrow {
  max-width: 960px;
}
.container--full-width {
  max-width: none;
  width: 100%;
}

.area--cols > .cols > .col > .area:first-child, .form-container .area--cols > .cols > [class*=col-] > .area:first-child,
.form-builder-wrapper .area--cols > .cols > [class*=col-] > .area:first-child, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > .col > .area:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > [class*=col-] > .area:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > .col > .area:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > [class*=col-] > .area:first-child {
  margin-top: 0;
}
.area--cols > .cols > .col > .area:last-child, .form-container .area--cols > .cols > [class*=col-] > .area:last-child,
.form-builder-wrapper .area--cols > .cols > [class*=col-] > .area:last-child, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > .col > .area:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > [class*=col-] > .area:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > .col > .area:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--cols > .row > [class*=col-] > .area:last-child {
  margin-bottom: 0;
}

.cols, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row {
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
@media (min-width: 992px) {
  .cols, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
}
.cols.small-gutter, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .small-gutter.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .small-gutter.row {
  grid-row-gap: 0.5rem;
}
@media (min-width: 992px) {
  .cols.small-gutter, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .small-gutter.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .small-gutter.row {
    grid-row-gap: 1rem;
  }
}
.cols.no-gutter, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .no-gutter.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .no-gutter.row {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.cols.overflow-scroll, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row {
  grid: 1fr/auto-flow min(80vw, 20rem);
  overflow-x: auto;
  scroll-snap-type: x proximity;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.cols.overflow-scroll::-webkit-scrollbar, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row::-webkit-scrollbar,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.cols.overflow-scroll > *, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row > *,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row > * {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
@media (min-width: 768px) {
  .cols.overflow-scroll, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row {
    grid-template-rows: initial;
    grid-auto-flow: column;
    grid-auto-columns: initial;
  }
  .cols.overflow-scroll > *, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row > *,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .overflow-scroll.row > * {
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }
}
@media (min-width: 576px) {
  .cols--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols--2.ratio--25-75, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--25-75.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--25-75.row {
    grid-template-columns: 25% 1fr;
  }
  .cols--2.ratio--40-60, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--40-60.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--40-60.row {
    grid-template-columns: 40% 1fr;
  }
  .cols--2.ratio--60-40, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--60-40.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--60-40.row {
    grid-template-columns: 60% 1fr;
  }
  .cols--2.ratio--75-25, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--75-25.row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .ratio--75-25.row {
    grid-template-columns: 75% 1fr;
  }
}
@media (min-width: 768px) {
  .cols--md--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols--md--2.ratio--25-75 {
    grid-template-columns: 25% 1fr;
  }
  .cols--md--2.ratio--40-60 {
    grid-template-columns: 40% 1fr;
  }
  .cols--md--2.ratio--60-40 {
    grid-template-columns: 60% 1fr;
  }
  .cols--md--2.ratio--75-25 {
    grid-template-columns: 75% 1fr;
  }
}
@media (min-width: 992px) {
  .cols--lg--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols--lg--2.ratio--25-75 {
    grid-template-columns: 25% 1fr;
  }
  .cols--lg--2.ratio--40-60 {
    grid-template-columns: 40% 1fr;
  }
  .cols--lg--2.ratio--60-40 {
    grid-template-columns: 60% 1fr;
  }
  .cols--lg--2.ratio--75-25 {
    grid-template-columns: 75% 1fr;
  }
}
.cols--xs--2 {
  grid-template-columns: repeat(2, 1fr);
}
.cols--xs--2.ratio--25-75 {
  grid-template-columns: 25% 1fr;
}
.cols--xs--2.ratio--40-60 {
  grid-template-columns: 40% 1fr;
}
.cols--xs--2.ratio--60-40 {
  grid-template-columns: 60% 1fr;
}
.cols--xs--2.ratio--75-25 {
  grid-template-columns: 75% 1fr;
}
@media (min-width: 768px) {
  .cols--3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols--3.ratio--50-25-25 {
    grid-template-columns: 50% 25% 25%;
  }
  .cols--3.ratio--25-50-25 {
    grid-template-columns: 25% 50% 25%;
  }
  .cols--3.ratio--25-25-50 {
    grid-template-columns: 25% 25% 50%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 576px) {
  .cols--4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .cols--4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .cols--4.ratio--40-20-20-20 {
    grid-template-columns: 40% 20% 20% 20%;
  }
  .cols--4.ratio--20-40-20-20 {
    grid-template-columns: 20% 40% 20% 20%;
  }
  .cols--4.ratio--20-20-40-20 {
    grid-template-columns: 20% 20% 40% 20%;
  }
  .cols--4.ratio--20-20-20-40 {
    grid-template-columns: 20% 20% 20% 40%;
  }
}
@media (min-width: 1200px) {
  .cols--5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .cols--5 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cols--5 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .cols--6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .cols--6 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cols--6 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--6 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.cols .col, .form-container .cols > [class*=col-],
.form-builder-wrapper .cols > [class*=col-], .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .cols > [class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > [class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .form-builder-wrapper .cols > [class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .form-container .cols > [class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .cols > [class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > [class*=col-] {
  min-width: 0;
}
.cols .col--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--2,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--2 {
  grid-column: auto/span 2;
}
@media (min-width: 576px) {
  .cols .col--sm--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--2,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--2 {
    grid-column: auto/span 2;
  }
}
@media (min-width: 768px) {
  .cols .col--md--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--2,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--2 {
    grid-column: auto/span 2;
  }
}
@media (min-width: 992px) {
  .cols .col--lg--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--2,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--2 {
    grid-column: auto/span 2;
  }
}
@media (min-width: 1200px) {
  .cols .col--xl--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--2,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--2 {
    grid-column: auto/span 2;
  }
}
.cols .col--3, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--3,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--3 {
  grid-column: auto/span 3;
}
@media (min-width: 576px) {
  .cols .col--sm--3, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--3,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--3 {
    grid-column: auto/span 3;
  }
}
@media (min-width: 768px) {
  .cols .col--md--3, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--3,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--3 {
    grid-column: auto/span 3;
  }
}
@media (min-width: 992px) {
  .cols .col--lg--3, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--3,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--3 {
    grid-column: auto/span 3;
  }
}
@media (min-width: 1200px) {
  .cols .col--xl--3, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--3,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--3 {
    grid-column: auto/span 3;
  }
}
.cols .col--4, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--4,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--4 {
  grid-column: auto/span 4;
}
@media (min-width: 576px) {
  .cols .col--sm--4, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--4,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--4 {
    grid-column: auto/span 4;
  }
}
@media (min-width: 768px) {
  .cols .col--md--4, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--4,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--4 {
    grid-column: auto/span 4;
  }
}
@media (min-width: 992px) {
  .cols .col--lg--4, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--4,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--4 {
    grid-column: auto/span 4;
  }
}
@media (min-width: 1200px) {
  .cols .col--xl--4, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--4,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--4 {
    grid-column: auto/span 4;
  }
}
.cols .col--5, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--5,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--5 {
  grid-column: auto/span 5;
}
@media (min-width: 576px) {
  .cols .col--sm--5, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--5,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--5 {
    grid-column: auto/span 5;
  }
}
@media (min-width: 768px) {
  .cols .col--md--5, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--5,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--5 {
    grid-column: auto/span 5;
  }
}
@media (min-width: 992px) {
  .cols .col--lg--5, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--5,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--5 {
    grid-column: auto/span 5;
  }
}
@media (min-width: 1200px) {
  .cols .col--xl--5, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--5,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--5 {
    grid-column: auto/span 5;
  }
}
.cols .col--6, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--6,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--6 {
  grid-column: auto/span 6;
}
@media (min-width: 576px) {
  .cols .col--sm--6, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--6,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--sm--6 {
    grid-column: auto/span 6;
  }
}
@media (min-width: 768px) {
  .cols .col--md--6, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--6,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--md--6 {
    grid-column: auto/span 6;
  }
}
@media (min-width: 992px) {
  .cols .col--lg--6, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--6,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--lg--6 {
    grid-column: auto/span 6;
  }
}
@media (min-width: 1200px) {
  .cols .col--xl--6, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--6,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .col--xl--6 {
    grid-column: auto/span 6;
  }
}

.main-content p a[href*="//"]:not([href*="bfi-sbg.at"]):not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit)::after,
.main-content p a[target=_blank]:not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit)::after, .main-content li a[href*="//"]:not([href*="bfi-sbg.at"]):not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit)::after,
.main-content li a[target=_blank]:not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit)::after, .main-content table a[href*="//"]:not([href*="bfi-sbg.at"]):not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit)::after,
.main-content table a[target=_blank]:not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit)::after {
  content: "";
  display: inline-block;
  width: 0.6666666667rem;
  height: 0.6666666667rem;
  margin-left: 0.3333333333rem;
  margin-right: 0.1333333333rem;
  background-image: svg-load("external-link-alt.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.link-arrow,
a.link-arrow {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}
.link-arrow::before,
a.link-arrow::before {
  content: "";
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  width: 0.4rem;
  height: 0.6666666667rem;
  margin-right: 0.3333333333rem;
  background-image: svg-load("angle-right.svg", fill="");
  background-size: contain;
  background-repeat: no-repeat;
}
.link-arrow:hover, .link-arrow:focus,
a.link-arrow:hover,
a.link-arrow:focus {
  color: inherit;
}

ul > li {
  list-style: square;
}
ul > li::marker {
  color: #C61932;
}
ul ul li {
  list-style: disc;
}
ul ul li::marker {
  color: currentColor;
}

ol {
  counter-reset: ordered-list;
}
ol li {
  list-style: none;
  color: #000;
  background-color: #F0F5FB;
  border-radius: 1.3333333333em;
  font-weight: 400;
  min-height: 2em;
  margin-bottom: 1em;
  padding: 0.6666666667em 0.6666666667em 0.6666666667em 3.3333333333em;
  position: relative;
}
ol li:last-child {
  margin-bottom: 0;
}
ol li::before {
  content: counter(ordered-list);
  counter-increment: ordered-list;
  display: block;
  position: absolute;
  top: 0.3333333333em;
  left: 0.3333333333em;
  background-color: #C61932;
  color: #fff;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-weight: 900;
  text-align: center;
  border-radius: 50%;
}

.icon,
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1em;
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.icon--color--white {
  filter: saturate(0) brightness(0) invert(100%) opacity(1);
}

.mask-icon, .area--mobile-icon-nav i::before, .btn > i[class*=mask-icon], .footer-newsletter-small--submit > i[class*=mask-icon], .cta-search--submit > i[class*=mask-icon], .search-overlay--submit > i[class*=mask-icon], .main-nav--item > .main-nav--item--inner a:not(.hamburger) i::before {
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: currentColor;
}

.icon--angle-left,
.icon-before--angle-left::before,
.icon-after--angle-left::after {
  background-image: svg-load("angle-left.svg");
}

.icon--angle-right,
.icon-before--angle-right::before,
.icon-after--angle-right::after {
  background-image: svg-load("angle-right.svg");
}

.icon--arrow-right,
.icon-before--arrow-right::before,
.icon-after--arrow-right::after {
  background-image: svg-load("arrow-right.svg");
}

.icon--calendar,
.icon-before--calendar::before,
.icon-after--calendar::after {
  background-image: svg-load("calendar.svg");
}

.icon--cart,
.icon-before--cart::before,
.icon-after--cart::after {
  background-image: svg-load("cart.svg");
}

.icon--check,
.icon-before--check::before,
.icon-after--check::after {
  background-image: svg-load("check.svg");
}

.icon--chevron-filled-down,
.icon-before--chevron-filled-down::before,
.icon-after--chevron-filled-down::after {
  background-image: svg-load("chevron-filled-down.svg");
}

.icon--chevron-light-right,
.icon-before--chevron-light-right::before,
.icon-after--chevron-light-right::after {
  background-image: svg-load("chevron-light-right.svg");
}

.icon--clock,
.icon-before--clock::before,
.icon-after--clock::after {
  background-image: svg-load("clock.svg");
}

.icon--close,
.icon-before--close::before,
.icon-after--close::after {
  background-image: svg-load("close.svg");
}

.icon--date,
.icon-before--date::before,
.icon-after--date::after {
  background-image: svg-load("date.svg");
}

.icon--delete,
.icon-before--delete::before,
.icon-after--delete::after {
  background-image: svg-load("delete.svg");
}

.icon--external-link-alt,
.icon-before--external-link-alt::before,
.icon-after--external-link-alt::after {
  background-image: svg-load("external-link-alt.svg");
}

.icon--facebook-f,
.icon-before--facebook-f::before,
.icon-after--facebook-f::after {
  background-image: svg-load("facebook-f.svg");
}

.icon--filter,
.icon-before--filter::before,
.icon-after--filter::after {
  background-image: svg-load("filter.svg");
}

.icon--instagram,
.icon-before--instagram::before,
.icon-after--instagram::after {
  background-image: svg-load("instagram.svg");
}

.icon--link-external,
.icon-before--link-external::before,
.icon-after--link-external::after {
  background-image: svg-load("link-external.svg");
}

.icon--linkedin,
.icon-before--linkedin::before,
.icon-after--linkedin::after {
  background-image: svg-load("linkedin.svg");
}

.icon--mail,
.icon-before--mail::before,
.icon-after--mail::after {
  background-image: svg-load("mail.svg");
}

.icon--map-marker,
.icon-before--map-marker::before,
.icon-after--map-marker::after {
  background-image: svg-load("map-marker.svg");
}

.icon--minus,
.icon-before--minus::before,
.icon-after--minus::after {
  background-image: svg-load("minus.svg");
}

.icon--pencil-alt,
.icon-before--pencil-alt::before,
.icon-after--pencil-alt::after {
  background-image: svg-load("pencil-alt.svg");
}

.icon--phone,
.icon-before--phone::before,
.icon-after--phone::after {
  background-image: svg-load("phone.svg");
}

.icon--play,
.icon-before--play::before,
.icon-after--play::after {
  background-image: svg-load("play.svg");
}

.icon--plus,
.icon-before--plus::before,
.icon-after--plus::after {
  background-image: svg-load("plus.svg");
}

.icon--search,
.icon-before--search::before,
.icon-after--search::after {
  background-image: svg-load("search.svg");
}

.icon--share,
.icon-before--share::before,
.icon-after--share::after {
  background-image: svg-load("share.svg");
}

.icon--twitter,
.icon-before--twitter::before,
.icon-after--twitter::after {
  background-image: svg-load("twitter.svg");
}

.icon--whatsapp,
.icon-before--whatsapp::before,
.icon-after--whatsapp::after {
  background-image: svg-load("whatsapp.svg");
}

.icon--xing,
.icon-before--xing::before,
.icon-after--xing::after {
  background-image: svg-load("xing.svg");
}

.icon--youtube,
.icon-before--youtube::before,
.icon-after--youtube::after {
  background-image: svg-load("youtube.svg");
}

.icon--pdf,
.icon-before--pdf::before,
.icon-after--pdf::after {
  background-image: svg-load("pdf.svg");
}

.icon--bfi-company-services,
.icon-before--bfi-company-services::before,
.icon-after--bfi-company-services::after {
  background-image: svg-load("bfi-company-services.svg");
}

.icon--bfi-courses,
.icon-before--bfi-courses::before,
.icon-after--bfi-courses::after {
  background-image: svg-load("bfi-courses.svg");
}

.icon--bfi-gear,
.icon-before--bfi-gear::before,
.icon-after--bfi-gear::after {
  background-image: svg-load("bfi-gear.svg");
}

.icon--bfi-graduation,
.icon-before--bfi-graduation::before,
.icon-after--bfi-graduation::after {
  background-image: svg-load("bfi-graduation.svg");
}

.icon--bfi-health,
.icon-before--bfi-health::before,
.icon-after--bfi-health::after {
  background-image: svg-load("bfi-health.svg");
}

.icon--bfi-info,
.icon-before--bfi-info::before,
.icon-after--bfi-info::after {
  background-image: svg-load("bfi-info.svg");
}

.icon--bfi-kufer-501,
.icon-before--bfi-kufer-501::before,
.icon-after--bfi-kufer-501::after {
  background-image: svg-load("bfi-kufer-501.svg");
}

.icon--bfi-kufer-502,
.icon-before--bfi-kufer-502::before,
.icon-after--bfi-kufer-502::after {
  background-image: svg-load("bfi-kufer-502.svg");
}

.icon--bfi-kufer-503,
.icon-before--bfi-kufer-503::before,
.icon-after--bfi-kufer-503::after {
  background-image: svg-load("bfi-kufer-503.svg");
}

.icon--bfi-kufer-504,
.icon-before--bfi-kufer-504::before,
.icon-after--bfi-kufer-504::after {
  background-image: svg-load("bfi-kufer-504.svg");
}

.icon--bfi-kufer-505,
.icon-before--bfi-kufer-505::before,
.icon-after--bfi-kufer-505::after {
  background-image: svg-load("bfi-kufer-505.svg");
}

.icon--bfi-kufer-506,
.icon-before--bfi-kufer-506::before,
.icon-after--bfi-kufer-506::after {
  background-image: svg-load("bfi-kufer-506.svg");
}

.icon--bfi-kufer-507,
.icon-before--bfi-kufer-507::before,
.icon-after--bfi-kufer-507::after {
  background-image: svg-load("bfi-kufer-507.svg");
}

.icon--bfi-kufer-508,
.icon-before--bfi-kufer-508::before,
.icon-after--bfi-kufer-508::after {
  background-image: svg-load("bfi-kufer-508.svg");
}

.icon--bfi-kufer-509,
.icon-before--bfi-kufer-509::before,
.icon-after--bfi-kufer-509::after {
  background-image: svg-load("bfi-kufer-509.svg");
}

.icon--bfi-kufer-511,
.icon-before--bfi-kufer-511::before,
.icon-after--bfi-kufer-511::after {
  background-image: svg-load("bfi-kufer-511.svg");
}

.icon--bfi-kufer-512,
.icon-before--bfi-kufer-512::before,
.icon-after--bfi-kufer-512::after {
  background-image: svg-load("bfi-kufer-512.svg");
}

.icon--bfi-kufer-513,
.icon-before--bfi-kufer-513::before,
.icon-after--bfi-kufer-513::after {
  background-image: svg-load("bfi-kufer-513.svg");
}

.icon--bfi-kufer-514,
.icon-before--bfi-kufer-514::before,
.icon-after--bfi-kufer-514::after {
  background-image: svg-load("bfi-kufer-514.svg");
}

.icon--bfi-kufer-515,
.icon-before--bfi-kufer-515::before,
.icon-after--bfi-kufer-515::after {
  background-image: svg-load("bfi-kufer-515.svg");
}

.icon--bfi-kufer-516,
.icon-before--bfi-kufer-516::before,
.icon-after--bfi-kufer-516::after {
  background-image: svg-load("bfi-kufer-516.svg");
}

.icon--bfi-kufer-517,
.icon-before--bfi-kufer-517::before,
.icon-after--bfi-kufer-517::after {
  background-image: svg-load("bfi-kufer-517.svg");
}

.icon--bfi-kufer-519,
.icon-before--bfi-kufer-519::before,
.icon-after--bfi-kufer-519::after {
  background-image: svg-load("bfi-kufer-519.svg");
}

.icon--bfi-kufer-520,
.icon-before--bfi-kufer-520::before,
.icon-after--bfi-kufer-520::after {
  background-image: svg-load("bfi-kufer-520.svg");
}

.icon--bfi-kufer-521,
.icon-before--bfi-kufer-521::before,
.icon-after--bfi-kufer-521::after {
  background-image: svg-load("bfi-kufer-521.svg");
}

.icon--bfi-kufer-524,
.icon-before--bfi-kufer-524::before,
.icon-after--bfi-kufer-524::after {
  background-image: svg-load("bfi-kufer-524.svg");
}

.icon--bfi-kufer-525,
.icon-before--bfi-kufer-525::before,
.icon-after--bfi-kufer-525::after {
  background-image: svg-load("bfi-kufer-525.svg");
}

.icon--bfi-kufer-526,
.icon-before--bfi-kufer-526::before,
.icon-after--bfi-kufer-526::after {
  background-image: svg-load("bfi-kufer-526.svg");
}

.icon--bfi-kufer-527,
.icon-before--bfi-kufer-527::before,
.icon-after--bfi-kufer-527::after {
  background-image: svg-load("bfi-kufer-527.svg");
}

.icon--bfi-kufer-info,
.icon-before--bfi-kufer-info::before,
.icon-after--bfi-kufer-info::after {
  background-image: svg-load("bfi-kufer-info.svg");
}

.mask-icon--angle-left,
.mask-icon-before--angle-left::before,
.mask-icon-after--angle-left::after {
  mask-image: svg-load("angle-left.svg");
}

.mask-icon--angle-right,
.mask-icon-before--angle-right::before,
.mask-icon-after--angle-right::after {
  mask-image: svg-load("angle-right.svg");
}

.mask-icon--arrow-right,
.mask-icon-before--arrow-right::before,
.mask-icon-after--arrow-right::after {
  mask-image: svg-load("arrow-right.svg");
}

.mask-icon--calendar,
.mask-icon-before--calendar::before,
.mask-icon-after--calendar::after {
  mask-image: svg-load("calendar.svg");
}

.mask-icon--cart,
.mask-icon-before--cart::before,
.mask-icon-after--cart::after {
  mask-image: svg-load("cart.svg");
}

.mask-icon--check,
.mask-icon-before--check::before,
.mask-icon-after--check::after {
  mask-image: svg-load("check.svg");
}

.mask-icon--chevron-filled-down,
.mask-icon-before--chevron-filled-down::before,
.mask-icon-after--chevron-filled-down::after {
  mask-image: svg-load("chevron-filled-down.svg");
}

.mask-icon--chevron-light-right,
.mask-icon-before--chevron-light-right::before,
.mask-icon-after--chevron-light-right::after {
  mask-image: svg-load("chevron-light-right.svg");
}

.mask-icon--clock,
.mask-icon-before--clock::before,
.mask-icon-after--clock::after {
  mask-image: svg-load("clock.svg");
}

.mask-icon--close,
.mask-icon-before--close::before,
.mask-icon-after--close::after {
  mask-image: svg-load("close.svg");
}

.mask-icon--date,
.mask-icon-before--date::before,
.mask-icon-after--date::after {
  mask-image: svg-load("date.svg");
}

.mask-icon--delete,
.mask-icon-before--delete::before,
.mask-icon-after--delete::after {
  mask-image: svg-load("delete.svg");
}

.mask-icon--external-link-alt,
.mask-icon-before--external-link-alt::before,
.mask-icon-after--external-link-alt::after {
  mask-image: svg-load("external-link-alt.svg");
}

.mask-icon--facebook-f,
.mask-icon-before--facebook-f::before,
.mask-icon-after--facebook-f::after {
  mask-image: svg-load("facebook-f.svg");
}

.mask-icon--filter,
.mask-icon-before--filter::before,
.mask-icon-after--filter::after {
  mask-image: svg-load("filter.svg");
}

.mask-icon--instagram,
.mask-icon-before--instagram::before,
.mask-icon-after--instagram::after {
  mask-image: svg-load("instagram.svg");
}

.mask-icon--link-external,
.mask-icon-before--link-external::before,
.mask-icon-after--link-external::after {
  mask-image: svg-load("link-external.svg");
}

.mask-icon--linkedin,
.mask-icon-before--linkedin::before,
.mask-icon-after--linkedin::after {
  mask-image: svg-load("linkedin.svg");
}

.mask-icon--mail,
.mask-icon-before--mail::before,
.mask-icon-after--mail::after {
  mask-image: svg-load("mail.svg");
}

.mask-icon--map-marker,
.mask-icon-before--map-marker::before,
.mask-icon-after--map-marker::after {
  mask-image: svg-load("map-marker.svg");
}

.mask-icon--minus,
.mask-icon-before--minus::before,
.mask-icon-after--minus::after {
  mask-image: svg-load("minus.svg");
}

.mask-icon--pencil-alt,
.mask-icon-before--pencil-alt::before,
.mask-icon-after--pencil-alt::after {
  mask-image: svg-load("pencil-alt.svg");
}

.mask-icon--phone,
.mask-icon-before--phone::before,
.mask-icon-after--phone::after {
  mask-image: svg-load("phone.svg");
}

.mask-icon--play,
.mask-icon-before--play::before,
.mask-icon-after--play::after {
  mask-image: svg-load("play.svg");
}

.mask-icon--plus,
.mask-icon-before--plus::before,
.mask-icon-after--plus::after {
  mask-image: svg-load("plus.svg");
}

.mask-icon--search,
.mask-icon-before--search::before,
.mask-icon-after--search::after {
  mask-image: svg-load("search.svg");
}

.mask-icon--share,
.mask-icon-before--share::before,
.mask-icon-after--share::after {
  mask-image: svg-load("share.svg");
}

.mask-icon--twitter,
.mask-icon-before--twitter::before,
.mask-icon-after--twitter::after {
  mask-image: svg-load("twitter.svg");
}

.mask-icon--whatsapp,
.mask-icon-before--whatsapp::before,
.mask-icon-after--whatsapp::after {
  mask-image: svg-load("whatsapp.svg");
}

.mask-icon--xing,
.mask-icon-before--xing::before,
.mask-icon-after--xing::after {
  mask-image: svg-load("xing.svg");
}

.mask-icon--youtube,
.mask-icon-before--youtube::before,
.mask-icon-after--youtube::after {
  mask-image: svg-load("youtube.svg");
}

.mask-icon--pdf,
.mask-icon-before--pdf::before,
.mask-icon-after--pdf::after {
  mask-image: svg-load("pdf.svg");
}

.mask-icon--bfi-company-services,
.mask-icon-before--bfi-company-services::before,
.mask-icon-after--bfi-company-services::after {
  mask-image: svg-load("bfi-company-services.svg");
}

.mask-icon--bfi-courses,
.mask-icon-before--bfi-courses::before,
.mask-icon-after--bfi-courses::after {
  mask-image: svg-load("bfi-courses.svg");
}

.mask-icon--bfi-gear,
.mask-icon-before--bfi-gear::before,
.mask-icon-after--bfi-gear::after {
  mask-image: svg-load("bfi-gear.svg");
}

.mask-icon--bfi-graduation,
.mask-icon-before--bfi-graduation::before,
.mask-icon-after--bfi-graduation::after {
  mask-image: svg-load("bfi-graduation.svg");
}

.mask-icon--bfi-health,
.mask-icon-before--bfi-health::before,
.mask-icon-after--bfi-health::after {
  mask-image: svg-load("bfi-health.svg");
}

.mask-icon--bfi-info,
.mask-icon-before--bfi-info::before,
.mask-icon-after--bfi-info::after {
  mask-image: svg-load("bfi-info.svg");
}

.mask-icon--bfi-kufer-501,
.mask-icon-before--bfi-kufer-501::before,
.mask-icon-after--bfi-kufer-501::after {
  mask-image: svg-load("bfi-kufer-501.svg");
}

.mask-icon--bfi-kufer-502,
.mask-icon-before--bfi-kufer-502::before,
.mask-icon-after--bfi-kufer-502::after {
  mask-image: svg-load("bfi-kufer-502.svg");
}

.mask-icon--bfi-kufer-503,
.mask-icon-before--bfi-kufer-503::before,
.mask-icon-after--bfi-kufer-503::after {
  mask-image: svg-load("bfi-kufer-503.svg");
}

.mask-icon--bfi-kufer-504,
.mask-icon-before--bfi-kufer-504::before,
.mask-icon-after--bfi-kufer-504::after {
  mask-image: svg-load("bfi-kufer-504.svg");
}

.mask-icon--bfi-kufer-505,
.mask-icon-before--bfi-kufer-505::before,
.mask-icon-after--bfi-kufer-505::after {
  mask-image: svg-load("bfi-kufer-505.svg");
}

.mask-icon--bfi-kufer-506,
.mask-icon-before--bfi-kufer-506::before,
.mask-icon-after--bfi-kufer-506::after {
  mask-image: svg-load("bfi-kufer-506.svg");
}

.mask-icon--bfi-kufer-507,
.mask-icon-before--bfi-kufer-507::before,
.mask-icon-after--bfi-kufer-507::after {
  mask-image: svg-load("bfi-kufer-507.svg");
}

.mask-icon--bfi-kufer-508,
.mask-icon-before--bfi-kufer-508::before,
.mask-icon-after--bfi-kufer-508::after {
  mask-image: svg-load("bfi-kufer-508.svg");
}

.mask-icon--bfi-kufer-509,
.mask-icon-before--bfi-kufer-509::before,
.mask-icon-after--bfi-kufer-509::after {
  mask-image: svg-load("bfi-kufer-509.svg");
}

.mask-icon--bfi-kufer-511,
.mask-icon-before--bfi-kufer-511::before,
.mask-icon-after--bfi-kufer-511::after {
  mask-image: svg-load("bfi-kufer-511.svg");
}

.mask-icon--bfi-kufer-512,
.mask-icon-before--bfi-kufer-512::before,
.mask-icon-after--bfi-kufer-512::after {
  mask-image: svg-load("bfi-kufer-512.svg");
}

.mask-icon--bfi-kufer-513,
.mask-icon-before--bfi-kufer-513::before,
.mask-icon-after--bfi-kufer-513::after {
  mask-image: svg-load("bfi-kufer-513.svg");
}

.mask-icon--bfi-kufer-514,
.mask-icon-before--bfi-kufer-514::before,
.mask-icon-after--bfi-kufer-514::after {
  mask-image: svg-load("bfi-kufer-514.svg");
}

.mask-icon--bfi-kufer-515,
.mask-icon-before--bfi-kufer-515::before,
.mask-icon-after--bfi-kufer-515::after {
  mask-image: svg-load("bfi-kufer-515.svg");
}

.mask-icon--bfi-kufer-516,
.mask-icon-before--bfi-kufer-516::before,
.mask-icon-after--bfi-kufer-516::after {
  mask-image: svg-load("bfi-kufer-516.svg");
}

.mask-icon--bfi-kufer-517,
.mask-icon-before--bfi-kufer-517::before,
.mask-icon-after--bfi-kufer-517::after {
  mask-image: svg-load("bfi-kufer-517.svg");
}

.mask-icon--bfi-kufer-519,
.mask-icon-before--bfi-kufer-519::before,
.mask-icon-after--bfi-kufer-519::after {
  mask-image: svg-load("bfi-kufer-519.svg");
}

.mask-icon--bfi-kufer-520,
.mask-icon-before--bfi-kufer-520::before,
.mask-icon-after--bfi-kufer-520::after {
  mask-image: svg-load("bfi-kufer-520.svg");
}

.mask-icon--bfi-kufer-521,
.mask-icon-before--bfi-kufer-521::before,
.mask-icon-after--bfi-kufer-521::after {
  mask-image: svg-load("bfi-kufer-521.svg");
}

.mask-icon--bfi-kufer-524,
.mask-icon-before--bfi-kufer-524::before,
.mask-icon-after--bfi-kufer-524::after {
  mask-image: svg-load("bfi-kufer-524.svg");
}

.mask-icon--bfi-kufer-525,
.mask-icon-before--bfi-kufer-525::before,
.mask-icon-after--bfi-kufer-525::after {
  mask-image: svg-load("bfi-kufer-525.svg");
}

.mask-icon--bfi-kufer-526,
.mask-icon-before--bfi-kufer-526::before,
.mask-icon-after--bfi-kufer-526::after {
  mask-image: svg-load("bfi-kufer-526.svg");
}

.mask-icon--bfi-kufer-527,
.mask-icon-before--bfi-kufer-527::before,
.mask-icon-after--bfi-kufer-527::after {
  mask-image: svg-load("bfi-kufer-527.svg");
}

.mask-icon--bfi-kufer-info,
.mask-icon-before--bfi-kufer-info::before,
.mask-icon-after--bfi-kufer-info::after {
  mask-image: svg-load("bfi-kufer-info.svg");
}

.icon-text {
  display: inline-flex;
  align-items: center;
  line-height: 1;
}
.icon-text > .icon,
.icon-text > .inline-icon {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 1.2em;
  margin-right: 0.6666666667rem;
}
.icon-text + .icon-text {
  margin-left: 0.6666666667rem;
}

.btn, .footer-newsletter-small--submit, .cta-search--submit, .search-overlay--submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.3333333333em;
  min-width: 10ch;
  padding: 0 1.667em;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  font-size: 0.8rem;
  line-height: 1;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 1.667em;
  box-shadow: none;
  transition: background-color 0.3s, background-position 0.3s, color 0.3s, border 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  margin-top: 1em;
  margin-bottom: 1em;
  overflow: hidden;
  position: relative;
}
.btn > i, .footer-newsletter-small--submit > i, .cta-search--submit > i, .search-overlay--submit > i {
  display: inline-block;
  font-size: 1.75em;
  width: 1em;
  height: 1em;
  margin-right: 0.4761904762em;
  flex-shrink: 0;
}
.btn > i svg, .footer-newsletter-small--submit > i svg, .cta-search--submit > i svg, .search-overlay--submit > i svg {
  display: block;
  width: 100%;
  height: auto;
}
.btn:hover, .footer-newsletter-small--submit:hover, .cta-search--submit:hover, .search-overlay--submit:hover, .btn:focus, .footer-newsletter-small--submit:focus, .cta-search--submit:focus, .search-overlay--submit:focus {
  color: #000;
  text-decoration: none;
}
.btn:hover::after, .footer-newsletter-small--submit:hover::after, .cta-search--submit:hover::after, .search-overlay--submit:hover::after, .btn:focus::after, .footer-newsletter-small--submit:focus::after, .cta-search--submit:focus::after, .search-overlay--submit:focus::after {
  transform: translate(-50%, -50%);
}
.btn:disabled, .footer-newsletter-small--submit:disabled, .cta-search--submit:disabled, .search-overlay--submit:disabled, .btn.disabled, .disabled.footer-newsletter-small--submit, .disabled.cta-search--submit, .disabled.search-overlay--submit {
  pointer-events: none;
  opacity: 0.4;
}
.btn-lg {
  font-size: 1.12rem;
}
.btn-sm {
  font-size: 0.64rem;
}
.btn-block {
  display: flex;
  width: 100%;
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (min-width: 576px) {
  .btn-block {
    max-width: 50vw;
  }
}
@media (min-width: 1200px) {
  .btn-block {
    max-width: 30vw;
  }
}
@media (min-width: 1400px) {
  .btn-block {
    max-width: 20vw;
  }
}
.btn-primary {
  color: #fff;
  border-color: #6ABBF3;
  background-color: #6ABBF3;
  background-image: radial-gradient(circle, #C61932 0%, #C61932 50%, #6ABBF3 50%);
  background-size: 450% 150%;
  background-position: 0% center;
  background-repeat: no-repeat;
}
.btn-primary:hover, .btn-primary:focus {
  color: #fff;
  background-color: #C61932;
  border-color: #C61932;
  background-position: center;
}
.btn-secondary {
  color: #fff;
  border-color: #003770;
  background-color: #003770;
  background-image: radial-gradient(circle, #fff 0%, #fff 50%, #003770 50%);
  background-size: 450% 150%;
  background-position: 0% center;
  background-repeat: no-repeat;
}
.btn-secondary:hover, .btn-secondary:focus {
  color: #C61932;
  background-color: #fff;
  border-color: #C61932;
  background-position: center;
}
.btn-outline {
  border-color: #eee;
  border-width: 1px;
  color: #003770;
  box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
  transition-timing-function: ease-in-out;
}
.btn-outline:hover, .btn-outline:focus {
  color: #003770;
  box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0.15);
}
.area--textmedia.is-highlighted .btn-outline, .area--parallax--content .btn-outline {
  color: #fff;
}
.btn-outline-text {
  font-size: 1rem;
  font-weight: 300;
  padding: 1.333em 1.7333em;
  border-radius: 1.3336em;
  border-width: 1px;
  border-color: #eee;
  color: #000;
  box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
  transition-timing-function: ease-in-out;
  text-decoration: underline;
  text-transform: none;
}
.btn-outline-text.btn-block {
  text-align: left;
  justify-content: left;
}
.btn-outline-text > i {
  font-size: 1.6666666667em;
  color: #C61932;
}
.btn-outline-text:hover, .btn-outline-text:focus {
  color: #003770;
  box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0.15);
  text-decoration: underline;
}
.area--textmedia.is-highlighted .btn-outline-text, .area--parallax--content .btn-outline-text {
  color: #fff;
}
.btn-text-icon {
  font-size: 1rem;
  font-weight: 300;
  padding: 0;
  border-radius: 0;
  border-width: 0;
  color: #000;
  box-shadow: none;
  min-height: 0;
  min-width: 0;
  margin: 0;
  justify-content: left;
  text-align: left;
  text-decoration: underline;
  text-transform: none;
}
.btn-text-icon > i {
  font-size: 1.6666666667em;
  color: #C61932;
}
.btn-text-icon:hover, .btn-text-icon:focus {
  text-decoration: none;
}
.area--textmedia.is-highlighted .btn-text-icon, .area--parallax--content .btn-text-icon {
  color: #fff;
}
.btn-text:hover, .btn-text:focus {
  text-decoration: underline;
}
.area--textmedia.is-highlighted .btn-text, .area--parallax--content .btn-text {
  color: #fff;
}
.btn-icon {
  min-width: 0;
  padding: 0 1rem;
}
.btn-icon > i {
  margin-right: 0;
}

.btn:not(.btn-block) + .btn:not(.btn-block), .footer-newsletter-small--submit:not(.btn-block) + .btn:not(.btn-block), .btn:not(.btn-block) + .footer-newsletter-small--submit:not(.btn-block), .footer-newsletter-small--submit:not(.btn-block) + .footer-newsletter-small--submit:not(.btn-block), .cta-search--submit:not(.btn-block) + .btn:not(.btn-block), .cta-search--submit:not(.btn-block) + .footer-newsletter-small--submit:not(.btn-block), .btn:not(.btn-block) + .cta-search--submit:not(.btn-block), .footer-newsletter-small--submit:not(.btn-block) + .cta-search--submit:not(.btn-block), .cta-search--submit:not(.btn-block) + .cta-search--submit:not(.btn-block), .search-overlay--submit:not(.btn-block) + .btn:not(.btn-block), .search-overlay--submit:not(.btn-block) + .footer-newsletter-small--submit:not(.btn-block), .search-overlay--submit:not(.btn-block) + .cta-search--submit:not(.btn-block), .btn:not(.btn-block) + .search-overlay--submit:not(.btn-block), .footer-newsletter-small--submit:not(.btn-block) + .search-overlay--submit:not(.btn-block), .cta-search--submit:not(.btn-block) + .search-overlay--submit:not(.btn-block), .search-overlay--submit:not(.btn-block) + .search-overlay--submit:not(.btn-block) {
  margin-left: 0.6666666667rem;
}

.area > .cols > .btn, .area > .cols > .footer-newsletter-small--submit, .area > .cols > .cta-search--submit, .area > .cols > .search-overlay--submit, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .btn, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .footer-newsletter-small--submit, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .cta-search--submit, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .search-overlay--submit,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .btn,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .footer-newsletter-small--submit,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .cta-search--submit,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .search-overlay--submit {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .area > .cols > .btn, .area > .cols > .footer-newsletter-small--submit, .area > .cols > .cta-search--submit, .area > .cols > .search-overlay--submit, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .btn, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .footer-newsletter-small--submit, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .cta-search--submit, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .search-overlay--submit,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .btn,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .footer-newsletter-small--submit,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .cta-search--submit,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area > .row > .search-overlay--submit {
    max-width: none;
  }
}

.aria-tooltip[aria-description]:not([aria-description=""]) {
  position: relative;
}
@media (min-width: 768px) {
  .aria-tooltip[aria-description]:not([aria-description=""])::before, .aria-tooltip[aria-description]:not([aria-description=""])::after {
    display: block;
    position: absolute;
    left: 50%;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transition-timing-function: ease-in-out;
    pointer-events: none;
    text-align: center;
    font-size: 0.8em;
    font-style: normal;
    line-height: 1.2;
    color: #000;
    text-decoration: none;
  }
  .aria-tooltip[aria-description]:not([aria-description=""])::before {
    content: attr(aria-description);
    bottom: calc(100% + 0.3333333333rem + 0.5rem);
    width: auto;
    min-width: 20ch;
    padding: 0.8333333333em;
    border-radius: 1em;
    box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0.15);
    transform: translate(-50%, 0%);
  }
  .aria-tooltip[aria-description]:not([aria-description=""])::after {
    content: "";
    bottom: calc(100% + 0.3333333333rem);
    width: 1rem;
    height: 1rem;
    transform: translate(-50%, 0%) rotate(45deg);
  }
  .aria-tooltip[aria-description]:not([aria-description=""]):hover::after, .aria-tooltip[aria-description]:not([aria-description=""]):focus::after {
    opacity: 1;
    transform: translate(-50%, 0%) rotate(45deg);
  }
  .aria-tooltip[aria-description]:not([aria-description=""]):hover::before, .aria-tooltip[aria-description]:not([aria-description=""]):focus::before {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}

.tag {
  display: inline-block;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  padding: 0.4166666667em 0.6666666667em;
  color: #757575;
  border: 1px solid #ccc;
  border-radius: 0.4166666667em;
  background-color: #fff;
  margin: 0.1666666667rem 0.3333333333rem 0.1666666667rem 0;
  text-decoration: none;
}
.tag:hover, .tag:focus {
  text-decoration: none;
  color: #757575;
}
.tag[aria-description]:not([aria-description=""]):not(a) {
  cursor: help;
}
.tag[aria-description]:not([aria-description=""]) {
  position: relative;
}
@media (min-width: 768px) {
  .tag[aria-description]:not([aria-description=""])::before, .tag[aria-description]:not([aria-description=""])::after {
    display: block;
    position: absolute;
    left: 50%;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transition-timing-function: ease-in-out;
    pointer-events: none;
    text-align: center;
    font-size: 0.8em;
    font-style: normal;
    line-height: 1.2;
    color: #000;
    text-decoration: none;
  }
  .tag[aria-description]:not([aria-description=""])::before {
    content: attr(aria-description);
    bottom: calc(100% + 0.3333333333rem + 0.5rem);
    width: auto;
    min-width: 20ch;
    padding: 0.8333333333em;
    border-radius: 1em;
    box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0.15);
    transform: translate(-50%, 0%);
  }
  .tag[aria-description]:not([aria-description=""])::after {
    content: "";
    bottom: calc(100% + 0.3333333333rem);
    width: 1rem;
    height: 1rem;
    transform: translate(-50%, 0%) rotate(45deg);
  }
  .tag[aria-description]:not([aria-description=""]):hover::after, .tag[aria-description]:not([aria-description=""]):focus::after {
    opacity: 1;
    transform: translate(-50%, 0%) rotate(45deg);
  }
  .tag[aria-description]:not([aria-description=""]):hover::before, .tag[aria-description]:not([aria-description=""]):focus::before {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}

.icon-kufer {
  display: inline-block;
  font-size: 1rem;
  width: 2.3333333333em;
  height: 2.3333333333em;
  border: none;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0.1666666667em 0.3333333333em 0.1666666667em 0;
}
.icon-kufer[aria-description]:not([aria-description=""]):not(a) {
  cursor: help;
}
.icon-kufer[aria-description]:not([aria-description=""]) {
  position: relative;
}
@media (min-width: 768px) {
  .icon-kufer[aria-description]:not([aria-description=""])::before, .icon-kufer[aria-description]:not([aria-description=""])::after {
    display: block;
    position: absolute;
    left: 50%;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transition-timing-function: ease-in-out;
    pointer-events: none;
    text-align: center;
    font-size: 0.8em;
    font-style: normal;
    line-height: 1.2;
    color: #000;
    text-decoration: none;
  }
  .icon-kufer[aria-description]:not([aria-description=""])::before {
    content: attr(aria-description);
    bottom: calc(100% + 0.3333333333rem + 0.5rem);
    width: auto;
    min-width: 20ch;
    padding: 0.8333333333em;
    border-radius: 1em;
    box-shadow: 0 0 0.667rem rgba(0, 0, 0, 0.15);
    transform: translate(-50%, 0%);
  }
  .icon-kufer[aria-description]:not([aria-description=""])::after {
    content: "";
    bottom: calc(100% + 0.3333333333rem);
    width: 1rem;
    height: 1rem;
    transform: translate(-50%, 0%) rotate(45deg);
  }
  .icon-kufer[aria-description]:not([aria-description=""]):hover::after, .icon-kufer[aria-description]:not([aria-description=""]):focus::after {
    opacity: 1;
    transform: translate(-50%, 0%) rotate(45deg);
  }
  .icon-kufer[aria-description]:not([aria-description=""]):hover::before, .icon-kufer[aria-description]:not([aria-description=""]):focus::before {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}
.icon-kufer--medium {
  font-size: 17px;
}
@media screen and (min-width: 768px) {
  .icon-kufer--medium {
    font-size: calc(17px + 8 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .icon-kufer--medium {
    font-size: 25px;
  }
}

.card-item {
  display: block;
  font-size: 1rem;
  border-radius: 1.3333333333rem;
  position: relative;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  background-color: #fff;
  min-height: 100%;
}
.card-item-icon {
  background-color: transparent;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0);
  text-align: center;
  border: 1px solid #eee;
}
.card-item-icon {
  padding-top: 30px;
}
@media screen and (min-width: 768px) {
  .card-item-icon {
    padding-top: calc(30px + 30 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .card-item-icon {
    padding-top: 60px;
  }
}
.card-item-icon {
  padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .card-item-icon {
    padding-bottom: calc(30px + 30 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .card-item-icon {
    padding-bottom: 60px;
  }
}
.card-item-icon .card-item--content {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .card-item-icon {
    border-color: transparent;
  }
}
.card-item:not(.card-item--no-hover):focus-within {
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1), 0 0 0 2pt #6ABBF3;
}
@media (min-width: 992px) {
  .card-item:not(.card-item--no-hover) {
    transition: transform 0.3s, box-shadow 0.3s;
    transition-timing-function: ease-in-out;
    will-change: transform, box-shadow;
  }
  .card-item:not(.card-item--no-hover):hover, .card-item:not(.card-item--no-hover):focus {
    box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.15);
    transform: translate3d(0, -5px, 0);
  }
}
.card-item--stretched-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: -9999em;
  text-align: left;
  opacity: 0;
}
.card-item--head {
  position: relative;
}
.card-item--head--media {
  position: relative;
  border-radius: 1.3333333333rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}
.card-item--head--media:empty {
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
}
.card-item--head--media::before {
  content: "";
  display: block;
  padding-top: 54%;
}
.card-item-icon .card-item--head--media {
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
}
.card-item-icon .card-item--head--media {
  max-width: 80px;
}
@media screen and (min-width: 768px) {
  .card-item-icon .card-item--head--media {
    max-width: calc(80px + 70 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .card-item-icon .card-item--head--media {
    max-width: 150px;
  }
}
.card-item-icon .card-item--head--media::before {
  padding-top: 100%;
}
.card-item-icon .card-item--head--media picture, .card-item-icon .card-item--head--media img, .card-item-icon .card-item--head--media video {
  object-fit: contain;
}
.card-item--head--media picture, .card-item--head--media img, .card-item--head--media video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  width: calc(100% + 3px);
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.card-item--head--headline {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  width: 100%;
  padding: 0 1.3333333333rem 1.3333333333rem 1.3333333333rem;
}
@media (min-width: 768px) {
  .card-item--head--headline {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.card-item--head--headline h1, .card-item--head--headline h2, .card-item--head--headline h3, .card-item--head--headline h4, .card-item--head--headline h5, .card-item--head--headline h6,
.card-item--head--headline .h1, .card-item--head--headline .h2, .card-item--head--headline .h3, .card-item--head--headline .h4, .card-item--head--headline .area--thumbnail--caption, .card-item--head--headline .cart-big--item--title--headline, .card-item--head--headline .h5, .card-item--head--headline .h6 {
  color: currentColor;
}
.card-item--head--headline h1 small, .card-item--head--headline h2 small, .card-item--head--headline h3 small, .card-item--head--headline h4 small, .card-item--head--headline h5 small, .card-item--head--headline h6 small,
.card-item--head--headline .h1 small, .card-item--head--headline .h2 small, .card-item--head--headline .h3 small, .card-item--head--headline .h4 small, .card-item--head--headline .area--thumbnail--caption small, .card-item--head--headline .cart-big--item--title--headline small, .card-item--head--headline .h5 small, .card-item--head--headline .h6 small {
  color: currentColor;
  font-weight: 300;
  margin-top: 0.6666666667rem;
  font-size: 1rem;
}
.card-item--head--headline h1, .card-item--head--headline h2, .card-item--head--headline h3, .card-item--head--headline h4, .card-item--head--headline h5, .card-item--head--headline h6,
.card-item--head--headline .h1, .card-item--head--headline .h2, .card-item--head--headline .h3, .card-item--head--headline .h4, .card-item--head--headline .area--thumbnail--caption, .card-item--head--headline .cart-big--item--title--headline, .card-item--head--headline .h5, .card-item--head--headline .h6,
.card-item--head--headline p,
.card-item--head--headline hr {
  margin: 0.6666666667rem 0;
}
.card-item--head--headline > *:first-child {
  margin-top: 0;
}
.card-item--head--headline > *:last-child {
  margin-bottom: 0;
}
.card-item--head--tools {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0.6666666667rem;
}
.card-item--content {
  padding: 2rem 0;
}
.card-item--content .border-top,
.card-item--content .border-bottom {
  position: relative;
}
.card-item--content .border-top::before,
.card-item--content .border-bottom::after {
  content: "";
  display: block;
  position: absolute;
  left: 2rem;
  right: 2rem;
  height: 0.0666666667rem;
  background-color: #eee;
}
.card-item--content .border-top {
  padding-top: 0.6666666667rem;
}
.card-item--content .border-top::before {
  top: 0;
}
.card-item--content .border-bottom {
  padding-bottom: 0.6666666667rem;
}
.card-item--content .border-bottom::after {
  bottom: 0;
}
.card-item--content-item, .card-item--course-filter--filter, .card-item--course-filter--result {
  position: relative;
  padding: 0.6666666667rem 1.3333333333rem;
  border-collapse: collapse;
}
@media (min-width: 768px) {
  .card-item--content-item, .card-item--course-filter--filter, .card-item--course-filter--result {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.card-item--content-item:first-child, .card-item--course-filter--filter:first-child, .card-item--course-filter--result:first-child {
  padding-top: 0;
}
.card-item--content-item:last-child, .card-item--course-filter--filter:last-child, .card-item--course-filter--result:last-child {
  padding-bottom: 0;
}
.card-item--content-item a, .card-item--course-filter--filter a, .card-item--course-filter--result a {
  z-index: 1;
}
.card-item--content-item a:not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit), .card-item--course-filter--filter a:not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit), .card-item--course-filter--result a:not(.btn):not(.footer-newsletter-small--submit):not(.cta-search--submit):not(.search-overlay--submit) {
  position: relative;
}
.card-item--content-item h1, .card-item--course-filter--filter h1, .card-item--course-filter--result h1, .card-item--content-item h2, .card-item--course-filter--filter h2, .card-item--course-filter--result h2, .card-item--content-item h3, .card-item--course-filter--filter h3, .card-item--course-filter--result h3, .card-item--content-item h4, .card-item--course-filter--filter h4, .card-item--course-filter--result h4, .card-item--content-item h5, .card-item--course-filter--filter h5, .card-item--course-filter--result h5, .card-item--content-item h6, .card-item--course-filter--filter h6, .card-item--course-filter--result h6,
.card-item--content-item .h1,
.card-item--course-filter--filter .h1,
.card-item--course-filter--result .h1, .card-item--content-item .h2, .card-item--course-filter--filter .h2, .card-item--course-filter--result .h2, .card-item--content-item .h3, .card-item--course-filter--filter .h3, .card-item--course-filter--result .h3, .card-item--content-item .h4, .card-item--content-item .area--thumbnail--caption, .card-item--content-item .cart-big--item--title--headline, .card-item--course-filter--filter .h4, .card-item--course-filter--filter .area--thumbnail--caption, .card-item--course-filter--filter .cart-big--item--title--headline, .card-item--course-filter--result .h4, .card-item--course-filter--result .area--thumbnail--caption, .card-item--course-filter--result .cart-big--item--title--headline, .card-item--content-item .h5, .card-item--course-filter--filter .h5, .card-item--course-filter--result .h5, .card-item--content-item .h6, .card-item--course-filter--filter .h6, .card-item--course-filter--result .h6 {
  color: currentColor;
}
.card-item--content-item h1 small, .card-item--course-filter--filter h1 small, .card-item--course-filter--result h1 small, .card-item--content-item h2 small, .card-item--course-filter--filter h2 small, .card-item--course-filter--result h2 small, .card-item--content-item h3 small, .card-item--course-filter--filter h3 small, .card-item--course-filter--result h3 small, .card-item--content-item h4 small, .card-item--course-filter--filter h4 small, .card-item--course-filter--result h4 small, .card-item--content-item h5 small, .card-item--course-filter--filter h5 small, .card-item--course-filter--result h5 small, .card-item--content-item h6 small, .card-item--course-filter--filter h6 small, .card-item--course-filter--result h6 small,
.card-item--content-item .h1 small,
.card-item--course-filter--filter .h1 small,
.card-item--course-filter--result .h1 small, .card-item--content-item .h2 small, .card-item--course-filter--filter .h2 small, .card-item--course-filter--result .h2 small, .card-item--content-item .h3 small, .card-item--course-filter--filter .h3 small, .card-item--course-filter--result .h3 small, .card-item--content-item .h4 small, .card-item--content-item .area--thumbnail--caption small, .card-item--content-item .cart-big--item--title--headline small, .card-item--course-filter--filter .h4 small, .card-item--course-filter--filter .area--thumbnail--caption small, .card-item--course-filter--filter .cart-big--item--title--headline small, .card-item--course-filter--result .h4 small, .card-item--course-filter--result .area--thumbnail--caption small, .card-item--course-filter--result .cart-big--item--title--headline small, .card-item--content-item .h5 small, .card-item--course-filter--filter .h5 small, .card-item--course-filter--result .h5 small, .card-item--content-item .h6 small, .card-item--course-filter--filter .h6 small, .card-item--course-filter--result .h6 small {
  font-weight: 300;
  margin-top: 0.6666666667rem;
  font-size: 1rem;
}
.card-item--content-item h1, .card-item--course-filter--filter h1, .card-item--course-filter--result h1, .card-item--content-item h2, .card-item--course-filter--filter h2, .card-item--course-filter--result h2, .card-item--content-item h3, .card-item--course-filter--filter h3, .card-item--course-filter--result h3, .card-item--content-item h4, .card-item--course-filter--filter h4, .card-item--course-filter--result h4, .card-item--content-item h5, .card-item--course-filter--filter h5, .card-item--course-filter--result h5, .card-item--content-item h6, .card-item--course-filter--filter h6, .card-item--course-filter--result h6,
.card-item--content-item .h1,
.card-item--course-filter--filter .h1,
.card-item--course-filter--result .h1, .card-item--content-item .h2, .card-item--course-filter--filter .h2, .card-item--course-filter--result .h2, .card-item--content-item .h3, .card-item--course-filter--filter .h3, .card-item--course-filter--result .h3, .card-item--content-item .h4, .card-item--content-item .area--thumbnail--caption, .card-item--content-item .cart-big--item--title--headline, .card-item--course-filter--filter .h4, .card-item--course-filter--filter .area--thumbnail--caption, .card-item--course-filter--filter .cart-big--item--title--headline, .card-item--course-filter--result .h4, .card-item--course-filter--result .area--thumbnail--caption, .card-item--course-filter--result .cart-big--item--title--headline, .card-item--content-item .h5, .card-item--course-filter--filter .h5, .card-item--course-filter--result .h5, .card-item--content-item .h6, .card-item--course-filter--filter .h6, .card-item--course-filter--result .h6,
.card-item--content-item p,
.card-item--course-filter--filter p,
.card-item--course-filter--result p,
.card-item--content-item hr,
.card-item--course-filter--filter hr,
.card-item--course-filter--result hr {
  margin: 0.6666666667rem 0;
}
.card-item--content-item > *:first-child, .card-item--course-filter--filter > *:first-child, .card-item--course-filter--result > *:first-child {
  margin-top: 0;
}
.card-item--content-item > *:last-child, .card-item--course-filter--filter > *:last-child, .card-item--course-filter--result > *:last-child {
  margin-bottom: 0;
}
.card-item--content-item .icon-text, .card-item--course-filter--filter .icon-text, .card-item--course-filter--result .icon-text {
  flex-shrink: 0;
  font-size: 0.8rem;
}
.card-item--content-item--icon-texts {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.6666666667rem;
}
.card-item--content-item--icon-texts .icon-text {
  margin-left: 0 !important;
}
.card-item--content-item--icon-texts + p:not(.small):not(.cart-big--item--title--info):not(.form-infotext-scroll--content) {
  margin-top: 1rem;
}
.card-item--content--course-info {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 400;
  padding-top: 1.3333333333rem;
}
.card-item--content--course-info--price {
  font-weight: 700;
}
.card-item--content--course-info--number {
  margin-top: 0.6666666667rem;
  font-size: 0.8rem;
  flex-basis: 100%;
  text-align: right;
}
.card-item--content--course-info--col-info {
  align-self: flex-end;
  text-align: left;
}
.card-item--content--course-info--col-info > * {
  margin-top: 0;
  margin-bottom: 0;
}
.card-item--content--course-info--col-info .card-item--content--course-info--number {
  text-align: left;
}
.card-item--content--course-info--col-info .card-item--content--course-info--price {
  font-size: 1.0666666667rem;
}
@media screen and (min-width: 768px) {
  .card-item--content--course-info--col-info .card-item--content--course-info--price {
    font-size: calc(16px + 9 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .card-item--content--course-info--col-info .card-item--content--course-info--price {
    font-size: 1.6666666667rem;
  }
}
.card-item--content--course-info--col-button {
  align-self: flex-end;
  flex-shrink: 0;
  flex-grow: 0;
}
.card-item--content--course-info--col-button > .btn, .card-item--content--course-info--col-button > .footer-newsletter-small--submit, .card-item--content--course-info--col-button > .cta-search--submit, .card-item--content--course-info--col-button > .search-overlay--submit {
  margin: 0;
}
.card-item--content--additional-info {
  padding-top: 1.3333333333rem;
}
.card-item--content--additional-info p {
  margin-top: 0;
  margin-bottom: 0;
}
.card-item--content-item--buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 2rem;
}
.card-item--content-item--buttons .btn, .card-item--content-item--buttons .footer-newsletter-small--submit, .card-item--content-item--buttons .cta-search--submit, .card-item--content-item--buttons .search-overlay--submit {
  margin: 0;
}
.card-item--content-item--buttons-multiple {
  padding-top: 0;
}
@media (max-width: 400px) {
  .card-item--content-item--buttons-multiple {
    display: block;
  }
  .card-item--content-item--buttons-multiple .btn, .card-item--content-item--buttons-multiple .footer-newsletter-small--submit, .card-item--content-item--buttons-multiple .cta-search--submit, .card-item--content-item--buttons-multiple .search-overlay--submit {
    display: flex;
    width: 100%;
    margin-bottom: 0.6666666667rem;
    margin-left: 0 !important;
  }
  .card-item--content-item--buttons-multiple .btn:last-child, .card-item--content-item--buttons-multiple .footer-newsletter-small--submit:last-child, .card-item--content-item--buttons-multiple .cta-search--submit:last-child, .card-item--content-item--buttons-multiple .search-overlay--submit:last-child {
    margin-bottom: 0;
  }
}
.card-item--course-filter--filter {
  margin-top: 0.6666666667rem;
  padding-bottom: 1.3333333333rem;
}
@media (min-width: 768px) {
  .card-item--course-filter--filter {
    padding-bottom: 2.6666666667rem;
  }
}
.card-item--course-filter--result {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  border-top: 1px solid #eee;
}
.card-item--course-filter--result.hidden {
  display: none;
}
.card-item--course-filter--result--header:not(.no-accordion) {
  list-style: none;
  position: relative;
}
.card-item--course-filter--result--header:not(.no-accordion)::marker, .card-item--course-filter--result--header:not(.no-accordion)::-webkit-details-marker {
  display: none;
}
.card-item--course-filter--result--header:not(.no-accordion)::after {
  content: "";
  display: block;
  width: 0.5333333333rem;
  height: 0.9333333333rem;
  position: absolute;
  top: 1.3333333333rem;
  right: 0;
  background-image: svg-load("angle-right.svg", fill=#000);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
  will-change: transform, opacify;
  transition: transform 0.3s, opacity 0.3s;
}
@media (min-width: 768px) {
  .card-item--course-filter--result--header:not(.no-accordion)::after {
    top: 3rem;
  }
}
.card-item--course-filter--result[open] .card-item--course-filter--result--header:not(.no-accordion) {
  padding-bottom: 1.3333333333rem;
}
.card-item--course-filter--result[open] .card-item--course-filter--result--header:not(.no-accordion)::after {
  opacity: 0.2;
  transform: rotate(90deg);
}
.card-item--course-filter--result--header {
  display: block;
  padding-top: 1.3333333333rem;
  padding-bottom: 1.3333333333rem;
  padding-right: 0.9333333333rem;
}
.card-item--course-filter--result--header .icon-text {
  display: flex;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .card-item--course-filter--result--header .icon-text {
    display: inline-flex;
  }
}
@media (min-width: 768px) {
  .card-item--course-filter--result--header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.4666666667rem;
  }
}
@media (min-width: 768px) {
  .card-item--course-filter--result--header {
    padding-top: 2.6666666667rem;
    padding-bottom: 2.6666666667rem;
  }
}
.card-item--course-filter--result--header.no-accordion {
  padding-bottom: 1.3333333333rem;
}
.card-item--course-filter--result--content {
  padding-bottom: 1.3333333333rem;
}
@media (min-width: 768px) {
  .card-item--course-filter--result--content {
    padding-bottom: 2.6666666667rem;
  }
}
.card-item--download-buttons {
  padding-top: 1.3333333333rem;
}
.card-item--download-buttons > strong {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 900;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .card-item--download-buttons > strong {
    font-size: 1rem;
    font-weight: 700;
  }
}
.card-item--download-buttons .btn-text-icon.btn-block {
  margin-bottom: 0.6666666667rem;
}
.card-item--download-buttons .btn-text-icon.btn-block:last-child {
  margin-bottom: 0;
}

.card-item-promo {
  position: relative;
  overflow: hidden;
}
.card-item-promo .card-item--head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card-item-promo .card-item--head--media {
  border-bottom-left-radius: 1.3333333333rem;
  border-bottom-right-radius: 1.3333333333rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: calc(100% - 2px);
  background-color: #003770;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
}
.card-item-promo .card-item--head--media img, .card-item-promo .card-item--head--media video {
  opacity: 0.4;
  mix-blend-mode: luminosity;
}
.card-item-promo--head--headline {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 2.6666666667rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2rem;
}
@media (min-width: 992px) {
  .card-item-promo .card-item--head--media {
    transition: height 0.3s;
    transition-timing-function: ease-in-out;
    will-change: height;
  }
  .card-item-promo .card-item--head--media img, .card-item-promo .card-item--head--media video {
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
    will-change: transform;
  }
  .card-item-promo .card-item--content {
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
    will-change: transform;
  }
  .card-item-promo:hover .card-item--head--media, .card-item-promo:focus .card-item--head--media {
    height: 100%;
  }
  .card-item-promo:hover .card-item--head--media img, .card-item-promo:hover .card-item--head--media video, .card-item-promo:focus .card-item--head--media img, .card-item-promo:focus .card-item--head--media video {
    transform: scale(1.2);
  }
  .card-item-promo:hover .card-item--content, .card-item-promo:focus .card-item--content {
    transform: translate3d(0, 100%, 0);
  }
}
.card-item-promo::before {
  content: "";
  display: block;
  padding-top: 129%;
}
.card-item-promo .card-item--content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 0;
}
.card-item-promo .card-item--content > .card-item--content-item.card-item-promo--content-item, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--filter, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--result {
  background-color: #fff;
  position: relative;
  padding-top: 3.3333333333rem;
  text-align: center;
}
.card-item-promo .card-item--content > .card-item--content-item.card-item-promo--content-item:last-child, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--filter:last-child, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--result:last-child {
  padding-bottom: 2rem;
}
.card-item-promo .card-item--content > .card-item--content-item.card-item-promo--content-item::before, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--filter::before, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--result::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.3333333333rem;
  transform: translateY(-100%);
  background-image: svg-load("layout/svg/card-item-promo--rounded.svg");
  background-position: bottom left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.card-item-promo .card-item--content > .card-item--content-item.card-item-promo--content-item p, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--filter p, .card-item-promo .card-item--content > .card-item-promo--content-item.card-item--course-filter--result p {
  margin-bottom: 0;
}
.card-item-promo .card-item--content .card-item-promo--button {
  display: flex;
  width: auto;
  margin: 0;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.accordion {
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 1.3333333333rem;
  background-color: #fff;
}
.accordion--header {
  line-height: 1.2;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #003770;
  hyphens: auto;
  word-break: break-all;
  padding: 1rem;
  padding-left: 3.0555555556em;
  list-style: none;
  background-image: svg-load("angle-right.svg", fill=#fff);
  background-size: auto 0.7777777778em;
  background-repeat: no-repeat;
  background-position: 1.0277777778em center;
}
.accordion--header {
  font-size: 1.0666666667rem;
}
@media screen and (min-width: 768px) {
  .accordion--header {
    font-size: calc(16px + 2 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .accordion--header {
    font-size: 1.2rem;
  }
}
@supports (hyphens: auto) {
  .accordion--header {
    word-break: normal;
  }
}
@media (min-width: 992px) {
  .accordion--header {
    word-break: normal;
  }
}
.accordion--header::marker, .accordion--header::-webkit-details-marker {
  display: none;
}
.accordion[open] .accordion--header {
  background-image: svg-load("angle-down.svg", fill=#003770);
  background-size: 0.7777777778em auto;
  background-color: #fff;
  color: #003770;
}
.accordion--collapsible {
  padding: 1rem 1.3333333333rem 1.3333333333rem 1.3333333333rem;
}
.accordion--content > *:first-child {
  margin-top: 0;
}
.accordion--content > *:last-child {
  margin-bottom: 0;
}

.accordion + .accordion {
  margin-top: 0.6666666667rem;
}

.accordion-simple > summary {
  list-style: none;
}
.accordion-simple > summary::marker, .accordion-simple > summary::-webkit-details-marker {
  display: none;
}

.infobox {
  position: relative;
  border-radius: 1.3333333333rem;
  padding: 1.3333333333rem;
}
@media (min-width: 576px) {
  .infobox {
    padding: 2.6666666667rem;
  }
}
.infobox > *:first-child {
  margin-top: 0;
}
.infobox > *:last-child {
  margin-bottom: 0;
}
.infobox > h1, .infobox > h2, .infobox > h3, .infobox > h4, .infobox > h5, .infobox > h6,
.infobox > .h1, .infobox > .h2, .infobox > .h3, .infobox > .h4, .infobox > .area--thumbnail--caption, .infobox > .cart-big--item--title--headline, .infobox > .h5, .infobox > .h6 {
  margin-bottom: 1em;
}
.infobox--title {
  margin-bottom: 1em;
}
.infobox--title * {
  color: currentColor;
}
.infobox--title > *:first-child {
  margin-bottom: 0;
}
.infobox {
  border: 1px solid #eee;
  background-color: #fff;
}
.infobox .infobox--title {
  color: #003770;
}
.infobox-primary {
  color: #003770;
  background-color: #F0F5FB;
  border-color: #F0F5FB;
}
.infobox-primary .infobox--title {
  color: currentColor;
}
.infobox-success {
  color: #3BA738;
  background-color: #F0FBF3;
  border-color: #F0FBF3;
}
.infobox-success .infobox--title {
  color: currentColor;
}
.infobox-danger {
  color: #B83D3D;
  background-color: #FBF0F0;
  border-color: #FBF0F0;
}
.infobox-danger .infobox--title {
  color: currentColor;
}

/**
 *
 * Form Container
 *
 */
.form-container fieldset,
.form-builder-wrapper fieldset {
  margin-bottom: 10px;
}
.form-container fieldset:last-child,
.form-builder-wrapper fieldset:last-child {
  margin-bottom: 0;
}
.form-container fieldset legend:not(.label),
.form-builder-wrapper fieldset legend:not(.label) {
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  color: #757575;
}
.form-container .cols,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-container .form-group > .row,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row,
.form-builder-wrapper .cols,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-builder-wrapper .form-group > .row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 2rem;
}
.form-group {
  margin-bottom: 2rem;
}
.cols .form-group, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .form-group,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row .form-group {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .form-group.form-group-horizontal {
    display: flex;
    align-items: center;
  }
  .form-group.form-group-horizontal > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 2rem;
  }
  .form-group.form-group-horizontal > *:first-child {
    margin-left: 0;
  }
}

.form-group.form-buttons,
.formbuilder-row.form-buttons > .form-group {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}
.form-group.form-buttons .btn, .form-group.form-buttons .footer-newsletter-small--submit, .form-group.form-buttons .cta-search--submit, .form-group.form-buttons .search-overlay--submit,
.formbuilder-row.form-buttons > .form-group .btn,
.formbuilder-row.form-buttons > .form-group .footer-newsletter-small--submit,
.formbuilder-row.form-buttons > .form-group .cta-search--submit,
.formbuilder-row.form-buttons > .form-group .search-overlay--submit {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .form-group.form-buttons.col > .btn:first-child, .form-group.form-buttons.col > .footer-newsletter-small--submit:first-child, .form-group.form-buttons.col > .cta-search--submit:first-child, .form-group.form-buttons.col > .search-overlay--submit:first-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .btn:first-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:first-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .cta-search--submit:first-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .search-overlay--submit:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .btn:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .cta-search--submit:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .search-overlay--submit:first-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .btn:first-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .cta-search--submit:first-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .search-overlay--submit:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .btn:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .cta-search--submit:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .search-overlay--submit:first-child,
.formbuilder-row.form-buttons > .form-group.col > .btn:first-child,
.formbuilder-row.form-buttons > .form-group.col > .footer-newsletter-small--submit:first-child,
.formbuilder-row.form-buttons > .form-group.col > .cta-search--submit:first-child,
.formbuilder-row.form-buttons > .form-group.col > .search-overlay--submit:first-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:first-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:first-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:first-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:first-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:first-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:first-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:first-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:first-child {
    margin-top: 0;
  }
  .form-group.form-buttons.col > .btn:last-child, .form-group.form-buttons.col > .footer-newsletter-small--submit:last-child, .form-group.form-buttons.col > .cta-search--submit:last-child, .form-group.form-buttons.col > .search-overlay--submit:last-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .btn:last-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:last-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .cta-search--submit:last-child, .form-container .cols > .form-group.form-buttons[class*=col-] > .search-overlay--submit:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .btn:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .cta-search--submit:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .search-overlay--submit:last-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .btn:last-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .cta-search--submit:last-child,
.form-builder-wrapper .cols > .form-group.form-buttons[class*=col-] > .search-overlay--submit:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .btn:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .cta-search--submit:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row > .form-group.form-buttons[class*=col-] > .search-overlay--submit:last-child,
.formbuilder-row.form-buttons > .form-group.col > .btn:last-child,
.formbuilder-row.form-buttons > .form-group.col > .footer-newsletter-small--submit:last-child,
.formbuilder-row.form-buttons > .form-group.col > .cta-search--submit:last-child,
.formbuilder-row.form-buttons > .form-group.col > .search-overlay--submit:last-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:last-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:last-child,
.form-container .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:last-child,
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:last-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:last-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:last-child,
.form-builder-wrapper .cols.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .btn:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .footer-newsletter-small--submit:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .cta-search--submit:last-child,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group > .row.formbuilder-row.form-buttons > .form-group[class*=col-] > .search-overlay--submit:last-child {
    margin-bottom: 0;
  }
}

/**
 *
 * Form Fields
 *
 */
.form-container label:not(input + label),
.form-container .label:not(input + .label),
.form-builder-wrapper label:not(input + label),
.form-builder-wrapper .label:not(input + .label),
.card-form-container label:not(input + label),
.card-form-container .label:not(input + .label) {
  font-weight: 900;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.3333333333rem;
}
.form-container input + small,
.form-container select + small,
.form-container textarea + small,
.form-builder-wrapper input + small,
.form-builder-wrapper select + small,
.form-builder-wrapper textarea + small,
.card-form-container input + small,
.card-form-container select + small,
.card-form-container textarea + small {
  display: block;
  text-align: right;
  padding: 0.6666666667rem 1.3333333333rem;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
  color: #757575;
  margin-top: 0.25em;
}
.form-container input[type=text],
.form-container input[type=search],
.form-container input[type=number],
.form-container input[type=tel],
.form-container input[type=email],
.form-container input[type=date],
.form-container input[type=datetime-local],
.form-container input[type=password],
.form-container select,
.form-container textarea,
.form-builder-wrapper input[type=text],
.form-builder-wrapper input[type=search],
.form-builder-wrapper input[type=number],
.form-builder-wrapper input[type=tel],
.form-builder-wrapper input[type=email],
.form-builder-wrapper input[type=date],
.form-builder-wrapper input[type=datetime-local],
.form-builder-wrapper input[type=password],
.form-builder-wrapper select,
.form-builder-wrapper textarea,
.card-form-container input[type=text],
.card-form-container input[type=search],
.card-form-container input[type=number],
.card-form-container input[type=tel],
.card-form-container input[type=email],
.card-form-container input[type=date],
.card-form-container input[type=datetime-local],
.card-form-container input[type=password],
.card-form-container select,
.card-form-container textarea {
  padding: 0.6666666667rem 1.3333333333rem;
  width: 100%;
  color: #000;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 1.3333333333rem;
  position: relative;
  /*&:not(:focus):not(:placeholder-shown):invalid,
  &[required]:not(:focus):not(:placeholder-shown):invalid{
      border-color: $color-danger;
  }*/
}
.form-container input[type=text]:focus,
.form-container input[type=search]:focus,
.form-container input[type=number]:focus,
.form-container input[type=tel]:focus,
.form-container input[type=email]:focus,
.form-container input[type=date]:focus,
.form-container input[type=datetime-local]:focus,
.form-container input[type=password]:focus,
.form-container select:focus,
.form-container textarea:focus,
.form-builder-wrapper input[type=text]:focus,
.form-builder-wrapper input[type=search]:focus,
.form-builder-wrapper input[type=number]:focus,
.form-builder-wrapper input[type=tel]:focus,
.form-builder-wrapper input[type=email]:focus,
.form-builder-wrapper input[type=date]:focus,
.form-builder-wrapper input[type=datetime-local]:focus,
.form-builder-wrapper input[type=password]:focus,
.form-builder-wrapper select:focus,
.form-builder-wrapper textarea:focus,
.card-form-container input[type=text]:focus,
.card-form-container input[type=search]:focus,
.card-form-container input[type=number]:focus,
.card-form-container input[type=tel]:focus,
.card-form-container input[type=email]:focus,
.card-form-container input[type=date]:focus,
.card-form-container input[type=datetime-local]:focus,
.card-form-container input[type=password]:focus,
.card-form-container select:focus,
.card-form-container textarea:focus {
  outline: 0;
  color: #000;
  background-color: #fff;
  border-color: rgba(0, 55, 112, 0.5);
  box-shadow: 0 0 0 0.1333333333rem rgba(0, 55, 112, 0.5);
}
.form-container input[type=text][readonly], .form-container input[type=text][disabled],
.form-container input[type=search][readonly],
.form-container input[type=search][disabled],
.form-container input[type=number][readonly],
.form-container input[type=number][disabled],
.form-container input[type=tel][readonly],
.form-container input[type=tel][disabled],
.form-container input[type=email][readonly],
.form-container input[type=email][disabled],
.form-container input[type=date][readonly],
.form-container input[type=date][disabled],
.form-container input[type=datetime-local][readonly],
.form-container input[type=datetime-local][disabled],
.form-container input[type=password][readonly],
.form-container input[type=password][disabled],
.form-container select[readonly],
.form-container select[disabled],
.form-container textarea[readonly],
.form-container textarea[disabled],
.form-builder-wrapper input[type=text][readonly],
.form-builder-wrapper input[type=text][disabled],
.form-builder-wrapper input[type=search][readonly],
.form-builder-wrapper input[type=search][disabled],
.form-builder-wrapper input[type=number][readonly],
.form-builder-wrapper input[type=number][disabled],
.form-builder-wrapper input[type=tel][readonly],
.form-builder-wrapper input[type=tel][disabled],
.form-builder-wrapper input[type=email][readonly],
.form-builder-wrapper input[type=email][disabled],
.form-builder-wrapper input[type=date][readonly],
.form-builder-wrapper input[type=date][disabled],
.form-builder-wrapper input[type=datetime-local][readonly],
.form-builder-wrapper input[type=datetime-local][disabled],
.form-builder-wrapper input[type=password][readonly],
.form-builder-wrapper input[type=password][disabled],
.form-builder-wrapper select[readonly],
.form-builder-wrapper select[disabled],
.form-builder-wrapper textarea[readonly],
.form-builder-wrapper textarea[disabled],
.card-form-container input[type=text][readonly],
.card-form-container input[type=text][disabled],
.card-form-container input[type=search][readonly],
.card-form-container input[type=search][disabled],
.card-form-container input[type=number][readonly],
.card-form-container input[type=number][disabled],
.card-form-container input[type=tel][readonly],
.card-form-container input[type=tel][disabled],
.card-form-container input[type=email][readonly],
.card-form-container input[type=email][disabled],
.card-form-container input[type=date][readonly],
.card-form-container input[type=date][disabled],
.card-form-container input[type=datetime-local][readonly],
.card-form-container input[type=datetime-local][disabled],
.card-form-container input[type=password][readonly],
.card-form-container input[type=password][disabled],
.card-form-container select[readonly],
.card-form-container select[disabled],
.card-form-container textarea[readonly],
.card-form-container textarea[disabled] {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(224, 224, 224, 0.3);
  cursor: not-allowed;
}
.form-container input[type=text]:not(:focus):not(:placeholder-shown):invalid, .form-container input[type=text].has-error:invalid,
.form-container input[type=search]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=search].has-error:invalid,
.form-container input[type=number]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=number].has-error:invalid,
.form-container input[type=tel]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=tel].has-error:invalid,
.form-container input[type=email]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=email].has-error:invalid,
.form-container input[type=date]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=date].has-error:invalid,
.form-container input[type=datetime-local]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=datetime-local].has-error:invalid,
.form-container input[type=password]:not(:focus):not(:placeholder-shown):invalid,
.form-container input[type=password].has-error:invalid,
.form-container select:not(:focus):not(:placeholder-shown):invalid,
.form-container select.has-error:invalid,
.form-container textarea:not(:focus):not(:placeholder-shown):invalid,
.form-container textarea.has-error:invalid,
.form-builder-wrapper input[type=text]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=text].has-error:invalid,
.form-builder-wrapper input[type=search]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=search].has-error:invalid,
.form-builder-wrapper input[type=number]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=number].has-error:invalid,
.form-builder-wrapper input[type=tel]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=tel].has-error:invalid,
.form-builder-wrapper input[type=email]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=email].has-error:invalid,
.form-builder-wrapper input[type=date]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=date].has-error:invalid,
.form-builder-wrapper input[type=datetime-local]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=datetime-local].has-error:invalid,
.form-builder-wrapper input[type=password]:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper input[type=password].has-error:invalid,
.form-builder-wrapper select:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper select.has-error:invalid,
.form-builder-wrapper textarea:not(:focus):not(:placeholder-shown):invalid,
.form-builder-wrapper textarea.has-error:invalid,
.card-form-container input[type=text]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=text].has-error:invalid,
.card-form-container input[type=search]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=search].has-error:invalid,
.card-form-container input[type=number]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=number].has-error:invalid,
.card-form-container input[type=tel]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=tel].has-error:invalid,
.card-form-container input[type=email]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=email].has-error:invalid,
.card-form-container input[type=date]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=date].has-error:invalid,
.card-form-container input[type=datetime-local]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=datetime-local].has-error:invalid,
.card-form-container input[type=password]:not(:focus):not(:placeholder-shown):invalid,
.card-form-container input[type=password].has-error:invalid,
.card-form-container select:not(:focus):not(:placeholder-shown):invalid,
.card-form-container select.has-error:invalid,
.card-form-container textarea:not(:focus):not(:placeholder-shown):invalid,
.card-form-container textarea.has-error:invalid {
  border-color: #B83D3D;
}
.form-container input[type=text]:not(:focus):not(:placeholder-shown):invalid + small, .form-container input[type=text].has-error:invalid + small,
.form-container input[type=search]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=search].has-error:invalid + small,
.form-container input[type=number]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=number].has-error:invalid + small,
.form-container input[type=tel]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=tel].has-error:invalid + small,
.form-container input[type=email]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=email].has-error:invalid + small,
.form-container input[type=date]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=date].has-error:invalid + small,
.form-container input[type=datetime-local]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=datetime-local].has-error:invalid + small,
.form-container input[type=password]:not(:focus):not(:placeholder-shown):invalid + small,
.form-container input[type=password].has-error:invalid + small,
.form-container select:not(:focus):not(:placeholder-shown):invalid + small,
.form-container select.has-error:invalid + small,
.form-container textarea:not(:focus):not(:placeholder-shown):invalid + small,
.form-container textarea.has-error:invalid + small,
.form-builder-wrapper input[type=text]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=text].has-error:invalid + small,
.form-builder-wrapper input[type=search]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=search].has-error:invalid + small,
.form-builder-wrapper input[type=number]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=number].has-error:invalid + small,
.form-builder-wrapper input[type=tel]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=tel].has-error:invalid + small,
.form-builder-wrapper input[type=email]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=email].has-error:invalid + small,
.form-builder-wrapper input[type=date]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=date].has-error:invalid + small,
.form-builder-wrapper input[type=datetime-local]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=datetime-local].has-error:invalid + small,
.form-builder-wrapper input[type=password]:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper input[type=password].has-error:invalid + small,
.form-builder-wrapper select:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper select.has-error:invalid + small,
.form-builder-wrapper textarea:not(:focus):not(:placeholder-shown):invalid + small,
.form-builder-wrapper textarea.has-error:invalid + small,
.card-form-container input[type=text]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=text].has-error:invalid + small,
.card-form-container input[type=search]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=search].has-error:invalid + small,
.card-form-container input[type=number]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=number].has-error:invalid + small,
.card-form-container input[type=tel]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=tel].has-error:invalid + small,
.card-form-container input[type=email]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=email].has-error:invalid + small,
.card-form-container input[type=date]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=date].has-error:invalid + small,
.card-form-container input[type=datetime-local]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=datetime-local].has-error:invalid + small,
.card-form-container input[type=password]:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container input[type=password].has-error:invalid + small,
.card-form-container select:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container select.has-error:invalid + small,
.card-form-container textarea:not(:focus):not(:placeholder-shown):invalid + small,
.card-form-container textarea.has-error:invalid + small {
  color: #B83D3D;
}
.form-container .invalid-feedback,
.form-builder-wrapper .invalid-feedback,
.card-form-container .invalid-feedback {
  color: #B83D3D;
  text-transform: none;
}
.form-container input[type=date],
.form-container input[type=datetime-local],
.form-builder-wrapper input[type=date],
.form-builder-wrapper input[type=datetime-local],
.card-form-container input[type=date],
.card-form-container input[type=datetime-local] {
  padding-top: 0.5333333333rem;
  padding-bottom: 0.6rem;
}
.form-container textarea,
.form-builder-wrapper textarea,
.card-form-container textarea {
  min-height: 8em;
}
.form-container select,
.form-builder-wrapper select,
.card-form-container select {
  cursor: pointer;
}
.form-container select, .form-container select:focus,
.form-builder-wrapper select,
.form-builder-wrapper select:focus,
.card-form-container select,
.card-form-container select:focus {
  appearance: none;
  background-image: svg-load("caret-down.svg", fill=#000);
  background-size: 0.5333333333em auto;
  background-repeat: no-repeat;
  background-position: right 1.3333333333em center;
}
.form-container .form-label,
.form-builder-wrapper .form-label,
.card-form-container .form-label {
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  margin-bottom: 1em;
}
.form-container .form-check,
.form-builder-wrapper .form-check,
.card-form-container .form-check {
  display: flex;
  margin-bottom: 1em;
}
.form-container .form-check > *,
.form-builder-wrapper .form-check > *,
.card-form-container .form-check > * {
  margin-top: 0;
  margin-bottom: 0;
}
.form-container input[type=checkbox],
.form-container input[type=radio],
.form-builder-wrapper input[type=checkbox],
.form-builder-wrapper input[type=radio],
.card-form-container input[type=checkbox],
.card-form-container input[type=radio] {
  appearance: none;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 1.4666666667em;
  box-sizing: border-box;
  vertical-align: top;
  width: 1.4666666667em;
  height: 1.4666666667em;
  margin-right: 1em;
  border: 1px solid #E0E0E0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.form-container input[type=checkbox],
.form-builder-wrapper input[type=checkbox],
.card-form-container input[type=checkbox] {
  border-radius: 0.3333333333em;
  background-size: 0.6666666667em auto;
}
.form-container input[type=checkbox]:checked,
.form-builder-wrapper input[type=checkbox]:checked,
.card-form-container input[type=checkbox]:checked {
  background-color: #6ABBF3;
  border-color: #6ABBF3;
  background-image: svg-load("check.svg", fill=#fff);
}
.form-container input[type=radio],
.form-builder-wrapper input[type=radio],
.card-form-container input[type=radio] {
  border-radius: 50%;
  background-size: 0.6666666667em 0.6666666667em;
  background-image: svg-load("solid/circle.svg", fill=#eee);
}
.form-container input[type=radio]:checked,
.form-builder-wrapper input[type=radio]:checked,
.card-form-container input[type=radio]:checked {
  background-image: svg-load("solid/circle.svg", fill=#6ABBF3);
}
.form-container input.search-keyword,
.form-builder-wrapper input.search-keyword,
.card-form-container input.search-keyword {
  background-size: 1.0666666667em 1.0666666667em;
  background-repeat: no-repeat;
  background-position: right 1.3333333333em center;
  background-image: svg-load("search.svg", fill=#000);
}
.form-container button.btn, .form-container button.footer-newsletter-small--submit, .form-container button.cta-search--submit, .form-container button.search-overlay--submit,
.form-builder-wrapper button.btn,
.form-builder-wrapper button.footer-newsletter-small--submit,
.form-builder-wrapper button.cta-search--submit,
.form-builder-wrapper button.search-overlay--submit,
.card-form-container button.btn,
.card-form-container button.footer-newsletter-small--submit,
.card-form-container button.cta-search--submit,
.card-form-container button.search-overlay--submit {
  cursor: pointer;
}

.form-captcha img {
  margin-bottom: 1em;
}

.form-infotext-scroll {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 1.3333333333rem;
  position: relative;
}
.form-infotext-scroll::before, .form-infotext-scroll::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 1.2rem;
  z-index: 1;
}
.form-infotext-scroll:before {
  top: 0;
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
}
.form-infotext-scroll:after {
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
}
.form-infotext-scroll--content {
  color: #757575;
  padding: 1.3333333333rem;
  width: 100%;
  max-height: min(50vh, 300px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.form-infotext-scroll--content > *:first-child {
  margin-top: 0;
}
.form-infotext-scroll--content > *:last-child {
  margin-bottom: 0;
}
.form-infotext-scroll--content ul {
  padding-left: 1rem;
}
.form-infotext-scroll--content ul > li::marker {
  color: currentColor;
}
.form-infotext-scroll--content h1, .form-infotext-scroll--content h2, .form-infotext-scroll--content h3, .form-infotext-scroll--content h4, .form-infotext-scroll--content h5, .form-infotext-scroll--content h6,
.form-infotext-scroll--content .h1, .form-infotext-scroll--content .h2, .form-infotext-scroll--content .h3, .form-infotext-scroll--content .h4, .form-infotext-scroll--content .area--thumbnail--caption, .form-infotext-scroll--content .cart-big--item--title--headline, .form-infotext-scroll--content .h5, .form-infotext-scroll--content .h6 {
  font-size: 1em;
  line-height: 1.2;
  color: currentColor;
  text-align: left;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 1em;
  margin-top: 1em;
}

/**
 *
 *
 * Optimizations for pimcore-formbuilder
 * https://github.com/dachcom-digital/pimcore-formbuilder
 *
 * @todo: check if needed
 *
 */
input[name$="[inputUserName]"] {
  display: none;
}

html:not(.form-builder-rec3-available) .grecaptcha-badge {
  visibility: hidden;
}

.required .invalid-feedback:before {
  content: " *";
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}

.video-controls {
  position: relative;
}

.video-controls--playpause {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  max-width: 80px;
  border: none;
  background-color: #C61932;
  opacity: 0.9;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.3s;
  background-image: svg-load("play.svg", fill=#fff);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px auto;
}
.video-controls--playpause[data-state=pause] {
  opacity: 0;
  background-image: svg-load("pause.svg", fill=#fff);
}
.video-controls:hover .video-controls--playpause[data-state=pause] {
  opacity: 0.9;
}
.video-controls--playpause::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.slide-btns {
  display: flex;
  align-items: center;
}

.slide-btn--prev, .slide-btn--next {
  display: block;
  font-size: 1rem;
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 50%;
  text-indent: -9999em;
  color: #003770;
  border: 1px solid currentColor;
  background-color: currentColor;
  background-size: 21% auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  margin: 0;
}
.slide-btn--prev[disabled], .slide-btn--next[disabled] {
  opacity: 0.2;
  cursor: not-allowed;
}
.slide-btn--prev {
  background-image: svg-load("angle-left.svg", fill=#fff);
}
.slide-btn--next {
  background-image: svg-load("angle-right.svg", fill=#fff);
}

.slide-btn--prev + .slide-btn--next,
.slide-btn--next + .slide-btn--prev {
  margin-left: 2em;
}

.area.area--course-filter {
  margin-bottom: 2.6666666667rem;
}

.area--course-filter + .area {
  margin-top: 2.6666666667rem;
}

.course-filter {
  background-color: #F0F5FB;
  border: 1px solid #D8E2ED;
  border-radius: 20px;
  overflow: hidden;
}
.course-filter h4, .course-filter .h4, .course-filter .area--thumbnail--caption, .course-filter .cart-big--item--title--headline {
  color: #003770;
  text-transform: uppercase;
}
.course-filter label, .course-filter .label {
  color: #003770;
}
.course-filter--header {
  padding: 1.3333333333rem 2.9333333333rem 1.3333333333rem 1.3333333333rem;
  list-style: none;
  background-size: 1.5333333333em 1.5333333333em;
  background-repeat: no-repeat;
  background-position: right 1.3333333333em center;
  background-image: svg-load("filter.svg", fill=#003770);
}
.course-filter--header::marker, .course-filter--header::-webkit-details-marker {
  display: none;
}
.course-filter[open] .course-filter--header {
  background-size: 1.5333333333em 1.5333333333em;
  background-image: svg-load("close-custom.svg", fill=#003770);
}
.course-filter--content {
  padding: 2rem;
  padding-top: 0.6666666667rem;
}

.course-filter--content input[type=checkbox],
.course-filter--content input[type=radio] {
  background-color: #fff;
}
@media (min-width: 576px) {
  .course-filter--content .form-check {
    display: inline-flex;
    margin-left: 1em;
  }
  .course-filter--content .form-check:first-of-type {
    margin-left: 0;
  }
}
.course-filter--content .form-buttons {
  flex-wrap: wrap-reverse;
  justify-content: center;
}
.course-filter--content .form-buttons .btn, .course-filter--content .form-buttons .footer-newsletter-small--submit, .course-filter--content .form-buttons .cta-search--submit, .course-filter--content .form-buttons .search-overlay--submit {
  margin-left: auto !important;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .course-filter--content .form-buttons {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .course-filter--content .form-buttons .btn, .course-filter--content .form-buttons .footer-newsletter-small--submit, .course-filter--content .form-buttons .cta-search--submit, .course-filter--content .form-buttons .search-overlay--submit {
    width: auto;
    margin-left: 0.6666666667rem !important;
  }
}

@media (min-width: 992px) {
  .course-filter--cols {
    display: grid;
    grid-template-columns: 315px 1fr 190px;
    grid-template-areas: "keyword attributes buttons";
    grid-column-gap: 2rem;
  }
}
.course-filter--cols .course-filter--col-keyword {
  grid-area: keyword;
}
.course-filter--cols .course-filter--col-attributes {
  grid-area: attributes;
}
.course-filter--cols .course-filter--col-buttons {
  grid-area: buttons;
}

.area--search-result > *:first-child {
  margin-top: 0;
}
.area--search-result > *:last-child {
  margin-bottom: 0;
}

.search-result {
  border-radius: 1.3333333333rem;
  padding: 1.3333333333rem;
  color: #000;
  background-color: #F0F5FB;
  border-color: #F0F5FB;
  margin-top: 0.6666666667rem;
  margin-bottom: 0.6666666667rem;
}
@media (min-width: 576px) {
  .search-result {
    padding: 2rem;
  }
}
.search-result:focus-within {
  outline: 3px solid #6ABBF3;
}
.search-result > a {
  text-decoration: none;
  color: currentColor;
}
.search-result > a:hover, .search-result > a:focus {
  color: currentColor;
  text-decoration: underline;
}
.search-result--headline {
  color: #003770;
  margin-bottom: 0.3333333333rem;
  text-transform: none;
  text-decoration: none;
}
.search-result--url {
  font-size: 0.8rem;
  margin-bottom: 0.3333333333rem;
  text-decoration: underline;
}
.search-result--content > *:first-child {
  margin-top: 0;
}
.search-result--content > *:last-child {
  margin-bottom: 0;
}

.search-box {
  position: relative;
  max-width: 24rem;
}
.search-box input[type=text],
.search-box input[type=search] {
  padding: 0.6666666667rem 1.3333333333rem;
  padding-right: 6.6666666667rem;
  width: 100%;
  color: #000;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 1.3333333333rem;
  position: relative;
}
.search-box input[type=text]:focus,
.search-box input[type=search]:focus {
  outline: 0;
  color: #000;
  background-color: #fff;
  border-color: rgba(0, 55, 112, 0.5);
  box-shadow: 0 0 0 0.1333333333rem rgba(0, 55, 112, 0.5);
}
.search-box input[type=text][readonly], .search-box input[type=text][disabled],
.search-box input[type=search][readonly],
.search-box input[type=search][disabled] {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(224, 224, 224, 0.3);
  cursor: not-allowed;
}
.search-box input[type=text]:not(:focus):not(:placeholder-shown):invalid, .search-box input[type=text][required]:not(:focus):not(:placeholder-shown):invalid,
.search-box input[type=search]:not(:focus):not(:placeholder-shown):invalid,
.search-box input[type=search][required]:not(:focus):not(:placeholder-shown):invalid {
  border-color: #B83D3D;
}
.search-box--submit {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.checkout-section {
  position: relative;
}
.checkout-section::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  top: 0;
  height: 100%;
  border-left-style: dashed;
  border-left-color: rgba(0, 55, 112, 0.2);
}
.checkout-section::before {
  left: 22.5px;
}
@media screen and (min-width: 768px) {
  .checkout-section::before {
    left: calc(22.5px + 11.5 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section::before {
    left: 34px;
  }
}
.checkout-section::before {
  border-left-width: 1px;
}
@media screen and (min-width: 768px) {
  .checkout-section::before {
    border-left-width: calc(1px + 1 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section::before {
    border-left-width: 2px;
  }
}
.checkout-section:last-child::before {
  content: none;
  display: none;
}
.checkout-section .checkout-section--header,
.checkout-section .checkout-section--content {
  position: relative;
}

.checkout-section--header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding-bottom: 1rem;
  text-decoration: none;
}
.checkout-section--header {
  grid-gap: 10px;
}
@media screen and (min-width: 768px) {
  .checkout-section--header {
    grid-gap: calc(10px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--header {
    grid-gap: 30px;
  }
}

.checkout-section--indicator,
.checkout-section--content::before {
  display: block;
  font-style: normal;
  text-align: center;
  color: #fff;
  font-weight: 400;
  border-style: solid;
  border-color: #fff;
  border-radius: 50%;
  background-color: #003770;
}
.checkout-section--indicator,
.checkout-section--content::before {
  font-size: 1.2rem;
}
@media screen and (min-width: 768px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    font-size: calc(18px + 2 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    font-size: 1.3333333333rem;
  }
}
.checkout-section--indicator,
.checkout-section--content::before {
  width: 45px;
}
@media screen and (min-width: 768px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    width: calc(45px + 23 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    width: 68px;
  }
}
.checkout-section--indicator,
.checkout-section--content::before {
  height: 45px;
}
@media screen and (min-width: 768px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    height: calc(45px + 23 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    height: 68px;
  }
}
.checkout-section--indicator,
.checkout-section--content::before {
  border-width: 7.5px;
}
@media screen and (min-width: 768px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    border-width: calc(7.5px + 2.5 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    border-width: 10px;
  }
}
.checkout-section--indicator,
.checkout-section--content::before {
  line-height: 30px;
}
@media screen and (min-width: 768px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    line-height: calc(30px + 18 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--indicator,
.checkout-section--content::before {
    line-height: 48px;
  }
}
.checkout-section.active .checkout-section--indicator,
.checkout-section.active .checkout-section--content::before {
  background-color: #6ABBF3;
}
.checkout-section.done .checkout-section--indicator,
.checkout-section.done .checkout-section--content::before {
  background-color: #3BA738;
}

.checkout-section--content::before {
  content: none;
  opacity: 0;
  pointer-events: none;
  background-color: transparent;
  color: transparent;
  border-color: transparent;
}
@media (min-width: 992px) {
  .checkout-section--content::before {
    content: "";
  }
}

.checkout-section.done .checkout-section--headline {
  color: #ccc;
}

.checkout-section--content--inner {
  background-color: #fff;
  padding: 1rem 0;
}
.checkout-section--content {
  grid-gap: 10px;
}
@media screen and (min-width: 768px) {
  .checkout-section--content {
    grid-gap: calc(10px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .checkout-section--content {
    grid-gap: 30px;
  }
}
@media (min-width: 992px) {
  .checkout-section--content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: top;
  }
}

.cart-big .highlight-container--content {
  padding-top: 0;
}

@media (max-width: 1199.98px) {
  .cart-big--title .cart-big--col-date, .cart-big--title .cart-big--col-location, .cart-big--title .cart-big--col-price {
    display: none;
  }
}
@media (min-width: 1200px) {
  .cart-big--title .cart-big--col-price {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .cart-big--row {
    display: grid;
    grid-template-columns: auto min(25%, 230px) min(15%, 140px) min(20%, 180px);
    align-items: center;
  }
}

.cart-big--form .cart-big--form--additional-fields {
  margin-top: 1.3333333333rem;
  margin-bottom: 0;
}

.cart-big--sum {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-top: 1.3333333333rem;
}
.cart-big--sum--price {
  font-weight: 700;
  text-align: right;
}

.cart-big--item {
  position: relative;
  margin-top: 1.3333333333rem;
  padding-bottom: 1.3333333333rem;
}
.cart-big--item::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -1rem;
  right: -1rem;
  height: 1px;
  background-color: #eee;
}
@media (min-width: 576px) {
  .cart-big--item::before {
    left: -2.6666666667rem;
    right: -2.6666666667rem;
  }
}
@media (min-width: 1200px) {
  .cart-big--item--title, .cart-big--item--info, .cart-big--item--date, .cart-big--item--location, .cart-big--item--price {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.cart-big--item--title {
  margin-bottom: 0.6666666667rem;
}
@media (min-width: 1200px) {
  .cart-big--item--title {
    padding-right: 2rem;
  }
}
.cart-big--item--title--headline {
  margin-bottom: 0;
  color: #000;
  text-transform: none;
}
.cart-big--item--title--info {
  margin-bottom: 0;
}
.cart-big--item--date {
  margin-bottom: 0.6666666667rem;
}
.cart-big--item--location {
  margin-bottom: 0.6666666667rem;
}
.cart-big--item--price {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.cart-big-no-actions .cart-big--item--price {
  display: block;
}
.cart-big--item--price--value {
  font-weight: 700;
  white-space: nowrap;
}
.cart-big-no-actions .cart-big--item--price--value {
  text-align: right;
}
@media (min-width: 1200px) {
  .cart-big--item--price--value {
    text-align: right;
  }
}
.cart-big-no-actions .cart-big--item--price--actions {
  display: none;
}
.cart-big--item--price--actions .btn, .cart-big--item--price--actions .footer-newsletter-small--submit, .cart-big--item--price--actions .cta-search--submit, .cart-big--item--price--actions .search-overlay--submit {
  margin: 0;
  color: #ccc;
}
.cart-big--item--price--actions .btn:hover, .cart-big--item--price--actions .footer-newsletter-small--submit:hover, .cart-big--item--price--actions .cta-search--submit:hover, .cart-big--item--price--actions .search-overlay--submit:hover, .cart-big--item--price--actions .btn:focus, .cart-big--item--price--actions .footer-newsletter-small--submit:focus, .cart-big--item--price--actions .cta-search--submit:focus, .cart-big--item--price--actions .search-overlay--submit:focus {
  color: #000;
}

@media (min-width: 768px) {
  .checkout-summary--data {
    display: grid;
    grid-template-columns: min(50%, 350px) 1fr;
  }
}
@media (min-width: 992px) {
  .checkout-summary--data.checkout-summary--data-with-actions {
    grid-template-columns: min(50%, 350px) 1fr auto;
  }
}

.checkout-summary-data--actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.6666666667rem;
}
.checkout-summary-data--actions .btn, .checkout-summary-data--actions .footer-newsletter-small--submit, .checkout-summary-data--actions .cta-search--submit, .checkout-summary-data--actions .search-overlay--submit {
  margin: 0;
  color: #ccc;
}
.checkout-summary-data--actions .btn:hover, .checkout-summary-data--actions .footer-newsletter-small--submit:hover, .checkout-summary-data--actions .cta-search--submit:hover, .checkout-summary-data--actions .search-overlay--submit:hover, .checkout-summary-data--actions .btn:focus, .checkout-summary-data--actions .footer-newsletter-small--submit:focus, .checkout-summary-data--actions .cta-search--submit:focus, .checkout-summary-data--actions .search-overlay--submit:focus {
  color: #000;
}
@media (min-width: 768px) {
  .checkout-summary-data--actions {
    grid-column: 1/span 2;
  }
}
@media (min-width: 992px) {
  .checkout-summary-data--actions {
    grid-column: auto;
    align-self: self-end;
    margin-top: 0;
  }
}

.checkout-summary-data--table th {
  font-weight: 700;
  width: 8.6666666667rem;
}
@media (min-width: 1200px) {
  .checkout-summary-data--table th {
    width: 10.6666666667rem;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s;
}
.modal-container-visible .modal-container {
  display: flex;
  pointer-events: all;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  display: block;
  font-size: 1rem;
  border-radius: 1.3333333333rem;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 80vw;
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}
.modal--content {
  position: relative;
  overflow: hidden;
  border-radius: 1.3333333333rem;
  padding: 2rem;
  text-align: center;
}
.modal--header {
  font-weight: 300;
  color: #000;
  margin-bottom: 0;
}
.modal--body {
  margin-top: 1rem;
}
.modal .modal-close {
  position: absolute;
  top: 0;
  right: 0;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 20vh;
  overflow: hidden;
  display: flex;
  max-height: 0;
  opacity: 0;
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  pointer-events: none;
}
.search-overlay.show {
  opacity: 1;
  max-height: 100%;
  pointer-events: all;
}
@media (min-width: 992px) {
  .search-overlay {
    padding-top: 0;
    align-items: center;
  }
}
.search-overlay--content {
  max-width: 42.6666666667rem;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.search-overlay--fieldset {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  grid-template-areas: "inputField submitButton";
  align-items: center;
  border-radius: 1.3333333333em;
  overflow: hidden;
}
.search-overlay--fieldset {
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .search-overlay--fieldset {
    font-size: calc(15px + 10 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .search-overlay--fieldset {
    font-size: 1.6666666667rem;
  }
}
.search-overlay--field, .search-overlay--submit {
  display: block;
  margin: 0;
  border: 1px solid #fff;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
.search-overlay--field {
  grid-area: inputField;
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 1.3333333333em;
  font-size: 0.8em;
}
.search-overlay--submit {
  font-size: 0.8em;
  grid-area: submitButton;
  color: #fff;
  background-color: #6ABBF3;
  border-color: #6ABBF3;
  border-radius: 0;
}

/*** IMPORT LAYOUT ***/
html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  width: 100%;
  font-weight: 300;
}
@media (max-width: 767.98px) {
  .offcanvas-nav--visible body {
    overflow-y: hidden;
  }
}

::selection {
  background: #003770;
  color: #fff;
}

.page-navigation {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .page-navigation {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1600px) {
  .page-navigation {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.page-navigation--container {
  display: grid;
  grid-template-columns: 3.6666666667em 1fr auto auto;
  grid-template-rows: 1fr;
  grid-template-areas: "mainNavToggle logo actionButtonCart";
  align-items: center;
}
@media (min-width: 1200px) {
  .page-navigation--container {
    grid-template-columns: auto 1fr auto auto;
    grid-template-areas: "logo mainNav actionButtonSearch actionButtonCart";
    grid-gap: 0.6666666667em;
  }
}
@media (min-width: 1600px) {
  .page-navigation--container {
    grid-gap: 1.3333333333em;
  }
}
.page-navigation--container .logo {
  justify-self: center;
  grid-area: logo;
  width: 8em;
  padding: 0.6em 0.6666666667em;
}
@media (min-width: 1200px) {
  .page-navigation--container .logo {
    padding: 1em 0;
    width: 8.5333333333em;
  }
}
.page-navigation--container .logo .logo-svg--claim {
  display: none;
}
.frontpage .page-navigation--container .logo .logo-svg--claim {
  display: block;
}
.landingpage-without-mainnav .page-navigation--container {
  grid-template-columns: 3.6666666667em 1fr auto;
  grid-template-areas: "spacer logo actionButtonCart";
}
.landingpage-without-mainnav .page-navigation--container > * {
  display: none !important;
}
.landingpage-without-mainnav .page-navigation--container .logo {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}
.landingpage-without-mainnav .page-navigation--container .header-action-button--btn-cart {
  display: block !important;
}
.page-navigation--container .main-nav--toggle {
  grid-area: mainNavToggle;
}
.page-navigation--container .header-action-button--btn-cart {
  grid-area: actionButtonCart;
}
.page-navigation--container .header-action-button--btn-search {
  grid-area: actionButtonSearch;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
#toggleMobileNav:checked ~ .page-wrap .page-navigation--container .header-action-button--btn-search {
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .page-navigation--container .header-action-button--btn-search {
    position: relative;
    bottom: auto;
    right: auto;
  }
}

.main-nav-wrap {
  grid-area: mainNav;
  justify-self: center;
  background-color: #F0F5FB;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(100vh - 100%);
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  max-width: 0;
  transition: max-width 0.3s;
  transition-timing-function: ease-in-out;
  will-change: max-width;
}
#toggleMobileNav:checked ~ .page-wrap .main-nav-wrap {
  max-width: 100vw;
}
@media (min-width: 768px) {
  #toggleMobileNav:checked ~ .page-wrap .main-nav-wrap {
    max-width: 30rem;
  }
}
@media (min-width: 1200px) {
  .main-nav-wrap {
    background-color: transparent;
    max-width: none;
    position: static;
    top: auto;
    left: auto;
    overflow-x: unset;
    overflow-y: unset;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.main-nav-wrap .main-nav {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
}

.page-header {
  position: relative;
}
.subpage .page-header {
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 50%;
  background-position: top left;
  background-repeat: no-repeat;
  color: #fff;
}
.page--courses-detail .page-header {
  background-size: 100% 100%;
}

.page-hero--entry {
  position: relative;
}
.page-hero--entry--media {
  position: relative;
  border-radius: 20px;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  background-color: #000;
  overflow: hidden;
}
.page-hero--entry--media::before {
  content: "";
  display: block;
  overflow: hidden;
  height: 67vw;
  max-height: 70vh;
}
@media (min-width: 768px) {
  .page-hero--entry--media::before {
    height: 0;
    padding-top: 43.8596491228%;
  }
}
.page-hero--entry--media > .page-hero--entry--media--item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}
.page-hero--entry--media picture, .page-hero--entry--media img, .page-hero--entry--media video,
.page-hero--entry--media .pimcore_editable_video {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.page-hero--entry--media .pimcore_editable_video_progress img {
  width: 100% !important;
  height: 100% !important;
}
.page-hero--entry--content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.3333333333rem 2rem;
  color: #fff;
}
@media (min-width: 768px) {
  .page-hero--entry--content {
    padding: 2.6666666667rem 4rem;
  }
}
.page-hero--entry--content h1, .page-hero--entry--content h2, .page-hero--entry--content h3, .page-hero--entry--content h4, .page-hero--entry--content h5, .page-hero--entry--content h6,
.page-hero--entry--content .h1, .page-hero--entry--content .h2, .page-hero--entry--content .h3, .page-hero--entry--content .h4, .page-hero--entry--content .area--thumbnail--caption, .page-hero--entry--content .cart-big--item--title--headline, .page-hero--entry--content .h5, .page-hero--entry--content .h6 {
  color: inherit;
  margin: 0;
  text-transform: uppercase;
}
.page-hero--entry--content h1 small, .page-hero--entry--content h2 small, .page-hero--entry--content h3 small, .page-hero--entry--content h4 small, .page-hero--entry--content h5 small, .page-hero--entry--content h6 small,
.page-hero--entry--content .h1 small, .page-hero--entry--content .h2 small, .page-hero--entry--content .h3 small, .page-hero--entry--content .h4 small, .page-hero--entry--content .area--thumbnail--caption small, .page-hero--entry--content .cart-big--item--title--headline small, .page-hero--entry--content .h5 small, .page-hero--entry--content .h6 small {
  color: inherit;
  text-transform: none;
}
.page-hero--entry--content h1 small, .page-hero--entry--content h2 small, .page-hero--entry--content h3 small, .page-hero--entry--content h4 small, .page-hero--entry--content h5 small, .page-hero--entry--content h6 small,
.page-hero--entry--content .h1 small, .page-hero--entry--content .h2 small, .page-hero--entry--content .h3 small, .page-hero--entry--content .h4 small, .page-hero--entry--content .area--thumbnail--caption small, .page-hero--entry--content .cart-big--item--title--headline small, .page-hero--entry--content .h5 small, .page-hero--entry--content .h6 small {
  font-size: 0.8rem;
}
@media screen and (min-width: 768px) {
  .page-hero--entry--content h1 small, .page-hero--entry--content h2 small, .page-hero--entry--content h3 small, .page-hero--entry--content h4 small, .page-hero--entry--content h5 small, .page-hero--entry--content h6 small,
.page-hero--entry--content .h1 small, .page-hero--entry--content .h2 small, .page-hero--entry--content .h3 small, .page-hero--entry--content .h4 small, .page-hero--entry--content .area--thumbnail--caption small, .page-hero--entry--content .cart-big--item--title--headline small, .page-hero--entry--content .h5 small, .page-hero--entry--content .h6 small {
    font-size: calc(12px + 6 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .page-hero--entry--content h1 small, .page-hero--entry--content h2 small, .page-hero--entry--content h3 small, .page-hero--entry--content h4 small, .page-hero--entry--content h5 small, .page-hero--entry--content h6 small,
.page-hero--entry--content .h1 small, .page-hero--entry--content .h2 small, .page-hero--entry--content .h3 small, .page-hero--entry--content .h4 small, .page-hero--entry--content .area--thumbnail--caption small, .page-hero--entry--content .cart-big--item--title--headline small, .page-hero--entry--content .h5 small, .page-hero--entry--content .h6 small {
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
  .page-hero--entry--content h1, .page-hero--entry--content h2, .page-hero--entry--content h3, .page-hero--entry--content h4, .page-hero--entry--content h5, .page-hero--entry--content h6,
.page-hero--entry--content .h1, .page-hero--entry--content .h2, .page-hero--entry--content .h3, .page-hero--entry--content .h4, .page-hero--entry--content .area--thumbnail--caption, .page-hero--entry--content .cart-big--item--title--headline, .page-hero--entry--content .h5, .page-hero--entry--content .h6 {
    hyphens: none;
  }
}
.page-hero--entry--content p {
  margin: 0.6666666667rem 0 0 0;
  font-weight: 700;
}
.page-hero--entry--content--buttons {
  margin-top: 1em;
}
.page-hero--entry--content--buttons .btn, .page-hero--entry--content--buttons .footer-newsletter-small--submit, .page-hero--entry--content--buttons .cta-search--submit, .page-hero--entry--content--buttons .search-overlay--submit {
  margin-top: 0;
  margin-bottom: 0;
}
.page-hero--entry--content .btn-outline,
.page-hero--entry--content .btn-text {
  color: #fff;
}
.page-hero--entry--content .btn-outline {
  box-shadow: none !important;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition-timing-function: ease-in-out;
  will-change: color, background-color, border-color;
}
.page-hero--entry--content .btn-outline:hover, .page-hero--entry--content .btn-outline:focus {
  color: #fff;
  border-color: #C61932;
  background-color: #C61932;
}

.page-hero--entry--content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}
.page-hero--entry--content.v-align-top {
  align-items: flex-start;
}
.page-hero--entry--content.v-align-center {
  align-items: center;
}
.page-hero--entry--content.v-align-bottom {
  align-items: flex-end;
}
.page-hero--entry--content.h-align-left {
  justify-content: flex-start;
  text-align: left;
}
.page-hero--entry--content.h-align-center {
  justify-content: center;
  text-align: center;
}
.page-hero--entry--content.h-align-right {
  justify-content: flex-end;
  text-align: right;
}
.page-hero--entry--content.v-align-top--xs {
  align-items: flex-start;
}
.page-hero--entry--content.v-align-center--xs {
  align-items: center;
}
.page-hero--entry--content.v-align-bottom--xs {
  align-items: flex-end;
}
.page-hero--entry--content.h-align-left--xs {
  justify-content: flex-start;
  text-align: left;
}
.page-hero--entry--content.h-align-center--xs {
  justify-content: center;
  text-align: center;
}
.page-hero--entry--content.h-align-right--xs {
  justify-content: flex-end;
  text-align: right;
}
@media (min-width: 576px) {
  .page-hero--entry--content.v-align-top--sm {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--sm {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--sm {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--sm {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--sm {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--sm {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 768px) {
  .page-hero--entry--content.v-align-top--md {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--md {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--md {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--md {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--md {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--md {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 992px) {
  .page-hero--entry--content.v-align-top--lg {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--lg {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--lg {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--lg {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--lg {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--lg {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .page-hero--entry--content.v-align-top--xl {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--xl {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--xl {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--xl {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--xl {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--xl {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 1400px) {
  .page-hero--entry--content.v-align-top--xxl {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--xxl {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--xxl {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--xxl {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--xxl {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--xxl {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 1600px) {
  .page-hero--entry--content.v-align-top--xxxl {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--xxxl {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--xxxl {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--xxxl {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--xxxl {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--xxxl {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 1920px) {
  .page-hero--entry--content.v-align-top--outer {
    align-items: flex-start;
  }
  .page-hero--entry--content.v-align-center--outer {
    align-items: center;
  }
  .page-hero--entry--content.v-align-bottom--outer {
    align-items: flex-end;
  }
  .page-hero--entry--content.h-align-left--outer {
    justify-content: flex-start;
    text-align: left;
  }
  .page-hero--entry--content.h-align-center--outer {
    justify-content: center;
    text-align: center;
  }
  .page-hero--entry--content.h-align-right--outer {
    justify-content: flex-end;
    text-align: right;
  }
}

.page-hero--big .page-hero--entry--media {
  border-radius: 0;
  box-shadow: none;
}
.page-hero--big .page-hero--entry--media::before {
  height: 130vw;
  max-height: 70vh;
  min-height: 20rem;
}
@media (min-width: 768px) {
  .page-hero--big .page-hero--entry--media::before {
    height: 60vw;
  }
}
@media (min-width: 1200px) {
  .page-hero--big .page-hero--entry--media::before {
    height: 0;
    padding-top: 37.5%;
    min-height: 720px;
  }
}
.page-hero--big .page-hero--entry--content {
  bottom: 4.6666666667rem;
}
.page-hero--big .page-hero--entry--content--inner {
  max-width: 70vw;
}
@media (min-width: 576px) {
  .page-hero--big .page-hero--entry--content--inner {
    max-width: 60vw;
  }
}
@media (min-width: 768px) {
  .page-hero--big .page-hero--entry--content--inner {
    max-width: 50vw;
  }
}
@media (min-width: 1200px) {
  .page-hero--big .page-hero--entry--content--inner {
    max-width: 40vw;
  }
}
@media (min-width: 1400px) {
  .page-hero--big .page-hero--entry--content--inner {
    max-width: 44vw;
  }
}
@media (min-width: 1600px) {
  .page-hero--big .page-hero--entry--content--inner {
    max-width: 51.3333333333rem;
  }
}
.page-hero--big .page-hero--entry--content h1, .page-hero--big .page-hero--entry--content .h1 {
  color: inherit;
  margin: 0;
  text-transform: none;
}
.page-hero--big .page-hero--entry--content h1, .page-hero--big .page-hero--entry--content .h1 {
  font-size: 1.6666666667rem;
}
@media screen and (min-width: 768px) {
  .page-hero--big .page-hero--entry--content h1, .page-hero--big .page-hero--entry--content .h1 {
    font-size: calc(25px + 35 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .page-hero--big .page-hero--entry--content h1, .page-hero--big .page-hero--entry--content .h1 {
    font-size: 4rem;
  }
}
.page-hero--big .page-hero--entry--content--inner {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.page-header--tools {
  padding: 1.3333333333rem 0;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
}
@media (min-width: 768px) {
  .page-header--tools {
    padding: 2.6666666667rem 0;
  }
}
.page-header--tools--inner {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "breadcrumb share";
  align-items: center;
}

.page-header--tools .breadcrumb {
  grid-area: breadcrumb;
}
.page-header--tools .breadcrumb--list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.page-header--tools .breadcrumb--list li {
  all: initial;
  display: inline-flex;
  align-items: center;
  margin-right: 1.3333333333rem;
  color: inherit;
  font-family: inherit;
  font-weight: 400;
  font-size: 0.8rem;
}
@media (min-width: 768px) {
  .page-header--tools .breadcrumb--list li {
    font-size: 1rem;
  }
}
.page-header--tools .breadcrumb--list li::before {
  all: initial;
  content: "";
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  width: 0.4rem;
  height: 0.6666666667rem;
  margin-right: 0.6666666667rem;
  background-image: svg-load("angle-right.svg", fill=#fff);
  background-size: contain;
  background-repeat: no-repeat;
}
.page-header--tools .breadcrumb--list li:first-child::before {
  display: none;
}
@media (max-width: 575.98px) {
  .page-header--tools .breadcrumb--list li {
    display: none;
  }
  .page-header--tools .breadcrumb--list li:nth-last-child(2) {
    display: inline-flex;
  }
  .page-header--tools .breadcrumb--list li:nth-last-child(2)::before {
    transform: rotate(180deg);
  }
}
.page-header--tools .breadcrumb--list a {
  text-decoration: none;
}
.page-header--tools .breadcrumb--list a:hover, .page-header--tools .breadcrumb--list a:focus {
  color: inherit;
  text-decoration: underline;
}

.share-action {
  grid-area: share;
  text-align: right;
  position: relative;
}

.share-action--btn {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-weight: 400;
  font-size: 0.8rem;
  cursor: pointer;
}
@media (min-width: 768px) {
  .share-action--btn {
    font-size: 1rem;
  }
}
.share-action--btn::before {
  display: block;
  content: "";
  width: 1em;
  height: 1em;
  line-height: 1em;
  margin-right: 0.2em;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: currentColor;
  mask-image: svg-load("share.svg");
}
@media (min-width: 768px) {
  .share-action--btn::before {
    margin-right: 0.6em;
  }
}

.share-action--dialog {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 9.1333333333rem;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
}
.share-action.open .share-action--dialog {
  display: block;
}
.share-action--dialog--head {
  position: relative;
}
.share-action--dialog--head--label {
  text-align: left;
  color: #003770;
  font-weight: 700;
  padding: 0.6666666667rem 1rem 0 1rem;
}
.share-action--dialog--head--close {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 0;
  width: 2.6666666667rem;
  font-size: 2rem;
  background: transparent;
  cursor: pointer;
}
.share-action--dialog--head--close i {
  opacity: 0.7;
}
@media (min-width: 992px) {
  .share-action--dialog--head--close:hover i, .share-action--dialog--head--close:focus i {
    opacity: 1;
  }
}
.share-action--dialog ul {
  margin: 0;
  list-style: none;
  padding: 0.4666666667rem 0 0.2rem 0;
}
.share-action--dialog li {
  list-style: none;
  display: block;
}
.share-action--dialog .btn, .share-action--dialog .footer-newsletter-small--submit, .share-action--dialog .cta-search--submit, .share-action--dialog .search-overlay--submit {
  display: flex;
  justify-content: flex-start;
  min-height: 0;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  padding: 0.4666666667rem 1rem;
}
.share-action--dialog .btn i, .share-action--dialog .footer-newsletter-small--submit i, .share-action--dialog .cta-search--submit i, .share-action--dialog .search-overlay--submit i {
  font-size: 1.5em;
}

@media (min-width: 768px) {
  .page-header--cta--inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1260px;
  }
}
@media (min-width: 1200px) {
  .page-header--cta--inner-wrap {
    position: relative;
    height: 6.6666666667rem;
  }
  .page-header--cta--inner-wrap::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 42.6666666667rem;
    padding-top: 3.125%;
    background-image: svg-load("layout/svg/cta-search-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 100%;
    transform: translateY(-98%);
    transform: translateY(calc(-100% + 2px));
    pointer-events: none;
    z-index: 0;
  }
  .page-header--cta-no-search .page-header--cta--inner-wrap::before {
    display: none;
  }
}

.page-header--cta--badge {
  grid-area: ctaBadge;
  position: absolute;
  top: 1.6666666667rem;
  right: 1rem;
}
@media (min-width: 1200px) {
  .page-header--cta--badge {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
  }
  .landingpage-without-mainnav .page-header--cta--badge {
    left: auto;
    right: 0;
  }
}

.page-header--cta--search {
  grid-area: ctaSearch;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 38.6666666667rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .page-header--cta--search {
    max-width: 42.6666666667rem;
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (min-width: 1200px) {
  .page-header--cta--search {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .page-header--cta--search::before {
    display: none;
  }
}
.page-header--cta--search::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 5.3333333333%;
  background-image: svg-load("layout/svg/cta-search-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 100%;
  transform: translateY(-98%);
  pointer-events: none;
  z-index: 0;
}
.page-header--cta--search::after {
  content: "";
  display: block;
  width: 100%;
  height: 1em;
  position: relative;
  top: -2rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  background-color: #000;
  opacity: 0.3;
  filter: blur(1em);
}
@media (min-width: 1200px) {
  .page-header--cta--search::after {
    top: auto;
    bottom: -1rem;
  }
}
.page-header--cta--search > .cta-search {
  transform: translateY(var(--ctaSearchTranslateYBase));
}
:root {
  --ctaSearchTranslateYBase: -70px;
  --ctaSearchTranslateYHover: -77px;
}
@media (min-width: 1200px) {
  :root {
    --ctaSearchTranslateYBase: 0px;
    --ctaSearchTranslateYHover: -7px;
  }
}

@media (min-width: 992px) {
  .page-header--cta--search::after,
.page-header--cta--search > .cta-search {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  .page-header--cta--search::after {
    animation-name: animation-ctaSearch-shadow;
  }
  .page-header--cta--search > .cta-search {
    animation-name: animation-ctaSearch-floating;
  }
  .page-header--cta--search:focus-within::after {
    animation: none;
  }
  .page-header--cta--search:focus-within > .cta-search {
    animation: none;
  }
}

.cta-search {
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
  color: #fff;
  border-radius: 20px;
  overflow: hidden;
  padding: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .cta-search {
    padding: 5.3333333333rem;
    text-align: left;
  }
}
.cta-search h1, .cta-search h2, .cta-search h3, .cta-search h4, .cta-search h5, .cta-search h6,
.cta-search .h1, .cta-search .h2, .cta-search .h3, .cta-search .h4, .cta-search .area--thumbnail--caption, .cta-search .cart-big--item--title--headline, .cta-search .h5, .cta-search .h6 {
  color: inherit;
  margin: 0;
  text-transform: uppercase;
}
.cta-search h1 small, .cta-search h1 .small, .cta-search h1 .form-container .invalid-feedback, .form-container .cta-search h1 .invalid-feedback,
.cta-search h1 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h1 .invalid-feedback,
.cta-search h1 .card-form-container .invalid-feedback,
.card-form-container .cta-search h1 .invalid-feedback, .cta-search h1 .form-infotext-scroll--content, .cta-search h1 .cart-big--item--title--info, .cta-search h2 small, .cta-search h2 .small, .cta-search h2 .form-container .invalid-feedback, .form-container .cta-search h2 .invalid-feedback,
.cta-search h2 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h2 .invalid-feedback,
.cta-search h2 .card-form-container .invalid-feedback,
.card-form-container .cta-search h2 .invalid-feedback, .cta-search h2 .form-infotext-scroll--content, .cta-search h2 .cart-big--item--title--info, .cta-search h3 small, .cta-search h3 .small, .cta-search h3 .form-container .invalid-feedback, .form-container .cta-search h3 .invalid-feedback,
.cta-search h3 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h3 .invalid-feedback,
.cta-search h3 .card-form-container .invalid-feedback,
.card-form-container .cta-search h3 .invalid-feedback, .cta-search h3 .form-infotext-scroll--content, .cta-search h3 .cart-big--item--title--info, .cta-search h4 small, .cta-search h4 .small, .cta-search h4 .form-container .invalid-feedback, .form-container .cta-search h4 .invalid-feedback,
.cta-search h4 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h4 .invalid-feedback,
.cta-search h4 .card-form-container .invalid-feedback,
.card-form-container .cta-search h4 .invalid-feedback, .cta-search h4 .form-infotext-scroll--content, .cta-search h4 .cart-big--item--title--info, .cta-search h5 small, .cta-search h5 .small, .cta-search h5 .form-container .invalid-feedback, .form-container .cta-search h5 .invalid-feedback,
.cta-search h5 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h5 .invalid-feedback,
.cta-search h5 .card-form-container .invalid-feedback,
.card-form-container .cta-search h5 .invalid-feedback, .cta-search h5 .form-infotext-scroll--content, .cta-search h5 .cart-big--item--title--info, .cta-search h6 small, .cta-search h6 .small, .cta-search h6 .form-container .invalid-feedback, .form-container .cta-search h6 .invalid-feedback,
.cta-search h6 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h6 .invalid-feedback,
.cta-search h6 .card-form-container .invalid-feedback,
.card-form-container .cta-search h6 .invalid-feedback, .cta-search h6 .form-infotext-scroll--content, .cta-search h6 .cart-big--item--title--info,
.cta-search .h1 small,
.cta-search .h1 .small,
.cta-search .h1 .form-container .invalid-feedback,
.form-container .cta-search .h1 .invalid-feedback,
.cta-search .h1 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h1 .invalid-feedback,
.cta-search .h1 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h1 .invalid-feedback,
.cta-search .h1 .form-infotext-scroll--content,
.cta-search .h1 .cart-big--item--title--info, .cta-search .h2 small, .cta-search .h2 .small, .cta-search .h2 .form-container .invalid-feedback, .form-container .cta-search .h2 .invalid-feedback,
.cta-search .h2 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h2 .invalid-feedback,
.cta-search .h2 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h2 .invalid-feedback, .cta-search .h2 .form-infotext-scroll--content, .cta-search .h2 .cart-big--item--title--info, .cta-search .h3 small, .cta-search .h3 .small, .cta-search .h3 .form-container .invalid-feedback, .form-container .cta-search .h3 .invalid-feedback,
.cta-search .h3 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h3 .invalid-feedback,
.cta-search .h3 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h3 .invalid-feedback, .cta-search .h3 .form-infotext-scroll--content, .cta-search .h3 .cart-big--item--title--info, .cta-search .h4 small, .cta-search .area--thumbnail--caption small, .cta-search .cart-big--item--title--headline small, .cta-search .h4 .small, .cta-search .area--thumbnail--caption .small, .cta-search .cart-big--item--title--headline .small, .cta-search .h4 .form-container .invalid-feedback, .cta-search .area--thumbnail--caption .form-container .invalid-feedback, .form-container .cta-search .h4 .invalid-feedback, .form-container .cta-search .area--thumbnail--caption .invalid-feedback, .cta-search .cart-big--item--title--headline .form-container .invalid-feedback, .form-container .cta-search .cart-big--item--title--headline .invalid-feedback,
.cta-search .h4 .form-builder-wrapper .invalid-feedback,
.cta-search .area--thumbnail--caption .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h4 .invalid-feedback,
.form-builder-wrapper .cta-search .area--thumbnail--caption .invalid-feedback,
.cta-search .cart-big--item--title--headline .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .cart-big--item--title--headline .invalid-feedback,
.cta-search .h4 .card-form-container .invalid-feedback,
.cta-search .area--thumbnail--caption .card-form-container .invalid-feedback,
.card-form-container .cta-search .h4 .invalid-feedback,
.card-form-container .cta-search .area--thumbnail--caption .invalid-feedback,
.cta-search .cart-big--item--title--headline .card-form-container .invalid-feedback,
.card-form-container .cta-search .cart-big--item--title--headline .invalid-feedback, .cta-search .h4 .form-infotext-scroll--content, .cta-search .area--thumbnail--caption .form-infotext-scroll--content, .cta-search .cart-big--item--title--headline .form-infotext-scroll--content, .cta-search .h4 .cart-big--item--title--info, .cta-search .area--thumbnail--caption .cart-big--item--title--info, .cta-search .cart-big--item--title--headline .cart-big--item--title--info, .cta-search .h5 small, .cta-search .h5 .small, .cta-search .h5 .form-container .invalid-feedback, .form-container .cta-search .h5 .invalid-feedback,
.cta-search .h5 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h5 .invalid-feedback,
.cta-search .h5 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h5 .invalid-feedback, .cta-search .h5 .form-infotext-scroll--content, .cta-search .h5 .cart-big--item--title--info, .cta-search .h6 small, .cta-search .h6 .small, .cta-search .h6 .form-container .invalid-feedback, .form-container .cta-search .h6 .invalid-feedback,
.cta-search .h6 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h6 .invalid-feedback,
.cta-search .h6 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h6 .invalid-feedback, .cta-search .h6 .form-infotext-scroll--content, .cta-search .h6 .cart-big--item--title--info {
  color: inherit;
  text-transform: none;
  margin-bottom: 0.5333333333em;
}
@media (min-width: 768px) {
  .cta-search h1 small, .cta-search h1 .small, .cta-search h1 .form-container .invalid-feedback, .form-container .cta-search h1 .invalid-feedback,
.cta-search h1 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h1 .invalid-feedback,
.cta-search h1 .card-form-container .invalid-feedback,
.card-form-container .cta-search h1 .invalid-feedback, .cta-search h1 .form-infotext-scroll--content, .cta-search h1 .cart-big--item--title--info, .cta-search h2 small, .cta-search h2 .small, .cta-search h2 .form-container .invalid-feedback, .form-container .cta-search h2 .invalid-feedback,
.cta-search h2 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h2 .invalid-feedback,
.cta-search h2 .card-form-container .invalid-feedback,
.card-form-container .cta-search h2 .invalid-feedback, .cta-search h2 .form-infotext-scroll--content, .cta-search h2 .cart-big--item--title--info, .cta-search h3 small, .cta-search h3 .small, .cta-search h3 .form-container .invalid-feedback, .form-container .cta-search h3 .invalid-feedback,
.cta-search h3 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h3 .invalid-feedback,
.cta-search h3 .card-form-container .invalid-feedback,
.card-form-container .cta-search h3 .invalid-feedback, .cta-search h3 .form-infotext-scroll--content, .cta-search h3 .cart-big--item--title--info, .cta-search h4 small, .cta-search h4 .small, .cta-search h4 .form-container .invalid-feedback, .form-container .cta-search h4 .invalid-feedback,
.cta-search h4 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h4 .invalid-feedback,
.cta-search h4 .card-form-container .invalid-feedback,
.card-form-container .cta-search h4 .invalid-feedback, .cta-search h4 .form-infotext-scroll--content, .cta-search h4 .cart-big--item--title--info, .cta-search h5 small, .cta-search h5 .small, .cta-search h5 .form-container .invalid-feedback, .form-container .cta-search h5 .invalid-feedback,
.cta-search h5 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h5 .invalid-feedback,
.cta-search h5 .card-form-container .invalid-feedback,
.card-form-container .cta-search h5 .invalid-feedback, .cta-search h5 .form-infotext-scroll--content, .cta-search h5 .cart-big--item--title--info, .cta-search h6 small, .cta-search h6 .small, .cta-search h6 .form-container .invalid-feedback, .form-container .cta-search h6 .invalid-feedback,
.cta-search h6 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search h6 .invalid-feedback,
.cta-search h6 .card-form-container .invalid-feedback,
.card-form-container .cta-search h6 .invalid-feedback, .cta-search h6 .form-infotext-scroll--content, .cta-search h6 .cart-big--item--title--info,
.cta-search .h1 small,
.cta-search .h1 .small,
.cta-search .h1 .form-container .invalid-feedback,
.form-container .cta-search .h1 .invalid-feedback,
.cta-search .h1 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h1 .invalid-feedback,
.cta-search .h1 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h1 .invalid-feedback,
.cta-search .h1 .form-infotext-scroll--content,
.cta-search .h1 .cart-big--item--title--info, .cta-search .h2 small, .cta-search .h2 .small, .cta-search .h2 .form-container .invalid-feedback, .form-container .cta-search .h2 .invalid-feedback,
.cta-search .h2 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h2 .invalid-feedback,
.cta-search .h2 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h2 .invalid-feedback, .cta-search .h2 .form-infotext-scroll--content, .cta-search .h2 .cart-big--item--title--info, .cta-search .h3 small, .cta-search .h3 .small, .cta-search .h3 .form-container .invalid-feedback, .form-container .cta-search .h3 .invalid-feedback,
.cta-search .h3 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h3 .invalid-feedback,
.cta-search .h3 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h3 .invalid-feedback, .cta-search .h3 .form-infotext-scroll--content, .cta-search .h3 .cart-big--item--title--info, .cta-search .h4 small, .cta-search .area--thumbnail--caption small, .cta-search .cart-big--item--title--headline small, .cta-search .h4 .small, .cta-search .area--thumbnail--caption .small, .cta-search .cart-big--item--title--headline .small, .cta-search .h4 .form-container .invalid-feedback, .cta-search .area--thumbnail--caption .form-container .invalid-feedback, .form-container .cta-search .h4 .invalid-feedback, .form-container .cta-search .area--thumbnail--caption .invalid-feedback, .cta-search .cart-big--item--title--headline .form-container .invalid-feedback, .form-container .cta-search .cart-big--item--title--headline .invalid-feedback,
.cta-search .h4 .form-builder-wrapper .invalid-feedback,
.cta-search .area--thumbnail--caption .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h4 .invalid-feedback,
.form-builder-wrapper .cta-search .area--thumbnail--caption .invalid-feedback,
.cta-search .cart-big--item--title--headline .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .cart-big--item--title--headline .invalid-feedback,
.cta-search .h4 .card-form-container .invalid-feedback,
.cta-search .area--thumbnail--caption .card-form-container .invalid-feedback,
.card-form-container .cta-search .h4 .invalid-feedback,
.card-form-container .cta-search .area--thumbnail--caption .invalid-feedback,
.cta-search .cart-big--item--title--headline .card-form-container .invalid-feedback,
.card-form-container .cta-search .cart-big--item--title--headline .invalid-feedback, .cta-search .h4 .form-infotext-scroll--content, .cta-search .area--thumbnail--caption .form-infotext-scroll--content, .cta-search .cart-big--item--title--headline .form-infotext-scroll--content, .cta-search .h4 .cart-big--item--title--info, .cta-search .area--thumbnail--caption .cart-big--item--title--info, .cta-search .cart-big--item--title--headline .cart-big--item--title--info, .cta-search .h5 small, .cta-search .h5 .small, .cta-search .h5 .form-container .invalid-feedback, .form-container .cta-search .h5 .invalid-feedback,
.cta-search .h5 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h5 .invalid-feedback,
.cta-search .h5 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h5 .invalid-feedback, .cta-search .h5 .form-infotext-scroll--content, .cta-search .h5 .cart-big--item--title--info, .cta-search .h6 small, .cta-search .h6 .small, .cta-search .h6 .form-container .invalid-feedback, .form-container .cta-search .h6 .invalid-feedback,
.cta-search .h6 .form-builder-wrapper .invalid-feedback,
.form-builder-wrapper .cta-search .h6 .invalid-feedback,
.cta-search .h6 .card-form-container .invalid-feedback,
.card-form-container .cta-search .h6 .invalid-feedback, .cta-search .h6 .form-infotext-scroll--content, .cta-search .h6 .cart-big--item--title--info {
    font-size: 1rem;
  }
}
.cta-search--fieldset {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "inputField submitButton";
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .cta-search--fieldset {
    margin-top: 2rem;
  }
}
.cta-search--field, .cta-search--submit {
  display: block;
  margin: 0;
  border: 1px solid #fff;
}
.cta-search--field {
  grid-area: inputField;
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 1.3333333333rem;
  font-size: 0.8rem;
  outline: 0 !important;
}
.cta-search--submit {
  grid-area: submitButton;
  color: #fff;
  background-color: #6ABBF3;
  border-color: #6ABBF3;
  border-radius: 0;
}
.cta-search--info-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .cta-search--info-buttons {
    margin-top: 2rem;
    font-size: 1rem;
  }
}
.cta-search--info-buttons a {
  color: #fff;
  text-decoration: none;
}
.cta-search--info-buttons a:hover, .cta-search--info-buttons a:focus {
  color: inherit;
  text-decoration: underline;
}

.cta-badge {
  display: flex;
  align-items: center;
  position: relative;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  padding: 2em;
  text-decoration: none;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  transform: rotate(-10deg);
  transition: color 0.3s, background-color 0.3s, transform 0.3s;
  transition-timing-function: ease-in-out;
  will-change: color, background-color, transform;
  font-size: 0.6666666667rem;
  background-color: #003770;
  color: #fff;
}
@media (min-width: 768px) {
  .cta-badge {
    font-size: 1.3333333333rem;
    font-size: min(1.3333333333rem, 1.43vw);
  }
}
.cta-badge::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.75em;
  bottom: 0.75em;
  left: 0.75em;
  right: 0.75em;
  border: 0.2em dashed currentColor;
  opacity: 0.5;
  border-radius: 50%;
}
.cta-badge:hover, .cta-badge:focus {
  color: #fff;
  transform: rotate(0deg);
}

.cta-badge--text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.cta-badge--text span {
  display: block;
  line-height: 1;
}
.cta-badge--text-big {
  font-size: 1.55em;
}
.cta-badge--text-small {
  font-size: 0.85em;
}
.cta-badge--text::after {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  margin-top: 0.75em;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: currentColor;
  mask-image: svg-load("arrow-right.svg");
}
.cta-badge--text.cta-badge--text-no-icon::after {
  display: none;
  content: none;
}

:root {
  --courseHeroRatioTop: 58.7301587302%;
}
@media (min-width: 992px) {
  :root {
    --courseHeroRatioTop: 55.7986870897%;
  }
}

.courses-hero {
  text-align: center;
}
@media (min-width: 992px) {
  .courses-hero {
    text-align: left;
  }
}
.courses-hero::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-top: 5.3333333333%;
  background-image: svg-load("layout/svg/cta-search-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 100%;
  pointer-events: none;
  z-index: 0;
}
@media (min-width: 992px) {
  .courses-hero::before {
    display: none;
  }
}
.courses-hero h1, .courses-hero h2, .courses-hero h3, .courses-hero h4, .courses-hero h5, .courses-hero h6,
.courses-hero .h1, .courses-hero .h2, .courses-hero .h3, .courses-hero .h4, .courses-hero .area--thumbnail--caption, .courses-hero .cart-big--item--title--headline, .courses-hero .h5, .courses-hero .h6 {
  color: inherit;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.courses-hero h1 small, .courses-hero h2 small, .courses-hero h3 small, .courses-hero h4 small, .courses-hero h5 small, .courses-hero h6 small,
.courses-hero .h1 small, .courses-hero .h2 small, .courses-hero .h3 small, .courses-hero .h4 small, .courses-hero .area--thumbnail--caption small, .courses-hero .cart-big--item--title--headline small, .courses-hero .h5 small, .courses-hero .h6 small {
  color: inherit;
  text-transform: none;
}
.courses-hero h1 small, .courses-hero h2 small, .courses-hero h3 small, .courses-hero h4 small, .courses-hero h5 small, .courses-hero h6 small,
.courses-hero .h1 small, .courses-hero .h2 small, .courses-hero .h3 small, .courses-hero .h4 small, .courses-hero .area--thumbnail--caption small, .courses-hero .cart-big--item--title--headline small, .courses-hero .h5 small, .courses-hero .h6 small {
  font-size: 0.8rem;
}
@media screen and (min-width: 768px) {
  .courses-hero h1 small, .courses-hero h2 small, .courses-hero h3 small, .courses-hero h4 small, .courses-hero h5 small, .courses-hero h6 small,
.courses-hero .h1 small, .courses-hero .h2 small, .courses-hero .h3 small, .courses-hero .h4 small, .courses-hero .area--thumbnail--caption small, .courses-hero .cart-big--item--title--headline small, .courses-hero .h5 small, .courses-hero .h6 small {
    font-size: calc(12px + 6 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .courses-hero h1 small, .courses-hero h2 small, .courses-hero h3 small, .courses-hero h4 small, .courses-hero h5 small, .courses-hero h6 small,
.courses-hero .h1 small, .courses-hero .h2 small, .courses-hero .h3 small, .courses-hero .h4 small, .courses-hero .area--thumbnail--caption small, .courses-hero .cart-big--item--title--headline small, .courses-hero .h5 small, .courses-hero .h6 small {
    font-size: 1.2rem;
  }
}
html[data-useragent*="Edg/"] .courses-hero h1, html[data-useragent*="Edg/"] .courses-hero .h1 {
  word-break: break-all;
}
.courses-hero .tag {
  background-color: transparent;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.4);
}
.courses-hero--inner {
  background-repeat: no-repeat;
  background-position: bottom;
}
.courses-hero--inner::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: var(--courseHeroRatioTop);
}
@media (min-width: 992px) {
  .courses-hero--inner {
    display: grid;
    grid-template-columns: 1fr var(--courseDetail-sidebarWidth);
    grid-template-areas: "header-content header-sidebar";
    position: relative;
    background-image: svg-load("layout/svg/cta-search-bg.svg");
    background-position: bottom right;
    background-position: 100% calc(100% + 2px);
    background-size: calc(var(--courseDetail-sidebarWidth) + 120px) auto;
  }
  .courses-hero--inner .courses-hero--inner-content {
    padding-right: 4rem;
  }
}

:root {
  --mainContentPaddingTop: 4rem;
  --mainContentPaddingBottom: 4rem;
}
@media (min-width: 992px) {
  :root {
    --mainContentPaddingTop: 6.6666666667rem;
    --mainContentPaddingBottom: 6.6666666667rem;
  }
}

.main-content {
  padding-top: var(--mainContentPaddingTop);
  padding-bottom: var(--mainContentPaddingBottom);
}
.main-content > *:first-child {
  margin-top: 0;
}
.main-content > *:last-child {
  margin-bottom: 0;
}

.main-content--container > *:first-child:not(.sr-only) {
  margin-top: 0;
}
.main-content--container > .sr-only:first-child + .area {
  margin-top: 0;
}
.main-content--container > *:last-child:not(.btn):not(.footer-newsletter-small--submit):not(.search-overlay--submit):not(.cta-search--submit) {
  margin-bottom: 0;
}

.footer {
  position: relative;
  border-top: 1px solid #F0F5FB;
  margin-top: 6.6666666667rem;
}

.footer-social-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer-social-nav .footer-social-nav--item {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 0.6666666667em;
  width: 4.5em;
  height: 4.5em;
  overflow: hidden;
  text-indent: -9999em;
  color: #fff;
  background-color: currentColor;
  border: 1px solid currentColor;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  margin-left: 1.5em;
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
  transition-timing-function: ease-in-out;
  will-change: transform, box-shadow;
}
.footer-social-nav .footer-social-nav--item:first-child {
  margin-left: 0;
}
.footer-social-nav .footer-social-nav--item::before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.4em;
  height: 2.4em;
  transform: translate(-50%, -50%);
  color: #fff;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: #000;
  transition: background-color 0.3s;
  transition-timing-function: ease-in-out;
  will-change: background-color;
}
@media (min-width: 768px) {
  .footer-social-nav .footer-social-nav--item {
    width: 6em;
    height: 6em;
  }
}
@media (min-width: 1200px) {
  .footer-social-nav .footer-social-nav--item:hover, .footer-social-nav .footer-social-nav--item:focus {
    transform: translateY(-5%);
    box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.15);
  }
  .footer-social-nav .footer-social-nav--item[class*=facebook]:hover::before, .footer-social-nav .footer-social-nav--item[class*=facebook]:focus::before {
    background-color: #3b5998;
  }
  .footer-social-nav .footer-social-nav--item[class*=xing]:hover::before, .footer-social-nav .footer-social-nav--item[class*=xing]:focus::before {
    background-color: #026466;
  }
  .footer-social-nav .footer-social-nav--item[class*=youtube]:hover::before, .footer-social-nav .footer-social-nav--item[class*=youtube]:focus::before {
    background-color: #ff0000;
  }
  .footer-social-nav .footer-social-nav--item[class*=instagram]:hover::before, .footer-social-nav .footer-social-nav--item[class*=instagram]:focus::before {
    background-color: #405de6;
  }
  .footer-social-nav .footer-social-nav--item[class*=linkedin]:hover::before, .footer-social-nav .footer-social-nav--item[class*=linkedin]:focus::before {
    background-color: #0077b5;
  }
}

.footer-main {
  padding: 1.3333333333rem 0;
  font-weight: 400;
}
@media (min-width: 768px) {
  .footer-main--inner {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "popularLinks newsletter";
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .footer-main--inner {
    grid-template-columns: 1fr auto;
  }
}
.footer-main h1, .footer-main h2, .footer-main h3, .footer-main h4, .footer-main h5, .footer-main h6,
.footer-main .h1, .footer-main .h2, .footer-main .h3, .footer-main .h4, .footer-main .area--thumbnail--caption, .footer-main .cart-big--item--title--headline, .footer-main .h5, .footer-main .h6 {
  color: #000;
  margin-bottom: 2rem;
}
.footer-main h1, .footer-main h2, .footer-main h3, .footer-main h4,
.footer-main .h1, .footer-main .h2, .footer-main .h3, .footer-main .h4, .footer-main .area--thumbnail--caption, .footer-main .cart-big--item--title--headline {
  text-transform: uppercase;
}

.footer-popular-links {
  grid-area: popularLinks;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.footer-popular-links--list, .footer-popular-links--list li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-popular-links--list li {
  margin-bottom: 1rem;
}
.footer-popular-links--list li:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .footer-popular-links--list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .footer-popular-links--list li {
    flex-basis: 50%;
  }
}

.footer-newsletter-small {
  grid-area: newsletter;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .footer-newsletter-small {
    max-width: 24rem;
  }
}
.footer-newsletter-small--fieldset {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "inputField submitButton";
  align-items: center;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
}
.footer-newsletter-small--field, .footer-newsletter-small--submit {
  display: block;
  margin: 0;
  border: 1px solid #fff;
}
.footer-newsletter-small--field {
  grid-area: inputField;
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 1.3333333333rem;
  font-size: 0.8rem;
}
.footer-newsletter-small--submit {
  grid-area: submitButton;
  color: #fff;
  background-color: #003770;
  border-color: #003770;
  border-radius: 0;
}
.footer-newsletter-small--submit:hover, .footer-newsletter-small--submit:focus {
  background-color: #6ABBF3;
  border-color: #6ABBF3;
}

.footer-info {
  background-color: #F0F5FB;
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .footer-info--inner {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
  }
}
@media (min-width: 992px) {
  .footer-info--inner {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer-infobox {
  padding: 2rem;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 1rem;
}
.footer-infobox:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .footer-infobox {
    margin: 0;
  }
}
.footer-infobox p.icon-text {
  display: flex;
  align-items: flex-start;
  margin-left: 0;
  font-weight: 400;
}
.footer-infobox p:first-child {
  margin-top: 0;
}
.footer-infobox p:last-child {
  margin-bottom: 0;
}
.footer-infobox p a {
  text-decoration: none;
}
.footer-infobox p a:hover, .footer-infobox p a:focus {
  text-decoration: underline;
}
.footer-infobox h1, .footer-infobox h2, .footer-infobox h3, .footer-infobox h4, .footer-infobox h5, .footer-infobox h6,
.footer-infobox .h1, .footer-infobox .h2, .footer-infobox .h3, .footer-infobox .h4, .footer-infobox .area--thumbnail--caption, .footer-infobox .cart-big--item--title--headline, .footer-infobox .h5, .footer-infobox .h6 {
  color: #000;
  margin-bottom: 1rem;
}

.footer-bottom {
  padding: 2.6666666667rem 0;
  background-color: #003770;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
  color: #fff;
  font-weight: 400;
}
.footer-bottom--inner {
  text-align: center;
}
@media (min-width: 768px) {
  .footer-bottom--inner {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "info conecto";
    align-items: center;
  }
}
.footer-bottom p {
  grid-area: text;
  margin-bottom: 1.3333333333em;
}
.footer-bottom p:last-child {
  margin-bottom: 0;
}
.footer-bottom--info {
  grid-area: info;
  margin-bottom: 3.3333333333rem;
}
@media (min-width: 768px) {
  .footer-bottom--info {
    text-align: left;
    margin-bottom: 0;
  }
  .footer-bottom--info p {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 3.3333333333rem;
  }
  .footer-bottom--info p:first-child {
    margin-left: 0;
  }
}
.footer-bottom--info--links {
  grid-area: links;
}
.footer-bottom a {
  text-decoration: none;
}
.footer-bottom a.link-arrow {
  margin-left: 1.6666666667em;
}
.footer-bottom a.link-arrow:first-child {
  margin-left: 0;
}
.footer-bottom a.link-arrow::before {
  filter: saturate(0) brightness(0) invert(100%) opacity(1);
}
.footer-bottom a:hover, .footer-bottom a:focus {
  text-decoration: underline;
}

.conecto-copyright {
  grid-area: conecto;
  display: inline-block;
  width: 6rem;
  height: 0.4666666667rem;
  min-height: 0.4666666667rem;
  text-align: left;
  text-indent: -9999em;
  overflow: hidden;
  background-image: svg-load("layout/svg/conecto.svg", fill=#fff);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/*** IMPORT AREAS ***/
.area {
  margin-top: 60px;
}
@media screen and (min-width: 768px) {
  .area {
    margin-top: calc(60px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .area {
    margin-top: 80px;
  }
}
.area {
  margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .area {
    margin-bottom: calc(60px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .area {
    margin-bottom: 80px;
  }
}
.area > *:first-child {
  margin-top: 0;
}
.area > *:last-child:not(.btn):not(.search-overlay--submit):not(.cta-search--submit):not(.footer-newsletter-small--submit) {
  margin-bottom: 0;
}
.area.is-narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 50em;
}
.area.full-width {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%);
}

.margin-top--none,
.area.margin-top--none {
  margin-top: 0;
}
.margin-top--tiny,
.area.margin-top--tiny {
  margin-top: 0.6666666667rem;
}
.margin-top--small,
.area.margin-top--small {
  margin-top: 1rem;
}
.margin-top--default,
.area.margin-top--default {
  margin-top: 2rem;
}
.margin-top--medium,
.area.margin-top--medium {
  margin-top: 60px;
}
@media screen and (min-width: 768px) {
  .margin-top--medium,
.area.margin-top--medium {
    margin-top: calc(60px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .margin-top--medium,
.area.margin-top--medium {
    margin-top: 80px;
  }
}
.margin-top--large,
.area.margin-top--large {
  margin-top: 80px;
}
@media screen and (min-width: 768px) {
  .margin-top--large,
.area.margin-top--large {
    margin-top: calc(80px + 80 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .margin-top--large,
.area.margin-top--large {
    margin-top: 160px;
  }
}
.margin-bottom--none,
.area.margin-bottom--none {
  margin-bottom: 0;
}
.margin-bottom--tiny,
.area.margin-bottom--tiny {
  margin-bottom: 0.6666666667rem;
}
.margin-bottom--small,
.area.margin-bottom--small {
  margin-bottom: 1rem;
}
.margin-bottom--default,
.area.margin-bottom--default {
  margin-bottom: 2rem;
}
.margin-bottom--medium,
.area.margin-bottom--medium {
  margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .margin-bottom--medium,
.area.margin-bottom--medium {
    margin-bottom: calc(60px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .margin-bottom--medium,
.area.margin-bottom--medium {
    margin-bottom: 80px;
  }
}
.margin-bottom--large,
.area.margin-bottom--large {
  margin-bottom: 80px;
}
@media screen and (min-width: 768px) {
  .margin-bottom--large,
.area.margin-bottom--large {
    margin-bottom: calc(80px + 80 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .margin-bottom--large,
.area.margin-bottom--large {
    margin-bottom: 160px;
  }
}

.area--mobile-icon-nav {
  font-size: 0.6666666667rem;
}
@media screen and (min-width: 400px) {
  .area--mobile-icon-nav {
    font-size: calc(10px + 8 * ((100vw - 400px) / 800));
  }
}
@media screen and (min-width: 1200px) {
  .area--mobile-icon-nav {
    font-size: 1.2rem;
  }
}
@media (min-width: 1200px) {
  .area--mobile-icon-nav {
    display: none;
  }
}
.area--mobile-icon-nav--list, .area--mobile-icon-nav--list li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.area--mobile-icon-nav--list {
  /*display: grid;
  grid-template-columns: 1fr;
  grid-gap: to-rem(10px);

  @media (min-width: 350px) {
      grid-template-columns: 50% 50%;
  }*/
}
.area--mobile-icon-nav--list li {
  display: block;
  margin-bottom: 0.6666666667rem;
}
.area--mobile-icon-nav--list li:last-child {
  margin-bottom: 0;
}
@media (min-width: 370px) {
  .area--mobile-icon-nav--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.6666666667rem;
  }
  .area--mobile-icon-nav--list li {
    margin: 0;
    flex-basis: calc(50% - 5px);
    flex-grow: 0;
    width: calc(50% - 5px);
  }
}
@media (min-width: 992px) {
  .area--mobile-icon-nav--list li {
    flex-basis: auto;
    width: auto;
  }
}
.area--mobile-icon-nav a {
  color: #003770;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "icon label";
  align-items: center;
  grid-gap: 1em;
  border: 1px solid #eee;
  border-radius: 1.3336em;
  padding: 1.5em;
}
.area--mobile-icon-nav i {
  grid-area: icon;
  position: relative;
  background-color: currentColor;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 3em;
  height: 3em;
}
.area--mobile-icon-nav i::before {
  color: #fff;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  transform: translate(-50%, -50%);
}
.area--mobile-icon-nav i.mask-icon-before--bfi-info {
  background-color: transparent;
}
.area--mobile-icon-nav i.mask-icon-before--bfi-info::before {
  color: currentColor;
  width: 100%;
  height: 100%;
}

.area.area--header-h3:not([class*=margin-bottom]), .area.area--header-h4:not([class*=margin-bottom]) {
  margin-bottom: 2rem;
}
.area.area--header-h5:not([class*=margin-bottom]), .area.area--header-h6:not([class*=margin-bottom]) {
  margin-bottom: 0;
}

.area--frontpage-main-headline {
  position: relative;
}
.area--frontpage-main-headline::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 35%;
  left: 0;
  width: 100%;
  height: calc(var(--frontpage-mainHeadline-offsetTop) - var(--frontpage-pageHeaderHero-height));
  z-index: -1;
  background-image: url("../images/layout/frontpage-bg-dotted-headline-mobile.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 110% auto;
  pointer-events: none;
}
@media (min-width: 768px) {
  .area--frontpage-main-headline::before {
    bottom: 6%;
  }
}
@media (min-width: 1200px) {
  .area--frontpage-main-headline::before {
    width: 80vw;
    height: calc(var(--frontpage-mainHeadline-offsetTop) - var(--frontpage-pageHeaderHero-height));
    bottom: 50%;
    background-image: url("../images/layout/svg/frontpage-bg-dotted-headline.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 1529px auto;
  }
}
.area--frontpage-main-headline .container, .area--frontpage-main-headline .page-header--container, .area--frontpage-main-headline .page-hero--small, .area--frontpage-main-headline .page-header--tools--inner, .area--frontpage-main-headline .courses-hero--inner, .area--frontpage-main-headline .footer-main--inner, .area--frontpage-main-headline .footer-info--inner, .area--frontpage-main-headline .footer-bottom--inner {
  padding-left: 10vw;
}
.area--frontpage-main-headline .area--header {
  position: relative;
  padding-left: 4em;
}
@media (min-width: 576px) {
  .area--frontpage-main-headline .area--header {
    max-width: 55vw;
  }
}
@media (min-width: 1200px) {
  .area--frontpage-main-headline .area--header {
    padding-left: 5.6em;
  }
}
.area--frontpage-main-headline .area--header::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3em;
  height: 3em;
  background-color: #003770;
  border: 0.8em solid #fff;
  border-radius: 50%;
}
@media (min-width: 1200px) {
  .area--frontpage-main-headline .area--header::before {
    width: 3.6em;
    height: 3.6em;
    border: 0.8em solid #fff;
  }
}
.area--frontpage-main-headline .area--header h1, .area--frontpage-main-headline .area--header h2, .area--frontpage-main-headline .area--header h3, .area--frontpage-main-headline .area--header h4, .area--frontpage-main-headline .area--header h5, .area--frontpage-main-headline .area--header h6,
.area--frontpage-main-headline .area--header .h1, .area--frontpage-main-headline .area--header .h2, .area--frontpage-main-headline .area--header .h3, .area--frontpage-main-headline .area--header .h4, .area--frontpage-main-headline .area--header .area--thumbnail--caption, .area--frontpage-main-headline .area--header .cart-big--item--title--headline, .area--frontpage-main-headline .area--header .h5, .area--frontpage-main-headline .area--header .h6 {
  max-width: 38rem;
  max-width: 22ch;
}

.area--frontpage-news--buttons {
  text-align: center;
}

@media (max-width: 991.98px) {
  .area--frontpage-news--promo {
    padding-left: var(--mainContentContainer-offsetLeftWithPadding);
    margin-bottom: 2rem;
  }
  .area--frontpage-news--promo .card-item {
    max-width: min(85vw, 20rem);
  }

  .area--frontpage-news--items {
    display: grid;
    grid: 1fr / auto-flow min(85vw, 20rem);
    grid-gap: 1rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    -webkit-overflow-scrolling: touch;
    padding-left: var(--mainContentContainer-offsetLeftWithPadding);
    scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);
    padding-top: 4rem;
    padding-bottom: 4rem;
    transform: translateY(-4rem);
    margin-bottom: -4rem;
    scrollbar-width: none;
  }
  .area--frontpage-news--items::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
  .area--frontpage-news--items > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}
@media (min-width: 992px) {
  .area--frontpage-news-container {
    background-image: url("../images/layout/frontpage-news--bg-line.svg"), url("../images/layout/frontpage-news--bg-team.png");
    background-repeat: no-repeat, no-repeat;
    background-position: 50% -6.6666666667rem, center right;
    background-size: 1920px 1500px, contain;
  }

  .area--frontpage-news--wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1260px;
  }

  .area--frontpage-news {
    max-width: 44rem;
    position: relative;
  }
  .area--frontpage-news .card-item {
    width: 20rem;
  }
  .area--frontpage-news--promo {
    position: relative;
    padding-top: 16.9rem;
  }
  .area--frontpage-news--promo .card-item {
    display: none;
  }
  .area--frontpage-news--promo .card-item:first-child {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .area--frontpage-news--items {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }
  .area--frontpage-news--items--item:nth-child(2) {
    transform: translateY(50%);
  }
  .area--frontpage-news--buttons {
    position: absolute;
    bottom: 6.6666666667rem;
    right: 0;
    width: 20rem;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .area--frontpage-news-container .container, .area--frontpage-news-container .page-header--container, .area--frontpage-news-container .page-hero--small, .area--frontpage-news-container .page-header--tools--inner, .area--frontpage-news-container .courses-hero--inner, .area--frontpage-news-container .footer-main--inner, .area--frontpage-news-container .footer-info--inner, .area--frontpage-news-container .footer-bottom--inner {
    position: relative;
    min-height: 10rem;
  }
  .area--frontpage-news-container .area--header {
    position: absolute;
    top: -4.3333333333rem;
    left: 2rem;
    padding-left: 4em;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .area--frontpage-news-container .area--header {
    max-width: 55vw;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .area--frontpage-news-container .area--header {
    padding-left: 5.6em;
  }
}
@media (min-width: 1200px) {
  .area--frontpage-news-container .area--header::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3em;
    height: 3em;
    background-color: #003770;
    border: 0.8em solid #fff;
    border-radius: 50%;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .area--frontpage-news-container .area--header::before {
    width: 3.6em;
    height: 3.6em;
    border: 0.8em solid #fff;
  }
}
@media (min-width: 1200px) {
  .area--frontpage-news-container .area--header h1, .area--frontpage-news-container .area--header h2, .area--frontpage-news-container .area--header h3, .area--frontpage-news-container .area--header h4, .area--frontpage-news-container .area--header h5, .area--frontpage-news-container .area--header h6,
.area--frontpage-news-container .area--header .h1, .area--frontpage-news-container .area--header .h2, .area--frontpage-news-container .area--header .h3, .area--frontpage-news-container .area--header .h4, .area--frontpage-news-container .area--header .area--thumbnail--caption, .area--frontpage-news-container .area--header .cart-big--item--title--headline, .area--frontpage-news-container .area--header .h5, .area--frontpage-news-container .area--header .h6 {
    max-width: 26rem;
    max-width: 15ch;
  }
}
.area--text h1, .area--text h2, .area--text h3, .area--text h4, .area--text h5, .area--text h6,
.area--text .h1, .area--text .h2, .area--text .h3, .area--text .h4, .area--text .area--thumbnail--caption, .area--text .cart-big--item--title--headline, .area--text .h5, .area--text .h6 {
  margin-bottom: 0.5em;
}
@media (max-width: 575.98px) {
  .area--text.text-center {
    text-align: left;
  }
}
.area--text .area--header {
  margin-bottom: 2rem;
}

.area--thumbnail {
  position: relative;
}
.area--thumbnail picture, .area--thumbnail img, .area--thumbnail video {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.area--thumbnail video {
  object-fit: cover;
}
.area--media--multiple > .cols > .area--thumbnail video, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media--multiple > .row > .area--thumbnail video,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media--multiple > .row > .area--thumbnail video {
  height: 100%;
}
.area--thumbnail .pimcore_editable_video_progress img, .area--thumbnail .pimcore_editable_video_progress picture {
  width: 100% !important;
  height: auto !important;
}
.area--thumbnail--copyright {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: auto;
  max-width: 80%;
  max-width: calc(100% - 1.3333333333rem);
  background-color: #000;
  font-size: 0.6666666667rem;
  color: #fff;
  opacity: 0.4;
  line-height: 1.2;
  padding: 0.2rem 1.3333333333rem;
  border-top-left-radius: 1.3333333333rem;
  border-bottom-right-radius: 1.3333333333rem;
  pointer-events: none;
}
.area--thumbnail--copyright a, .area--thumbnail--copyright a:hover, .area--thumbnail--copyright a:focus {
  text-decoration: none;
  color: currentColor;
  pointer-events: all;
}
.area--thumbnail--caption {
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-2.1em);
  width: 80%;
  max-width: 80vw;
  padding: 1.5em 2em;
  color: #000;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .area--thumbnail--caption {
    max-width: 60vw;
  }
}
@media (min-width: 1200px) {
  .area--thumbnail--caption {
    max-width: 40rem;
  }
}
.area--thumbnail picture, .area--thumbnail img, .area--thumbnail video, .area--thumbnail iframe {
  border-radius: 1.3333333333rem;
}
@media (max-width: 575.98px) {
  .area--textmedia.full-width .area--thumbnail picture, .area--textmedia.full-width .area--thumbnail img, .area--textmedia.full-width .area--thumbnail video, .area--textmedia.full-width .area--thumbnail iframe {
    border-radius: 0;
  }
}
.area--parallax.full-width .area--thumbnail picture, .area--parallax.full-width .area--thumbnail img, .area--parallax.full-width .area--thumbnail video, .area--parallax.full-width .area--thumbnail iframe, .area--media.full-width > .cols.cols--1 .area--thumbnail picture, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail picture,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail picture, .area--media.full-width > .cols.cols--1 .area--thumbnail img, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail img,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail img, .area--media.full-width > .cols.cols--1 .area--thumbnail video, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail video,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail video, .area--media.full-width > .cols.cols--1 .area--thumbnail iframe, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail iframe,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--media.full-width > .cols--1.row .area--thumbnail iframe {
  border-radius: 0;
}
.area--textmedia.full-width > .thumbnail--left > .area--thumbnail picture, .area--textmedia.full-width > .thumbnail--left > .area--thumbnail img, .area--textmedia.full-width > .thumbnail--left > .area--thumbnail video, .area--textmedia.full-width > .thumbnail--left > .area--thumbnail iframe {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.area--textmedia.full-width > .thumbnail--left > .area--thumbnail .area--thumbnail--copyright {
  border-top-left-radius: 0;
}
.area--textmedia.full-width > .thumbnail--right > .area--thumbnail picture, .area--textmedia.full-width > .thumbnail--right > .area--thumbnail img, .area--textmedia.full-width > .thumbnail--right > .area--thumbnail video, .area--textmedia.full-width > .thumbnail--right > .area--thumbnail iframe {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.no-gutter > .area--thumbnail picture, .no-gutter > .area--thumbnail img, .no-gutter > .area--thumbnail video, .no-gutter > .area--thumbnail iframe {
  border-radius: 0;
}
:not(.full-width) > .no-gutter > .area--thumbnail:first-child picture, :not(.full-width) > .no-gutter > .area--thumbnail:first-child img, :not(.full-width) > .no-gutter > .area--thumbnail:first-child video, :not(.full-width) > .no-gutter > .area--thumbnail:first-child iframe {
  border-top-left-radius: 1.3333333333rem;
  border-bottom-left-radius: 1.3333333333rem;
}
:not(.full-width) > .no-gutter > .area--thumbnail:last-child picture, :not(.full-width) > .no-gutter > .area--thumbnail:last-child img, :not(.full-width) > .no-gutter > .area--thumbnail:last-child video, :not(.full-width) > .no-gutter > .area--thumbnail:last-child iframe {
  border-top-right-radius: 1.3333333333rem;
  border-bottom-right-radius: 1.3333333333rem;
}

.area--textmedia .area--thumbnail {
  margin: 0;
}
.area--textmedia .area--text--inner {
  padding-top: 30px;
}
@media (min-width: 768px) {
  .area--textmedia .area--text--inner {
    padding-top: 0;
  }
}
.area--textmedia .area--text--inner > *:first-child {
  margin-top: 0;
}
.area--textmedia .area--text--inner > *:last-child {
  margin-bottom: 0;
}
.area--textmedia.full-width .area--text--inner {
  padding: 2rem;
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .area--text--inner {
    padding: 12% 15% 12% 0;
  }
}
.area--textmedia.full-width .thumbnail--right .area--text--inner {
  padding-left: 15%;
  padding-right: 2rem;
}
.area--textmedia.full-width .area--thumbnail {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .area--textmedia.full-width .area--thumbnail {
    width: 100%;
    height: 100%;
  }
  .area--textmedia.full-width .area--thumbnail img, .area--textmedia.full-width .area--thumbnail video {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
  }
}
.area--textmedia.is-highlighted {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%);
  background-color: #003770;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .area--textmedia.is-highlighted > .cols > .col.area--text, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.area--text,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.area--text, .form-container .area--textmedia.is-highlighted > .cols > .area--text[class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .area--text[class*=col-],
.form-builder-wrapper .area--textmedia.is-highlighted > .cols > .area--text[class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .area--text[class*=col-] {
    padding-left: 30px;
  }
}
@media (max-width: 575.98px) {
  .area--textmedia.is-highlighted > .cols > .col.area--text, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.area--text,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.area--text, .form-container .area--textmedia.is-highlighted > .cols > .area--text[class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .area--text[class*=col-],
.form-builder-wrapper .area--textmedia.is-highlighted > .cols > .area--text[class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .area--text[class*=col-] {
    padding-left: 0;
  }
}
.area--textmedia.is-highlighted > .cols > .col.thumbnail--right.area--text, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.thumbnail--right.area--text,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.thumbnail--right.area--text, .form-container .area--textmedia.is-highlighted > .cols > .thumbnail--right.area--text[class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .thumbnail--right.area--text[class*=col-],
.form-builder-wrapper .area--textmedia.is-highlighted > .cols > .thumbnail--right.area--text[class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .thumbnail--right.area--text[class*=col-] {
  padding: 0 150px 0 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .area--textmedia.is-highlighted > .cols > .col.thumbnail--right.area--text, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.thumbnail--right.area--text,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.thumbnail--right.area--text, .form-container .area--textmedia.is-highlighted > .cols > .thumbnail--right.area--text[class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .thumbnail--right.area--text[class*=col-],
.form-builder-wrapper .area--textmedia.is-highlighted > .cols > .thumbnail--right.area--text[class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .thumbnail--right.area--text[class*=col-] {
    padding-right: 30px;
  }
}
@media (max-width: 575.98px) {
  .area--textmedia.is-highlighted > .cols > .col.thumbnail--right.area--text, .form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.thumbnail--right.area--text,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .col.thumbnail--right.area--text, .form-container .area--textmedia.is-highlighted > .cols > .thumbnail--right.area--text[class*=col-],
.form-container .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .thumbnail--right.area--text[class*=col-],
.form-builder-wrapper .area--textmedia.is-highlighted > .cols > .thumbnail--right.area--text[class*=col-],
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .form-group.area--textmedia.is-highlighted > .row > .thumbnail--right.area--text[class*=col-] {
    padding-right: 0;
  }
}
.area--textmedia.is-highlighted .area--text {
  color: #fff;
}
.area--textmedia.is-highlighted .area--text h1, .area--textmedia.is-highlighted .area--text h2, .area--textmedia.is-highlighted .area--text h3, .area--textmedia.is-highlighted .area--text h4, .area--textmedia.is-highlighted .area--text h5, .area--textmedia.is-highlighted .area--text h6 {
  color: currentColor;
}
.area--textmedia.is-highlighted .area--text--inner {
  padding: 30px;
}
@media (min-width: 1200px) {
  .area--textmedia.is-highlighted .area--text--inner {
    padding: 12% 15%;
  }
}
.area--textmedia.is-highlighted .area--text a:not(.btn):not(.search-overlay--submit):not(.cta-search--submit):not(.footer-newsletter-small--submit) {
  color: currentColor;
}
.area--textmedia.is-highlighted .area--text a:not(.btn):not(.search-overlay--submit):not(.cta-search--submit):not(.footer-newsletter-small--submit):hover, .area--textmedia.is-highlighted .area--text a:not(.btn):not(.search-overlay--submit):not(.cta-search--submit):not(.footer-newsletter-small--submit):focus {
  color: currentColor;
}
.area--textmedia.text-center-vertically .area--text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .area--textmedia .thumbnail--right [class*=col]:first-child {
    order: 2;
  }
  .area--textmedia .thumbnail--right [class*=col]:last-child {
    order: 1;
  }
}
.area--textmedia > .cols--6 {
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 0;
}
@media (min-width: 768px) {
  .area--textmedia > .cols--6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 767.98px) {
  .area--textmedia > .cols--6 > [class*=col-] {
    grid-column: auto/auto;
  }
}

.area--textmedia--logos, .area--textmedia--logo {
  margin-bottom: 1em;
}
.area--textmedia--logos--logo iframe, .area--textmedia--logos--logo img, .area--textmedia--logos--logo picture, .area--textmedia--logos--logo video, .area--textmedia--logo--logo iframe, .area--textmedia--logo--logo img, .area--textmedia--logo--logo picture, .area--textmedia--logo--logo video {
  border-radius: 0;
  max-width: 55.3333333333rem;
}
.area--textmedia--logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  align-items: center;
}
@media (min-width: 576px) {
  .area--textmedia--logos {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .area--textmedia--logos {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .area--textmedia--logos {
    grid-template-columns: repeat(4, 1fr);
  }
}

.area--media .area--thumbnail {
  margin: 0;
}

.main-content--container > .area--media--multiple:not(.full-width) {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%);
  padding-left: var(--mainContentContainer-offsetLeftWithPadding);
  scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);
}
@media (min-width: 768px) {
  .main-content--container > .area--media--multiple:not(.full-width) {
    position: initial;
    left: initial;
    transform: initial;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    scroll-padding: 0;
  }
}
.main-content--container > .area--media--multiple:not(.full-width) > .cols::after, .form-container .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row::after,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row::after {
  display: block;
  content: "";
  width: calc(var(--mainContentContainer-offsetLeftWithPadding) - 1rem);
  height: 10px;
  scroll-snap-stop: normal;
}
.main-content--container > .area--media--multiple:not(.full-width) > .cols.no-gutter::after, .form-container .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .no-gutter.row::after,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .no-gutter.row::after {
  width: var(--mainContentContainer-offsetLeftWithPadding);
}
@media (min-width: 576px) {
  .main-content--container > .area--media--multiple:not(.full-width) > .cols.cols--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row {
    padding-right: var(--mainContentContainer-offsetLeftWithPadding);
  }
  .main-content--container > .area--media--multiple:not(.full-width) > .cols.cols--2::after, .form-container .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row::after,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row::after {
    display: none;
    content: none;
  }
}
@media (min-width: 768px) {
  .main-content--container > .area--media--multiple:not(.full-width) > .cols.cols--2, .form-container .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row {
    padding-right: 0;
  }
  .main-content--container > .area--media--multiple:not(.full-width) > .cols::after, .form-container .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row::after,
.form-builder-wrapper .formbuilder-row:not(.full_width_row) fieldset .main-content--container > .form-group.area--media--multiple:not(.full-width) > .row::after {
    display: none;
    content: none;
  }
}

.area--parallax {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  min-height: 50vw;
  max-height: 80vh;
}
@media (min-width: 576px) {
  .area--parallax {
    min-height: 40vw;
  }
}
.area--parallax .area-thumbnail--caption,
.area--parallax .area-thumbnail--copyright {
  display: none;
}
.area--parallax--media {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}
.area--parallax--media--item {
  width: 100%;
  height: 100%;
}
.area--parallax--media--item picture, .area--parallax--media--item img, .area--parallax--media--item video, .area--parallax--media--item iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}
.area--parallax--content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.area--parallax--content {
  color: #fff;
  padding: 2rem;
}
@media (min-width: 576px) {
  .area--parallax--content {
    max-width: 80vw;
  }
}
@media (min-width: 768px) {
  .area--parallax--content {
    max-width: 70vw;
  }
}
.area--parallax--content h1, .area--parallax--content h2, .area--parallax--content h3, .area--parallax--content h4, .area--parallax--content h5, .area--parallax--content h6,
.area--parallax--content .h1, .area--parallax--content .h2, .area--parallax--content .h3, .area--parallax--content .h4, .area--parallax--content .cart-big--item--title--headline, .area--parallax--content .area--thumbnail--caption, .area--parallax--content .h5, .area--parallax--content .h6 {
  color: currentColor;
}
.area--parallax--content h1 *, .area--parallax--content h2 *, .area--parallax--content h3 *, .area--parallax--content h4 *, .area--parallax--content h5 *, .area--parallax--content h6 *,
.area--parallax--content .h1 *, .area--parallax--content .h2 *, .area--parallax--content .h3 *, .area--parallax--content .h4 *, .area--parallax--content .cart-big--item--title--headline *, .area--parallax--content .area--thumbnail--caption *, .area--parallax--content .h5 *, .area--parallax--content .h6 * {
  color: currentColor;
}
.area--parallax--content > *:first-child {
  margin-top: 0;
}
.area--parallax--content > *:last-child {
  margin-bottom: 0;
}
.area--jumbotron-hero--outer {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .area--jumbotron-hero--outer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.area--jumbotron-hero {
  background-color: #003770;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
  color: #fff;
  border-radius: 1.3333333333rem;
  overflow: hidden;
}
.area--jumbotron-hero h1, .area--jumbotron-hero h2, .area--jumbotron-hero h3, .area--jumbotron-hero h4, .area--jumbotron-hero h5, .area--jumbotron-hero h6,
.area--jumbotron-hero .h1, .area--jumbotron-hero .h2, .area--jumbotron-hero .h3, .area--jumbotron-hero .h4, .area--jumbotron-hero .cart-big--item--title--headline, .area--jumbotron-hero .area--thumbnail--caption, .area--jumbotron-hero .h5, .area--jumbotron-hero .h6 {
  color: currentColor;
}
.area--jumbotron-hero h1 small, .area--jumbotron-hero h2 small, .area--jumbotron-hero h3 small, .area--jumbotron-hero h4 small, .area--jumbotron-hero h5 small, .area--jumbotron-hero h6 small,
.area--jumbotron-hero .h1 small, .area--jumbotron-hero .h2 small, .area--jumbotron-hero .h3 small, .area--jumbotron-hero .h4 small, .area--jumbotron-hero .cart-big--item--title--headline small, .area--jumbotron-hero .area--thumbnail--caption small, .area--jumbotron-hero .h5 small, .area--jumbotron-hero .h6 small {
  color: currentColor;
  font-size: 0.65em;
}
.area--jumbotron-hero li::marker {
  color: currentColor;
}
.area--jumbotron-hero--inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
}
@media (min-width: 768px) {
  .area--jumbotron-hero--inner {
    display: grid;
    grid-template-areas: "content image";
    grid-template-columns: 1fr auto;
  }
}
.area--jumbotron-hero--content {
  grid-area: content;
  padding: 2.6666666667rem;
  font-size: 0.8666666667rem;
}
@media (min-width: 768px) {
  .area--jumbotron-hero--content {
    font-size: 1rem;
    align-self: center;
  }
}
.area--jumbotron-hero--content a {
  text-decoration: none;
  color: currentColor;
}
.area--jumbotron-hero--content a:hover, .area--jumbotron-hero--content a:focus {
  color: currentColor;
  text-decoration: underline;
}
.area--jumbotron-hero--content p:not(.area--jumbotron-hero--address--line) {
  margin-top: 1em;
}
.area--jumbotron-hero--content p:not(.area--jumbotron-hero--address--line) a {
  text-decoration: underline;
}
.area--jumbotron-hero--image {
  grid-area: image;
  align-self: end;
  width: 100%;
  max-width: 50rem;
}
@media (min-width: 768px) {
  .area--jumbotron-hero--image {
    width: 40vw;
    padding-top: 2.6666666667rem;
  }
}
@media (min-width: 1200px) {
  .area--jumbotron-hero--image {
    padding-top: 6.6666666667rem;
  }
}
.area--jumbotron-hero--address--line {
  display: flex;
  margin: 0;
  margin-top: 1.3333333333rem;
}
.area--jumbotron-hero--address--line .icon-text {
  align-items: flex-start;
  line-height: 1.3333;
}

.area--jumbotron-teaser-slider {
  padding-top: 5.3333333333rem;
  padding-bottom: 3.3333333333rem;
  position: relative;
}
@media (min-width: 1200px) {
  .area--jumbotron-teaser-slider {
    padding-bottom: 0;
  }
}
.area--jumbotron-teaser-slider::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #003770;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .area--jumbotron-teaser-slider::before {
    max-width: 80vw;
    border-radius: 1.3333333333rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.area--jumbotron-teaser-slider--inner {
  position: relative;
}
.area--jumbotron-teaser-slider--inner h1, .area--jumbotron-teaser-slider--inner h2, .area--jumbotron-teaser-slider--inner h3, .area--jumbotron-teaser-slider--inner h4, .area--jumbotron-teaser-slider--inner h5, .area--jumbotron-teaser-slider--inner h6,
.area--jumbotron-teaser-slider--inner .h1, .area--jumbotron-teaser-slider--inner .h2, .area--jumbotron-teaser-slider--inner .h3, .area--jumbotron-teaser-slider--inner .h4, .area--jumbotron-teaser-slider--inner .cart-big--item--title--headline, .area--jumbotron-teaser-slider--inner .area--thumbnail--caption, .area--jumbotron-teaser-slider--inner .h5, .area--jumbotron-teaser-slider--inner .h6 {
  color: currentColor;
}
.area--jumbotron-teaser-slider--inner h1 small, .area--jumbotron-teaser-slider--inner h2 small, .area--jumbotron-teaser-slider--inner h3 small, .area--jumbotron-teaser-slider--inner h4 small, .area--jumbotron-teaser-slider--inner h5 small, .area--jumbotron-teaser-slider--inner h6 small,
.area--jumbotron-teaser-slider--inner .h1 small, .area--jumbotron-teaser-slider--inner .h2 small, .area--jumbotron-teaser-slider--inner .h3 small, .area--jumbotron-teaser-slider--inner .h4 small, .area--jumbotron-teaser-slider--inner .cart-big--item--title--headline small, .area--jumbotron-teaser-slider--inner .area--thumbnail--caption small, .area--jumbotron-teaser-slider--inner .h5 small, .area--jumbotron-teaser-slider--inner .h6 small {
  color: currentColor;
  font-size: 0.65em;
}
.area--jumbotron-teaser-slider--content, .area--jumbotron-teaser-slider--buttons {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1260px;
}
@media (min-width: 768px) {
  .area--jumbotron-teaser-slider--content, .area--jumbotron-teaser-slider--buttons {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.area--jumbotron-teaser-slider--buttons {
  display: none;
}
@media (min-width: 1200px) {
  .area--jumbotron-teaser-slider--buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 5.3333333333rem;
  }
  .area--jumbotron-teaser-slider--buttons .slide-btn--prev, .area--jumbotron-teaser-slider--buttons .slide-btn--next {
    color: #fff;
    background-color: transparent;
  }
}

.area--jumbotron-teaser-slider--slides {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid: 1fr / auto-flow min(80vw, 20rem);
  grid-gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  padding-left: var(--mainContentContainer-offsetLeftWithPadding);
  scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);
}
@media (min-width: 1200px) {
  .area--jumbotron-teaser-slider--slides {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.area--jumbotron-teaser-slider--slides::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.area--jumbotron-teaser-slider--slides > * {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.area--jumbotron-teaser-slider--slides::after {
  display: block;
  content: "";
  width: calc(var(--mainContentContainer-offsetLeftWithPadding) - 1rem);
  height: 10px;
  scroll-snap-stop: normal;
}

.area--jumbotron-teaser-slider--category-nav--item {
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background: transparent;
}
.area--jumbotron-teaser-slider--category-nav--item span {
  display: block;
  text-align: left;
  background-color: transparent;
  font-weight: 700;
  padding: 1em 3em 1em 1.3333333333em;
  border-radius: 1.3333333333rem;
  overflow: hidden;
  will-change: background-color;
  transition: background-color 0.3s;
  position: relative;
}
.area--jumbotron-teaser-slider--category-nav--item span::after {
  content: "";
  display: block;
  width: 0.4666666667rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  right: 1.3333333333rem;
  transform: translateY(-50%);
  background-image: svg-load("angle-right.svg", fill=#fff);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  will-change: transform;
  transition: transform 0.3s, opacity 0.3s;
}
@media (min-width: 992px) {
  .area--jumbotron-teaser-slider--category-nav--item span {
    padding: 1em 1.3333333333em;
  }
  .area--jumbotron-teaser-slider--category-nav--item span::after {
    content: none;
    display: none;
  }
}
details[open] .area--jumbotron-teaser-slider--category-nav--item span, .area--jumbotron-teaser-slider--category-nav--item.active span {
  background-color: rgba(255, 255, 255, 0.15);
}
details[open] .area--jumbotron-teaser-slider--category-nav--item span::after, .area--jumbotron-teaser-slider--category-nav--item.active span::after {
  transform: translateY(-50%) rotate(90deg);
}

.area--jumbotron-teaser-slider--category-nav {
  display: none;
}
@media (min-width: 992px) {
  .area--jumbotron-teaser-slider--category-nav {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .area--jumbotron-teaser-slider--category-nav .area--jumbotron-teaser-slider--category-nav--item {
    flex-shrink: 0;
  }
}

.area--jumbotron-teaser-slider--inner details .area--jumbotron-teaser-slider--category-nav--item {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1260px;
  list-style: none;
}
.area--jumbotron-teaser-slider--inner details .area--jumbotron-teaser-slider--category-nav--item::marker, .area--jumbotron-teaser-slider--inner details .area--jumbotron-teaser-slider--category-nav--item::-webkit-details-marker {
  display: none;
}
@media (min-width: 992px) {
  .area--jumbotron-teaser-slider--inner details .area--jumbotron-teaser-slider--category-nav--item {
    display: none;
  }
}
.area--jumbotron-teaser-slider--inner details:first-of-type .area--jumbotron-teaser-slider--category-nav--item {
  margin-top: 2rem;
}

.area--testimonial-slider {
  position: relative;
}
.area--testimonial-slider--buttons {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
.area--testimonial-slider-secondary .area--testimonial-slider--buttons .slide-btn--prev, .area--testimonial-slider-secondary .area--testimonial-slider--buttons .slide-btn--next {
  color: #C61932;
}
.area--testimonial-slider--slides {
  border-radius: 1.3333333333rem;
  padding-bottom: 2rem;
  color: #003770;
  background-color: #F0F5FB;
  border-color: #F0F5FB;
  display: grid;
  grid: 1fr/auto-flow 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.area--testimonial-slider-secondary .area--testimonial-slider--slides {
  color: #B83D3D;
  background-color: #FBF0F0;
  border-color: #FBF0F0;
}
.area--testimonial-slider--slides::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.area--testimonial-slider--slides > * {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.area--testimonial-entry {
  padding: 2.6666666667rem 1.3333333333rem;
  margin: 0;
}
@media (min-width: 992px) {
  .area--testimonial-entry {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.area--testimonial-entry--inner {
  width: 100%;
  max-width: 63rem;
  margin-left: auto;
  margin-right: auto;
}
.area--testimonial-entry--author {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
}
.area--testimonial-entry--author--name strong {
  display: block;
}
.area--testimonial-entry--author--image {
  position: relative;
  width: 100%;
  max-width: 10.6666666667rem;
  overflow: hidden;
  margin-bottom: 1.3333333333rem;
}
.area--testimonial-entry--author--image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.area--testimonial-entry--author--image picture, .area--testimonial-entry--author--image img, .area--testimonial-entry--author--image video, .area--testimonial-entry--author--image iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.area--testimonial-entry--content {
  margin: 0;
  text-align: center;
}
.area--testimonial-entry--content > *:first-child {
  margin-top: 0;
}
.area--testimonial-entry--content > *:last-child {
  margin-bottom: 0;
}
.area--testimonial-entry--media {
  grid-column: 1/-1;
}
.area--testimonial-entry--media > *:first-child {
  margin-top: 0;
}
.area--testimonial-entry--media > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry {
    padding-top: 6.6666666667rem;
    padding-bottom: 6.6666666667rem;
  }
}
@media (min-width: 768px) {
  .area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry--inner {
    display: grid;
    grid-template-areas: "image content";
    grid-template-columns: auto 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
@media (min-width: 992px) {
  .area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry--inner {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
}
@media (min-width: 1400px) {
  .area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry--inner {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
  }
}
@media (min-width: 768px) {
  .area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry--author {
    width: 25vw;
    max-width: 16.6666666667rem;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .area--testimonial-slider:not(.area--testimonial-slider-small) .area--testimonial-entry--content {
    text-align: left;
  }
}

@media (max-width: 991.98px) {
  .area--teaser-list-overflow--items {
    display: grid;
    grid: 1fr / auto-flow min(60vw, 18rem);
    grid-gap: 1rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    -webkit-overflow-scrolling: touch;
    padding-left: var(--mainContentContainer-offsetLeftWithPadding);
    scroll-padding: 0 0 0 var(--mainContentContainer-offsetLeftWithPadding);
    scrollbar-width: none;
  }
  .area--teaser-list-overflow--items::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
  .area--teaser-list-overflow--items > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}
@media (min-width: 992px) {
  .area--teaser-list-overflow--items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .area--teaser-list-overflow--items .area--teaser-list-overflow--item {
    width: calc(min(33vw, 340px) - 30px);
  }
}

.area--content-container {
  padding-top: 60px;
}
@media screen and (min-width: 768px) {
  .area--content-container {
    padding-top: calc(60px + 40 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .area--content-container {
    padding-top: 100px;
  }
}
.area--content-container {
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .area--content-container {
    padding-bottom: calc(60px + 40 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .area--content-container {
    padding-bottom: 100px;
  }
}
.area--content-container.highlight {
  background-color: #003770;
  background-image: linear-gradient(275.58deg, #003770 0%, #C61932 100%);
  background-size: 100% 100%;
  background-position: top left;
  color: #fff;
}
.area--content-container.highlight .area--content-container--inner h1, .area--content-container.highlight .area--content-container--inner h2, .area--content-container.highlight .area--content-container--inner h3, .area--content-container.highlight .area--content-container--inner h4, .area--content-container.highlight .area--content-container--inner h5, .area--content-container.highlight .area--content-container--inner h6,
.area--content-container.highlight .area--content-container--inner .h1, .area--content-container.highlight .area--content-container--inner .h2, .area--content-container.highlight .area--content-container--inner .h3, .area--content-container.highlight .area--content-container--inner .h4, .area--content-container.highlight .area--content-container--inner .cart-big--item--title--headline, .area--content-container.highlight .area--content-container--inner .area--thumbnail--caption, .area--content-container.highlight .area--content-container--inner .h5, .area--content-container.highlight .area--content-container--inner .h6 {
  color: currentColor;
}
.area--content-container.highlight .area--content-container--inner h1 small, .area--content-container.highlight .area--content-container--inner h2 small, .area--content-container.highlight .area--content-container--inner h3 small, .area--content-container.highlight .area--content-container--inner h4 small, .area--content-container.highlight .area--content-container--inner h5 small, .area--content-container.highlight .area--content-container--inner h6 small,
.area--content-container.highlight .area--content-container--inner .h1 small, .area--content-container.highlight .area--content-container--inner .h2 small, .area--content-container.highlight .area--content-container--inner .h3 small, .area--content-container.highlight .area--content-container--inner .h4 small, .area--content-container.highlight .area--content-container--inner .cart-big--item--title--headline small, .area--content-container.highlight .area--content-container--inner .area--thumbnail--caption small, .area--content-container.highlight .area--content-container--inner .h5 small, .area--content-container.highlight .area--content-container--inner .h6 small {
  color: currentColor;
  font-size: 0.65em;
}
.area--content-container.highlight .area--content-container--inner li::marker {
  color: currentColor;
}

.area--content-container--inner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.area--content-container--inner {
  padding-left: 30px;
}
@media screen and (min-width: 768px) {
  .area--content-container--inner {
    padding-left: calc(30px + 30 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .area--content-container--inner {
    padding-left: 60px;
  }
}
.area--content-container--inner {
  padding-right: 30px;
}
@media screen and (min-width: 768px) {
  .area--content-container--inner {
    padding-right: calc(30px + 30 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .area--content-container--inner {
    padding-right: 60px;
  }
}
.area--content-container--inner > *:first-child {
  margin-top: 0;
}
.area--content-container--inner > *:last-child {
  margin-bottom: 0;
}

table.contenttable,
.area--text table,
.area--table table {
  width: 100%;
  border: none;
}
table.contenttable th,
table.contenttable td,
.area--text table th,
.area--text table td,
.area--table table th,
.area--table table td {
  padding: 0.6666666667rem 1rem;
  min-width: 25vw;
}
table.contenttable th:first-child,
table.contenttable td:first-child,
.area--text table th:first-child,
.area--text table td:first-child,
.area--table table th:first-child,
.area--table table td:first-child {
  padding-left: 2rem;
}
table.contenttable th:last-child,
table.contenttable td:last-child,
.area--text table th:last-child,
.area--text table td:last-child,
.area--table table th:last-child,
.area--table table td:last-child {
  padding-right: 2rem;
}
@media (min-width: 768px) {
  table.contenttable th,
table.contenttable td,
.area--text table th,
.area--text table td,
.area--table table th,
.area--table table td {
    min-width: 0;
  }
}
table.contenttable thead tr:first-child th,
.area--text table thead tr:first-child th,
.area--table table thead tr:first-child th {
  background-color: #003770;
  color: #fff;
}
table.contenttable thead tr:not(:first-child) th,
.area--text table thead tr:not(:first-child) th,
.area--table table thead tr:not(:first-child) th {
  background-color: #eee;
  color: #000;
}
table.contenttable td,
.area--text table td,
.area--table table td {
  border-bottom: 1px solid #eee;
}
table.contenttable p:last-child,
.area--text table p:last-child,
.area--table table p:last-child {
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

table.contenttable,
.area--text table,
.area--table table {
  border-radius: 1.3333333333rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 575.98px) {
  table.contenttable,
.area--text table,
.area--table table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  table.contenttable td,
.area--text table td,
.area--table table td {
    min-width: 13.3333333333rem;
    max-width: 60vw;
    font-size: 0.8em;
  }
}

.highlight-container {
  border-radius: 1.3333333333rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0.267rem 4rem rgba(0, 0, 0, 0.1);
}
.highlight-container--title > *:first-child, .highlight-container--content > *:first-child {
  margin-top: 0;
}
.highlight-container--title > *:last-child, .highlight-container--content > *:last-child {
  margin-bottom: 0;
}
.highlight-container--title {
  background-color: #003770;
  font-weight: 700;
  color: #fff;
  padding: 1rem 1rem 1rem 2rem;
}
@media (min-width: 576px) {
  .highlight-container--title {
    padding: 1.3333333333rem 2.6666666667rem;
  }
}
.highlight-container--title * {
  color: currentColor;
}
.highlight-container--content {
  padding: 1rem;
}
@media (min-width: 576px) {
  .highlight-container--content {
    padding: 2.6666666667rem;
  }
}
.highlight-container--content > hr {
  margin-left: -1rem;
  margin-right: -1rem;
}
@media (min-width: 576px) {
  .highlight-container--content > hr {
    margin-left: -2.6666666667rem;
    margin-right: -2.6666666667rem;
  }
}

.area--mobile-collapse {
  position: relative;
}
.area--mobile-collapse > label {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.area--mobile-collapse > input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
.area--mobile-collapse > input[type=checkbox]:checked ~ label {
  display: none;
}
@media (min-width: 992px) {
  .area--mobile-collapse > label {
    display: none;
  }
  .area--mobile-collapse .area--mobile-collapse--content,
.area--mobile-collapse input[type=checkbox]:not(:checked) ~ .area--mobile-collapse--content {
    max-height: none;
  }
  .area--mobile-collapse .area--mobile-collapse--content::before,
.area--mobile-collapse input[type=checkbox]:not(:checked) ~ .area--mobile-collapse--content::before {
    content: none;
    display: none;
  }
}

.area--mobile-collapse--content {
  position: relative;
}
.area--mobile-collapse--content > *:first-child {
  margin-top: 0;
}
.area--mobile-collapse--content > *:last-child {
  margin-bottom: 0;
}
input[type=checkbox]:not(:checked) ~ .area--mobile-collapse--content {
  max-height: 8.6666666667rem;
  overflow: hidden;
}
input[type=checkbox]:not(:checked) ~ .area--mobile-collapse--content::before {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 4em;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 90%);
}

.area--embed .embera-embed-responsive {
  position: relative;
  width: 100%;
  height: 0;
}
.area--embed .embera-embed-responsive {
  padding-bottom: 66.66666%;
}
.area--embed .embera-embed-responsive--video {
  padding-bottom: 56.25%;
}
.area--embed .embera-embed-responsive-item, .area--embed .embera-embed-responsive iframe {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.area--embed .embera-embed-responsive iframe {
  border: none;
}

:root {
  --courseDetail-sidebarWidth: min(457px, 35vw) ;
}

.courses-page--container .card-item.card-item-course-detail {
  max-width: 30.4666666667rem;
  margin-top: calc(var(--courseHeroRatioTop) * -1 - var(--mainContentPaddingTop));
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .courses-page--container {
    display: flex;
    align-items: flex-start;
  }
  .courses-page--container .courses-page--content {
    flex-basis: calc(100% - var(--courseDetail-sidebarWidth));
    flex-shrink: 1;
    flex-grow: 0;
    width: calc(100% - var(--courseDetail-sidebarWidth));
    padding-right: 4rem;
  }
  .courses-page--container .courses-page--sidebar {
    flex-basis: var(--courseDetail-sidebarWidth);
    flex-shrink: 0;
    flex-grow: 0;
    width: var(--courseDetail-sidebarWidth);
  }
  .courses-page--container .courses-page--sidebar .card-item.card-item-course-detail {
    max-width: none;
  }
}

.courses-page--additional-info > *:not(.card-item):not(.area):not(.infobox) {
  padding-left: 1.3333333333rem;
  padding-right: 1.3333333333rem;
}
.courses-page--additional-info > h4, .courses-page--additional-info > .h4, .courses-page--additional-info > .cart-big--item--title--headline, .courses-page--additional-info > .area--thumbnail--caption {
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.person-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "image name";
  align-items: center;
  gap: 1rem;
}
.person-item--image {
  grid-area: image;
  border-radius: 50%;
  overflow: hidden;
}
.person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
  display: block;
  object-fit: cover;
}
.person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
  width: 40px;
}
@media screen and (min-width: 768px) {
  .person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
    width: calc(40px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
    width: 60px;
  }
}
.person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
  height: 40px;
}
@media screen and (min-width: 768px) {
  .person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
    height: calc(40px + 20 * ((100vw - 768px) / 432));
  }
}
@media screen and (min-width: 1200px) {
  .person-item--image img, .person-item--image picture, .person-item--image video, .person-item--image--dummy {
    height: 60px;
  }
}
.person-item--image--dummy {
  background-color: transparent;
  background-size: 70% auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: svg-load("icons/bfi/bfi-graduation.svg");
  border: 1px solid #eee;
  border-radius: 50%;
}
.person-item--name {
  grid-area: name;
}
.person-item + .person-item {
  margin-top: 1rem;
}