
.sub-nav--item.has-dropdown {
    .sub-nav {
        display: none;
    }

    @include navItemHoverFocusActive {
        > .sub-nav {
            display: block;
        }
    }
}

.sub-nav--item {
    &--inner {
        &--menuitem {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-areas: "label arrow";
            align-items: center;
            padding: to-em(15px) to-em(20px) to-em(15px) to-em(30px);

            > a {
                grid-area: label;
            }

            > button {
                display: block;
                overflow: hidden;
                //width: to-em(14px);
                height: to-em(14px);
                width: to-em(40px, $main-nav-item--font-size);
                //height: to-em(40px, $main-nav-item--font-size);

                opacity: 0;
                grid-area: arrow;
                margin-left: to-em(15px);
                //margin-right: to-em(13px);
                background-color: transparent;
                border: 1px solid transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto to-em(14px);
                @include insertIcon('angle-right', $brand-primary);

                transition: transform $btn-transition-duration, opacity $btn-transition-duration, border-color $btn-transition-duration;
                transition-timing-function: ease-in-out;
                will-change: transform, opacity, border-color;
            }
        }

        &--menuitem > a {

            color: $color-bfi-brand-blue;
            font-weight: $font-weight-normal;
            text-decoration: none;
            transition: color $btn-transition-duration, background-color $btn-transition-duration, border-color $btn-transition-duration, opacity $btn-transition-duration;
            transition-timing-function: ease-in-out;
            will-change: color, background-color, border-color, opacity;

            &::after {
            }

            &:hover {
                background-color: $white;
            }
        }
    }

    &.has-dropdown > .sub-nav--item--inner > .sub-nav--item--inner--menuitem {
        > button {
            opacity: 1;
            cursor: pointer;

            @include media-breakpoint-up(xl) {
                //opacity: .2;
                width: to-em(40px, $main-nav-item--font-size);
                height: to-em(40px, $main-nav-item--font-size);
                background-color: transparent;
                border: 1px solid rgba(0, 55, 112, 0.1);
                border-radius: 50%;
                background-size: auto to-em(14px, $main-nav-item--font-size);
                opacity: 1;
            }
        }
    }
    &.has-dropdown {
        @include navItemHoverFocusActive {
            > .sub-nav--item--inner--menuitem {
                > button {
                    transform: rotate(90deg);
                    //border-color: transparent;
                    opacity: 1;

                    @include media-breakpoint-up(xl) {
                        transform: rotate(0deg);
                        opacity: 1;
                        background-color: rgba(0, 55, 112, 0.1);
                    }
                }
            }
        }
    }
    @include navItemHoverFocusActive {
        background-color: $white;
    }
    &:hover > .sub-nav-item--selector {
        & ~ .sub-nav--item--inner {
            background-color: $white;
        }
    }

    .sub-nav--list .sub-nav--item:last-child {
        padding-bottom: to-em(15px);
    }
}

.main-nav--dropdown--inner > .sub-nav > .sub-nav--list > .sub-nav--item {
    &:last-child {
        margin-bottom: to-em(30px);
    }
    > .sub-nav--item--inner {
        > a {
            padding-top: to-em(20px);
            padding-bottom: to-em(20px);
            font-weight: $font-weight-bold;

            @include media-breakpoint-up(xl) {
                padding-top: to-em(10px);
                padding-bottom: to-em(10px);
            }


            &::after {
                border-radius: 50%;
                border-color: rgba(0, 55, 112, 0.1);
                width: to-em(40px);
                height: to-em(40px);
                margin-right: 0;
                margin-top: to-em(-10px);
                margin-bottom: to-em(-10px);

                @include media-breakpoint-up(xl) {
                    //border-color: transparent;
                }
            }

            background-color: transparent;
        }
    }
}

.main-nav--dropdown--inner > .sub-nav {
    @include media-breakpoint-up(xl) {
        //display: grid;
        //grid-template-columns: repeat(2, 1fr);



        > .sub-nav--list {
            width: 50%;
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border-right: 1px dashed rgba($color-bfi-brand-blue, .2);
                pointer-events: none;
            }

            > .sub-nav--item {
                padding-right: to-rem(30px);
            }
            .sub-nav {
                position: absolute;
                top: 0;
                left: 100%;
                width: 100%;
                min-height: 100%;

                .sub-nav {
                    display: none !important;
                }

                > .sub-nav--list {
                    position: sticky;
                    top: to-rem(30px);
                }

                .sub-nav--item {
                    padding-left: to-rem(30px);
                    > .sub-nav--item--inner {
                        > .sub-nav--item--inner--menuitem {
                            padding: 0;
                            display: block;

                            a {
                                display: block;
                                border: 1px solid transparent;
                                font-weight: $font-weight-normal;
                                padding: to-em(8px) to-em(15px);
                                margin-bottom: to-em(2px);

                                &::after {
                                    content: none;
                                    display: none;
                                }
                            }

                            button {
                                display: none;
                            }
                        }

                        &:hover {
                            background-color: transparent;
                            > a {
                                background-color: transparent;
                                border-color: rgba(0, 55, 112, 0.1);
                            }
                        }

                    }

                    @include navItemHoverFocusActive {
                        background-color: transparent;
                        > a {
                            border-color: rgba(0, 55, 112, 0.1);
                        }
                    }
                }
            }
        }
    }
}